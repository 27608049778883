import React, { useEffect, useState } from "react";
import { ResponsiveContainer } from "recharts";
import CallScenerios from "./CallScenerios";
import ContinueCancelBooking from "./ContinueCancelBooking";
import ContinueComplaint from "./ContinueComplaint";
import ContinueFranchiseEnquiry from "./ContinueFranchiseEnquiry";
import ContinueMessageOperator from "./ContinueMessageOperator";
import ContinueServiceCall from "./ContinueServiceCall";
import ReferallEnquiry from "./ReferallEnquiry";
import ReferallPage from "./ReferallPage";
import ReSchedule from "./ReSchedule";
import SaveCall from "./SaveCall";
import SelectOrganization from "./SelectOrganization";
import SelectPostCode from "./SelectPostCode";
import ServiceRequired from "./ServiceRequired";
import useStyles from "./style";
import TopInfoBox from "./topInfoBox";
import { httpClient } from "appUtility/Api";
import qs from "qs";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import ContinueDeclineOperator from "./ContinueDeclineOperator";
import SelectFranchiseChange from "./SelectFranchiseChange";
import ContinueFranchiseChange from "./ContinueFranchiseChange";
import ContinueEnquiry from "./ContinueEnquiry";

const login = localStorage.getItem("login");

const lodgeCall2 = () => {
  const classes = useStyles();
  var loginData = JSON.parse(login);

  // STEPS
  const [step, setStep] = useState(1);
  const [serviceStep, setServiceStep] = useState("");
  const [cancelStep, setCancelStep] = useState(0);
  const [messageStep, setMessageStep] = useState("");
  const [changeSuburbStep, setChangeSuburbState] = useState(0);
  const [enquiryStep, setEnquiryStep] = useState(0);
  const [adEnquiryStep, setAdEnquiryStep] = useState(0);
  const [complaintStep, setComplaintStep] = useState(0);
  const [declineStep, setDeclineStep] = useState(0);
  const [saveStep, setSaveStep] = useState(0);
  const [endCall, setEndCall] = useState(0);
  // STEPS

  const [boxInfo, setBoxInfo] = useState("");
  const [radios, setRadios] = useState("Service Required");
  const [serviceSelect, setServiceSelect] = useState("Wash only");
  const [postCode, setPostCode] = useState("");
  const [suburb, setSuburb] = useState("");
  const [franchise, setFranchise] = useState("");
  const [operatorName, setOperatorName] = useState("");
  const [state, setState] = useState("");
  const [stateID, setStateID] = useState("");
  const [suspendedFranchisee, setSuspendedFranchisee] = useState({});
  const [companyID, setCompanyID] = useState("");
  const [showResetButton, setShowResetButton] = useState(false);
  const [suburbList, setSuburbList] = useState([]);
  const [franchiseList, setFranchiseList] = useState([]);
  const [suburbCurrent, setSuburbCurrent] = useState([]);
  const [suburbStackList, setSuburbStackList] = useState([]);
  const [currentFranchiseList, setCurrentFranchiseList] = useState([]);
  const [newCallScenarioSelected, setNewCallScenarioSelected] = useState(false);
  const [previousForwardedScenario, setPreviousForwardedScenario] = useState(
    ""
  ); // The scenario for which we have moved to the next step
  const [inputValue, setInputValue] = useState("");
  const [currentSelectedFranchise, setCurrentSelectedFranchise] = useState(
    null
  ); // In SelectedPostCode component, used to set currently selected franchise when we are at tab FRANCHISE
  const [franchiseInput, setFranchiseInput] = useState("");
  const [franchiseSuburbsList, setFranchiseSuburbsList] = useState([]);
  const [franchiseSuburb, setFranchiseSuburb] = useState("");

  const [enquiryDetails, setEnquiryDetails] = useState({
    // customerName: "",
    firstName: "",
    lastName: "",
    phoneNumber1: "",
    phoneNumber2: "",
    address: "",
    message: "",
    hearingSource: "",
    other: "",
  });
  const [enquiryPressed, setEnquiryPressed] = useState({
    // customerName: false,
    firstName: false,
    lastName: false,
    phoneNumber1: false,
    phoneNumber2: false,
    address: false,
    message: false,
    hearingSource: false,
    other: false,
  });
  const [messageOperatorDetails, setMessageOperatorDetails] = useState({
    operatorName: "",
    // customerName: "",
    firstName: "",
    lastName: "",
    phoneNumber1: "",
    phoneNumber2: "",
    suburb: "",
    message: "",
  });
  const [messageOperatorPressed, setMessageOperatorPressed] = useState({
    // customerName: false,
    firstName: false,
    lastName: false,
    phoneNumber1: false,
    phoneNumber2: false,
    suburb: false,
    message: false,
  });
  const [callerDetails, setCallerDetails] = useState({
    firstName: "",
    lastName: "",
    phoneNumber1: "",
    phoneNumber2: "",
    address: "",
    hearingSource: "",
    arrangeCall: "",
    other: "",
    comment: "",
  });
  const [callerPressed, setCallerPressed] = useState({
    firstName: false,
    lastName: false,
    phoneNumber1: false,
    phoneNumber2: false,
    address: false,
    hearingSource: false,
    arrangeCall: false,
    other: "",
    comment: "",
  });

  const [saveCaller, setSaveCaller] = useState({
    firstName: "",
    lastName: "",
    phoneNumber1: "",
    phoneNumber2: "",
    address: "",
    suburb: "",
    postCode: "",
  });
  const [saveCallerPressed, setSaveCallerPressed] = useState({
    firstName: false,
    lastName: false,
    phoneNumber1: false,
    phoneNumber2: false,
    address: false,
    suburb: false,
    postCode: false,
  });

  useEffect(() => {
    // setRadios("Service Required");
    // setServiceSelect("");
    if (newCallScenarioSelected && radios !== previousForwardedScenario) {
      setSuburbList([]);
      setFranchiseList([]);

      setPostCode("");
      setSuburb("");
      setFranchise("");
      setOperatorName("");
      setState("");
      setStateID("");
      setSuspendedFranchisee({});
      setEnquiryDetails({
        // customerName: "",
        firstName: "",
        lastName: "",
        phoneNumber1: "",
        phoneNumber2: "",
        address: "",
        message: "",
        hearingSource: "",
        other: "",
      });
      setMessageOperatorDetails({
        operatorName: "",
        // customerName: "",
        firstName: "",
        lastName: "",
        phoneNumber1: "",
        phoneNumber2: "",
        suburb: "",
        message: "",
      });
      setMessageOperatorPressed({
        // customerName: "",
        firstName: "",
        lastName: "",
        phoneNumber1: "",
        phoneNumber2: "",
        suburb: "",
        message: "",
      });
      setCallerDetails({
        firstName: "",
        lastName: "",
        phoneNumber1: "",
        phoneNumber2: "",
        address: "",
        hearingSource: "",
        arrangeCall: "",
        other: "",
        comment: "",
      });
      setSaveCaller({
        firstName: "",
        lastName: "",
        phoneNumber1: "",
        phoneNumber2: "",
        address: "",
        suburb: "",
        postCode: "",
      });
      setEnquiryPressed({
        // customerName: false,
        firstName: false,
        lastName: false,
        phoneNumber1: false,
        phoneNumber2: false,
        address: false,
        message: false,
        hearingSource: false,
        other: false,
      });
      setMessageOperatorPressed({
        // customerName: false,
        firstName: false,
        lastName: false,
        phoneNumber1: false,
        phoneNumber2: false,
        suburb: false,
        message: false,
      });
      setCallerPressed({
        firstName: false,
        lastName: false,
        phoneNumber1: false,
        phoneNumber2: false,
        address: false,
        hearingSource: false,
        arrangeCall: false,
        other: "",
        comment: "",
      });
      setSaveCallerPressed({
        firstName: false,
        lastName: false,
        phoneNumber1: false,
        phoneNumber2: false,
        address: false,
        suburb: false,
        postCode: false,
      });
    }
  }, [step === 2]);

  // useEffect(() => {
  //   window.addEventListener("keydown", function(e) {
  //     if(e.key === "Backspace"){
  //       handleBack()
  //     }
  //   });

  // },[])

  const handleChangeSave = (e) => {
    const { name, value } = e.target;
    setSaveCaller({
      ...saveCaller,
      [name]: value,
    });
  };

  const handleChangeSavePressed = (e) => {
    const { name, value } = e.target;
    setSaveCallerPressed({
      [name]: name ? true : false,
    });
  };

  const handleInfo = (data) => {
    setBoxInfo(data);
    setStep(2);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newVal;
    if (name === "suburb") {
      setSuburb(value);
    }
    if (name === "postCode") {
      setPostCode(value);
    }
    if (name === "state") {
      setState(value);
    }
    if (name === "phoneNumber1" || name === "phoneNumber2") {
      newVal = value
        .replace(/[&\/\\#, +()$~%.'":*?<>{}@!^]/g, "")
        .toString()
        .slice(0, 10);
    }
    setCallerDetails({
      ...callerDetails,
      [name]:
        name === "phoneNumber1" || name === "phoneNumber2" ? newVal : value,
    });
  };

  const handleEnquiryChange = (e) => {
    const { name, value } = e.target;
    let newVal;
    if (name === "phoneNumber1" || name === "phoneNumber2") {
      newVal = value
        .replace(/[&\/\\#, +()$~%.'":*?<>{}@!^]/g, "")
        .toString()
        .slice(0, 10);
    }
    setEnquiryDetails({
      ...enquiryDetails,
      [name]:
        name === "phoneNumber1" || name === "phoneNumber2" ? newVal : value,
    });
  };
  const handleEnquiryPress = (e) => {
    const { name, value } = e.target;
    // if (e.key === "Enter") {
    setEnquiryPressed({
      // ...enquiryPressed,
      [name]: name ? true : false,
    });
  };

  const handleCallerFocus = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setCallerPressed({
      // ...callerPressed,
      [name]: name ? true : false,
    });
  };

  const handleChangeOperator = (e) => {
    const { name, value } = e.target;
    let newVal;
    if (name === "phoneNumber1" || name === "phoneNumber2") {
      newVal = value
        .replace(/[&\/\\#, +()$~%.'":*?<>{}@!^]/g, "")
        .toString()
        .slice(0, 10);
    }
    setMessageOperatorDetails({
      ...messageOperatorDetails,
      [name]:
        name === "phoneNumber1" || name === "phoneNumber2" ? newVal : value,
    });
  };

  const handlePressOperator = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setMessageOperatorPressed({
      // ...messageOperatorPressed,
      [name]: name ? true : false,
    });
  };

  const handleRadioChange = (e) => {
    setRadios(e.target.value);
    setNewCallScenarioSelected(radios !== e.target.value);
  };
  const handleServiceChange = (e) => {
    setServiceSelect(e.target.value);
  };

  const handlePostCode = (e) => {
    setPostCode(e.target.value);
  };

  const handleSuburb = (e) => {
    setSuburb(e.target.value);
  };

  const handleFranchise = (e) => {
    setFranchise(e.target.value);
  };

  const handleBack = () => {
    setStep(step - 1);
  };
  const handleNext = () => {
    setStep(step + 1);
  };

  const handleNone = () => {
    setStep(step);
  };

  const handleNextService = () => {
    setStep(step + 1);
    setServiceStep(1);
  };
  const handleBackService = () => {
    setStep(step - 1);
    setServiceStep(serviceStep - 1);
  };
  const handleContinueService = () => {
    setStep(step + 1);
    setServiceStep(serviceStep + 1);
  };

  const handleBackChangeSuburb = () => {
    setStep(step - 1);
    setChangeSuburbState(changeSuburbStep - 1);
  };

  const handleBackAd = () => {
    setStep(step - 1);
    setAdEnquiryStep(adEnquiryStep - 1);
  };

  const handleBackEq = () => {
    setStep(step - 1);
    setEnquiryStep(enquiryStep - 1);
  };

  const handleContinueCancel = () => {
    setStep(step + 1);
    setCancelStep(cancelStep + 1);
  };

  const handleBackCancel = () => {
    setStep(step - 1);
    setCancelStep(cancelStep - 1);
  };

  const handleContinueMessage = () => {
    setStep(step + 1);
    setMessageStep(1);
  };

  const handleBackMessage = () => {
    setStep(step - 1);
    setMessageStep(messageStep - 1);
  };

  const handleContinueChangeSuburb = () => {
    setStep(step + 1);
    setChangeSuburbState(changeSuburbStep + 1);
  };

  const handleContinueEnquiry = () => {
    setStep(step + 1);
    setEnquiryStep(enquiryStep + 1);
  };

  const handleContinueAdEnquiry = () => {
    setStep(step + 1);
    setAdEnquiryStep(adEnquiryStep + 1);
  };

  const handleContinueComplaint = () => {
    setStep(step + 1);
    setComplaintStep(complaintStep + 1);
  };

  const handleBackComplaint = () => {
    setStep(step - 1);
    setComplaintStep(complaintStep - 1);
  };
  const handleContinueDecline = () => {
    setStep(step + 1);
    setDeclineStep(declineStep + 1);
  };
  const handleBackDecline = () => {
    setStep(step - 1);
    setDeclineStep(declineStep - 1);
  };
  const handleNextCancel = () => {};

  const handleSubmitService = (e) => {
    let params = {
      userID: loginData.uid,
      userCode: loginData.aCode,
      appID: 1,
      firstName: callerDetails.firstName,
      lastName: callerDetails.lastName,
      address: callerDetails.address,
      phone: callerDetails.phoneNumber1,
      phone2: callerDetails.phoneNumber2,
      suburb: suburb,
      stateID: stateID,
      service_area_postcode: postCode,
      company_id: companyID,
      notes: callerDetails.other,
      interestedService: serviceSelect,
      referredBy: callerDetails.hearingSource,
      commentText: callerDetails.comment,
      messageType: "service_required",
    };

    httpClient
      .post(
        "saveCallLog2.php",
        qs.stringify({
          action: "save_call",
          source: params,
        })
      )
      .then(({ data }) => {
        if (data.success == "true") {
          setShowResetButton(true);
          NotificationManager.success(data.msg);
        } else {
          NotificationManager.warning(data.msg);
        }
      });
  };

  const handleReset = () => {
    setStep(1);
    setComplaintStep(0);
    setDeclineStep(0);
    setEnquiryStep(0);
    setAdEnquiryStep(0);
    setChangeSuburbState(0);
    setShowResetButton(false);
    setSuspendedFranchisee({});
  };

  const handleSubmitCancelBooking = () => {
    let params = {
      userID: loginData.uid,
      userCode: loginData.aCode,
      appID: 1,
      firstName: callerDetails.firstName,
      lastName: callerDetails.lastName,
      address: callerDetails.address,
      phone: callerDetails.phoneNumber1,
      phone2: callerDetails.phoneNumber2,
      suburb: suburb,
      stateID: stateID,
      service_area_postcode: postCode,
      company_id: companyID,
      reschedule: callerDetails.arrangeCall,
      messageType: "cancel_booking",
    };

    httpClient
      .post(
        "saveCallLog2.php",
        qs.stringify({
          action: "save_call",
          source: params,
        })
      )
      .then(({ data }) => {
        if (data.success == "true") {
          setShowResetButton(true);
          NotificationManager.success(data.msg);
        } else {
          NotificationManager.warning(data.msg);
        }
      });
  };

  const handleSubmitMessageOperator = () => {
    let params = {
      userID: loginData.uid,
      userCode: loginData.aCode,
      appID: 1,
      firstName: messageOperatorDetails.firstName,
      lastName: messageOperatorDetails.lastName,
      phone: messageOperatorDetails.phoneNumber1,
      phone2: messageOperatorDetails.phoneNumber2,
      notes: messageOperatorDetails.message,
      suburb: suburb,
      stateID: stateID,
      service_area_postcode: postCode,
      company_id: companyID,
      messageType: "Message_For_Operator",
    };

    httpClient
      .post(
        "saveCallLog2.php",
        qs.stringify({
          action: "save_call",
          source: params,
        })
      )
      .then(({ data }) => {
        if (data.success == "true") {
          setShowResetButton(true);
          NotificationManager.success(data.msg);
        } else {
          NotificationManager.warning(data.msg);
        }
      });
  };

  const handleDeclinedSubmit = () => {
    let params = {
      userID: loginData.uid,
      userCode: loginData.aCode,
      appID: 1,
      firstName: messageOperatorDetails.firstName,
      lastName: messageOperatorDetails.lastName,
      phone: messageOperatorDetails.phoneNumber1,
      phone2: messageOperatorDetails.phoneNumber2,
      message: messageOperatorDetails.message,
      suburb: suburb,
      stateID: stateID,
      service_area_postcode: postCode,
      company_id: companyID,
      messageType: "Declined_to_Proceed",
    };

    httpClient
      .post(
        "saveCallLog2.php",
        qs.stringify({
          action: "save_call",
          source: params,
        })
      )
      .then(({ data }) => {
        if (data.success == "true") {
          setShowResetButton(true);
          NotificationManager.success(data.msg);
        } else {
          NotificationManager.warning(data.msg);
        }
      });
  };

  const handleSubmitFranchiseEnquiry = () => {
    let params = {
      userID: loginData.uid,
      userCode: loginData.aCode,
      appID: 1,
      firstName: enquiryDetails.firstName,
      lastName: enquiryDetails.lastName,
      phone: enquiryDetails.phoneNumber1,
      phone2: enquiryDetails.phoneNumber2,
      address: enquiryDetails.address,
      notes: enquiryDetails.other,
      referredBy: enquiryDetails.hearingSource,
      message: enquiryDetails.message,
      suburb: suburb,
      stateID: stateID,
      service_area_postcode: postCode,
      company_id: companyID,
      messageType: "Franchise_Enquiry",
    };

    httpClient
      .post(
        "saveCallLog2.php",
        qs.stringify({
          action: "save_call",
          source: params,
        })
      )
      .then(({ data }) => {
        if (data.success == "true") {
          setShowResetButton(true);
          NotificationManager.success(data.msg);
        } else {
          NotificationManager.warning(data.msg);
        }
      });
  };

  const handleSubmitAdEnquiry = () => {
    let params = {
      userID: loginData.uid,
      userCode: loginData.aCode,
      appID: 1,
      firstName: enquiryDetails.firstName,
      lastName: enquiryDetails.lastName,
      phone: enquiryDetails.phoneNumber1,
      phone2: enquiryDetails.phoneNumber2,
      address: enquiryDetails.address,
      notes: enquiryDetails.other,
      referredBy: enquiryDetails.hearingSource,
      message: enquiryDetails.message,
      suburb: suburb,
      stateID: stateID,
      service_area_postcode: postCode,
      company_id: companyID,
      messageType: "Advertising_Enquiry",
    };

    httpClient
      .post(
        "saveCallLog2.php",
        qs.stringify({
          action: "save_call",
          source: params,
        })
      )
      .then(({ data }) => {
        if (data.success == "true") {
          setShowResetButton(true);
          NotificationManager.success(data.msg);
        } else {
          NotificationManager.warning(data.msg);
        }
      });
  };

  const handleSubmitEnquiry = (type, postCode, suburb) => {
    let params = {
      userID: loginData.uid,
      userCode: loginData.aCode,
      appID: 1,
      firstName: enquiryDetails.firstName,
      lastName: enquiryDetails.lastName,
      phone: enquiryDetails.phoneNumber1,
      phone2: enquiryDetails.phoneNumber2,
      address: enquiryDetails.address || "",
      notes: enquiryDetails.other || "",
      referredBy: "",
      message: enquiryDetails.message,
      suburb: suburb,
      stateID: stateID || "",
      service_area_postcode: postCode,
      company_id: "",
      messageType: type,
    };

    httpClient
      .post(
        "saveCallLog2.php",
        qs.stringify({
          action: "save_call",
          source: params,
        })
      )
      .then(({ data }) => {
        if (data.success == "true") {
          setShowResetButton(true);
          NotificationManager.success(data.msg);
        } else {
          NotificationManager.warning(data.msg);
        }
      });
  };

  const handleSubmitComplaint = () => {
    let params = {
      userID: loginData.uid,
      userCode: loginData.aCode,
      appID: 1,
      firstName: enquiryDetails.firstName,
      lastName: enquiryDetails.lastName,
      phone: enquiryDetails.phoneNumber1,
      phone2: enquiryDetails.phoneNumber2,
      address: enquiryDetails.address,

      suburb: suburb,
      stateID: stateID,
      service_area_postcode: postCode,
      company_id: companyID,
      message: enquiryDetails.message,
      messageType: "Complaint",
    };

    httpClient
      .post(
        "saveCallLog2.php",
        qs.stringify({
          action: "save_call",
          source: params,
        })
      )
      .then(({ data }) => {
        if (data.success == "true") {
          setShowResetButton(true);
          NotificationManager.success(data.msg);
        } else {
          NotificationManager.warning(data.msg);
        }
      });
  };

  const handleSubmitFranchiseChange = () => {
    let params = {
      userID: loginData.uid,
      userCode: loginData.aCode,
      appID: 1,
      // firstName: enquiryDetails.firstName,
      // lastName: enquiryDetails.lastName,
      // phone: enquiryDetails.phoneNumber1,
      // phone2: enquiryDetails.phoneNumber2,
      // address: enquiryDetails.address,

      // suburb: suburb,
      // stateID: stateID,
      // service_area_postcode: postCode,
      // company_id: companyID,
      franchiseeName: franchise,
      message: enquiryDetails.message,
      messageType: "Franchisee calling to change suburb",
    };

    httpClient
      .post(
        "saveCallLog2.php",
        qs.stringify({
          action: "save_call",
          source: params,
        })
      )
      .then(({ data }) => {
        if (data.success == "true") {
          setShowResetButton(true);
          NotificationManager.success(data.msg);
        } else {
          NotificationManager.warning(data.msg);
        }
      });
  };

  const handleSaveCall = () => {
    setStep(step + 1);
    setSaveStep(1);
    setEndCall(1);
  };

  const handleBackEndCall = () => {
    setStep(step - 1);
    setSaveStep(0);
    setEndCall(0);
  };

  const handleEndCall = () => {
    let params = {
      userID: loginData.uid,
      userCode: loginData.aCode,
      appID: 1,
      firstName: saveCaller.firstName,
      lastName: saveCaller.lastName,
      phone: saveCaller.phoneNumber1,
      phone2: saveCaller.phoneNumber2,
      address: saveCaller.address,
      suburb: saveCaller.suburb,
      service_area_postcode: saveCaller.postCode,
      messageType: "end_call",
    };

    httpClient
      .post(
        "saveCallLog2.php",
        qs.stringify({
          action: "save_call",
          source: params,
        })
      )
      .then(({ data }) => {
        if (data.success == "true") {
          setShowResetButton(true);
          NotificationManager.success(data.msg);
        } else {
          NotificationManager.warning(data.msg);
        }
      });
  };

  const continueEnquiryProps = {
    handleEnquiryChange: handleEnquiryChange,
    handleEnquiryPress: handleEnquiryPress,
    enquiryDetails: enquiryDetails,
    enquiryPressed: enquiryPressed,
    suspendedFranchisee: suspendedFranchisee,
    handleChange: handleChange,
    handleBack: handleBackEq,
    handleSubmit: handleSubmitEnquiry,
    showResetButton: showResetButton,
    handleReset: handleReset,
  };

  return (
    <>
      {step === 1 && <SelectOrganization handleInfo={handleInfo} />}
      {step === 2 && (
        <CallScenerios
          step={step}
          header={
            boxInfo === "Blue Wheelers"
              ? `Welcome to Blue Wheelers Wash,clip and groom. How may I help you ?`
              : `Welcome to Dash Dog. How may I help you ?`
          }
          radios={radios}
          handleRadioChange={handleRadioChange}
          handleBack={handleBack}
          // handleNext={
          //   radios === "Franchisee calling to change suburb"
          //     ? handleNone
          //     : handleNext
          // }
          handleNext={handleNext}
        />
      )}
      {step === 3 && radios === "Service Required" && (
        <ServiceRequired
          serviceSelect={serviceSelect}
          handleServiceChange={handleServiceChange}
          handleBack={handleBack}
          handleNext={handleNextService}
          radios={radios}
          setPreviousForwardedScenario={setPreviousForwardedScenario}
        />
      )}

      {/* Service Required */}
      {step === 4 && serviceStep === 1 && (
        <SelectPostCode
          currentOrganization={boxInfo}
          serviceRequired={true}
          handlePostCode={handlePostCode}
          setPostCode={setPostCode}
          handleSuburb={handleSuburb}
          handleFranchise={handleFranchise}
          setSuspendedFranchisee={setSuspendedFranchisee}
          postCode={postCode}
          suburb={suburb}
          franchise={franchise}
          setState={setState}
          setStateID={setStateID}
          setCompanyID={setCompanyID}
          setSuburb={setSuburb}
          setFranchise={setFranchise}
          setOperatorName={setOperatorName}
          handleBack={handleBackService}
          handleContinue={handleContinueService}
          handleSaveCall={handleSaveCall}
          saveCall={true}
          suburbList={suburbList}
          setSuburbList={setSuburbList}
          franchiseList={franchiseList}
          setFranchiseList={setFranchiseList}
          suburbCurrent={suburbCurrent}
          setSuburbCurrent={setSuburbCurrent}
          currentFranchiseList={currentFranchiseList}
          setCurrentFranchiseList={setCurrentFranchiseList}
          suburbStackList={suburbStackList}
          setSuburbStackList={setSuburbStackList}
          radios={radios}
          setPreviousForwardedScenario={setPreviousForwardedScenario}
          inputValue={inputValue}
          setInputValue={setInputValue}
          currentSelectedFranchise={currentSelectedFranchise}
          setCurrentSelectedFranchise={setCurrentSelectedFranchise}
          franchiseInput={franchiseInput}
          setFranchiseInput={setFranchiseInput}
          franchiseSuburbsList={franchiseSuburbsList}
          setFranchiseSuburbsList={setFranchiseSuburbsList}
          franchiseSuburb={franchiseSuburb}
          setFranchiseSuburb={setFranchiseSuburb}
        />
      )}
      {step === 5 && serviceStep === 2 && (
        <div>
          <ContinueServiceCall
            handleChange={handleChange}
            callerDetails={callerDetails}
            handleCallerFocus={handleCallerFocus}
            callerPressed={callerPressed}
            postCode={postCode}
            state={state}
            suburb={suburb}
            suspendedFranchisee={suspendedFranchisee}
            operatorName={operatorName}
            handleBack={handleBackService}
            // handleSubmitService={handleSubmitService}
            handleContinue={handleContinueService}
          />
        </div>
      )}
      {step === 6 && serviceStep === 3 && (
        <ReferallPage
          handleChange={handleChange}
          callerDetails={callerDetails}
          handleCallerFocus={handleCallerFocus}
          callerPressed={callerPressed}
          postCode={postCode}
          state={state}
          suburb={suburb}
          operatorName={operatorName}
          handleBack={handleBackService}
          handleSubmitService={handleSubmitService}
          showResetButton={showResetButton}
          handleReset={handleReset}
        />
      )}
      {/* Service Required */}

      {/* Cancel Booking */}
      {step === 3 && radios === "Cancel Booking" && (
        <SelectPostCode
          currentOrganization={boxInfo}
          cancelBooking={true}
          handlePostCode={handlePostCode}
          setPostCode={setPostCode}
          handleSuburb={handleSuburb}
          handleFranchise={handleFranchise}
          setSuspendedFranchisee={setSuspendedFranchisee}
          postCode={postCode}
          suburb={suburb}
          franchise={franchise}
          setState={setState}
          setStateID={setStateID}
          setCompanyID={setCompanyID}
          setSuburb={setSuburb}
          setFranchise={setFranchise}
          setOperatorName={setOperatorName}
          handleBack={handleBack}
          handleContinue={handleContinueCancel}
          handleSaveCall={handleSaveCall}
          saveCall={true}
          suburbList={suburbList}
          setSuburbList={setSuburbList}
          franchiseList={franchiseList}
          setFranchiseList={setFranchiseList}
          suburbCurrent={suburbCurrent}
          setSuburbCurrent={setSuburbCurrent}
          currentFranchiseList={currentFranchiseList}
          setCurrentFranchiseList={setCurrentFranchiseList}
          suburbStackList={suburbStackList}
          setSuburbStackList={setSuburbStackList}
          radios={radios}
          setPreviousForwardedScenario={setPreviousForwardedScenario}
          inputValue={inputValue}
          setInputValue={setInputValue}
          currentSelectedFranchise={currentSelectedFranchise}
          setCurrentSelectedFranchise={setCurrentSelectedFranchise}
          franchiseInput={franchiseInput}
          setFranchiseInput={setFranchiseInput}
          franchiseSuburbsList={franchiseSuburbsList}
          setFranchiseSuburbsList={setFranchiseSuburbsList}
          franchiseSuburb={franchiseSuburb}
          setFranchiseSuburb={setFranchiseSuburb}
        />
      )}

      {step === 4 && cancelStep === 1 && (
        <ContinueCancelBooking
          handleChange={handleChange}
          callerDetails={callerDetails}
          handleCallerFocus={handleCallerFocus}
          callerPressed={callerPressed}
          postCode={postCode}
          state={state}
          suburb={suburb}
          suspendedFranchisee={suspendedFranchisee}
          operatorName={operatorName}
          handleBack={handleBackCancel}
          handleContinue={handleContinueCancel}
        />
      )}
      {step === 5 && cancelStep === 2 && (
        <ReSchedule
          handleChange={handleChange}
          callerDetails={callerDetails}
          handleCallerFocus={handleCallerFocus}
          callerPressed={callerPressed}
          postCode={postCode}
          state={state}
          suburb={suburb}
          operatorName={operatorName}
          handleBack={handleBackCancel}
          handleSubmitCancelBooking={handleSubmitCancelBooking}
          showResetButton={showResetButton}
          handleReset={handleReset}
        />
      )}
      {/* Cancel Booking */}

      {/* Franchise Calling to change suburb */}
      {step === 3 && radios === "Franchisee calling to change suburb" && (
        <SelectFranchiseChange
          // setStateID={setStateID}
          // setCompanyID={setCompanyID}
          // setSuburb={setSuburb}
          setSuspendedFranchisee={setSuspendedFranchisee}
          setFranchise={setFranchise}
          handleContinue={handleContinueChangeSuburb}
          handleBack={handleBack}
          radios={radios}
          setPreviousForwardedScenario={setPreviousForwardedScenario}
        />
      )}

      {step === 4 && changeSuburbStep === 1 && (
        <ContinueFranchiseChange
          handleSubmitFranchiseChange={handleSubmitFranchiseChange}
          enquiryDetails={enquiryDetails}
          handleEnquiryChange={handleEnquiryChange}
          suspendedFranchisee={suspendedFranchisee}
          handleBack={handleBackChangeSuburb}
          // handleSubmitMessageOperator={handleSubmitMessageOperator}
          showResetButton={showResetButton}
          handleReset={handleReset}
        />
      )}

      {/* Franchise Calling to change suburb */}

      {/* Message for Operator */}
      {step === 3 && radios === "Message For Operator" && (
        // <MessageOperator
        //   handleChangeOperator={handleChangeOperator}
        //   messageOperatorDetails={messageOperatorDetails}
        //   messageOperatorPressed={messageOperatorPressed}
        //   handleBack={handleBack}
        //   handleContinue={handleContinueMessage}
        // />
        <SelectPostCode
          currentOrganization={boxInfo}
          messageOperator={true}
          handlePostCode={handlePostCode}
          setPostCode={setPostCode}
          handleSuburb={handleSuburb}
          handleFranchise={handleFranchise}
          setSuspendedFranchisee={setSuspendedFranchisee}
          postCode={postCode}
          suburb={suburb}
          franchise={franchise}
          setState={setState}
          setStateID={setStateID}
          setCompanyID={setCompanyID}
          setSuburb={setSuburb}
          setFranchise={setFranchise}
          setOperatorName={setOperatorName}
          handleChangeOperator={handleChangeOperator}
          messageOperatorDetails={messageOperatorDetails}
          messageOperatorPressed={messageOperatorPressed}
          handleBack={handleBack}
          handleContinue={handleContinueMessage}
          handleSaveCall={handleSaveCall}
          saveCall={true}
          suburbList={suburbList}
          setSuburbList={setSuburbList}
          franchiseList={franchiseList}
          setFranchiseList={setFranchiseList}
          suburbCurrent={suburbCurrent}
          setSuburbCurrent={setSuburbCurrent}
          currentFranchiseList={currentFranchiseList}
          setCurrentFranchiseList={setCurrentFranchiseList}
          suburbStackList={suburbStackList}
          setSuburbStackList={setSuburbStackList}
          radios={radios}
          setPreviousForwardedScenario={setPreviousForwardedScenario}
          inputValue={inputValue}
          setInputValue={setInputValue}
          currentSelectedFranchise={currentSelectedFranchise}
          setCurrentSelectedFranchise={setCurrentSelectedFranchise}
          franchiseInput={franchiseInput}
          setFranchiseInput={setFranchiseInput}
          franchiseSuburbsList={franchiseSuburbsList}
          setFranchiseSuburbsList={setFranchiseSuburbsList}
          franchiseSuburb={franchiseSuburb}
          setFranchiseSuburb={setFranchiseSuburb}
        />
      )}
      {step === 4 && messageStep === 1 && (
        <ContinueMessageOperator
          handleChangeOperator={handleChangeOperator}
          handlePressOperator={handlePressOperator}
          suburb={suburb}
          suspendedFranchisee={suspendedFranchisee}
          messageOperatorDetails={messageOperatorDetails}
          messageOperatorPressed={messageOperatorPressed}
          handleBack={handleBackMessage}
          handleSubmitMessageOperator={handleSubmitMessageOperator}
          showResetButton={showResetButton}
          handleReset={handleReset}
        />
      )}
      {/* Franchise Enquire */}
      {/* {step === 3 && radios === "Franchise Enquiry" && (
        <SelectPostCode
          currentOrganization={boxInfo}
          franchiseEnquiry={true}
          handlePostCode={handlePostCode}
          setPostCode={setPostCode}
          handleSuburb={handleSuburb}
          handleFranchise={handleFranchise}
          setSuspendedFranchisee={setSuspendedFranchisee}
          postCode={postCode}
          suburb={suburb}
          franchise={franchise}
          handleChange={handleChange}
          setState={setState}
          setStateID={setStateID}
          setCompanyID={setCompanyID}
          setSuburb={setSuburb}
          setFranchise={setFranchise}
          setOperatorName={setOperatorName}
          handleBack={handleBack}
          handleContinue={handleContinueEnquiry}
          handleSaveCall={handleSaveCall}
          saveCall={false}
          suburbList={suburbList}
          setSuburbList={setSuburbList}
          franchiseList={franchiseList}
          setFranchiseList={setFranchiseList}
          suburbCurrent={suburbCurrent}
          setSuburbCurrent={setSuburbCurrent}
          currentFranchiseList={currentFranchiseList}
          setCurrentFranchiseList={setCurrentFranchiseList}
          suburbStackList={suburbStackList}
          setSuburbStackList={setSuburbStackList}
          radios={radios}
          setPreviousForwardedScenario={setPreviousForwardedScenario}
          inputValue={inputValue}
          setInputValue={setInputValue}
          currentSelectedFranchise={currentSelectedFranchise}
          setCurrentSelectedFranchise={setCurrentSelectedFranchise}
          franchiseInput={franchiseInput}
          setFranchiseInput={setFranchiseInput}
          franchiseSuburbsList={franchiseSuburbsList}
          setFranchiseSuburbsList={setFranchiseSuburbsList}
          franchiseSuburb={franchiseSuburb}
          setFranchiseSuburb={setFranchiseSuburb}
        />
      )} */}

      {step === 3 && radios === "Franchise Enquiry" && (
        <ContinueEnquiry franchiseEnquiry {...continueEnquiryProps} />
      )}
      {/* {step === 5 && enquiryStep === 2 && (
        <ReferallEnquiry
          franchiseEnquiry={true}
          handleEnquiryChange={handleEnquiryChange}
          handleEnquiryPress={handleEnquiryPress}
          enquiryDetails={enquiryDetails}
          enquiryPressed={enquiryPressed}
          suburb={suburb}
          state={state}
          postCode={postCode}
          handleChange={handleChange}
          handleBack={handleBackEq}
          handleSubmitFranchiseEnquiry={handleSubmitFranchiseEnquiry}
          showResetButton={showResetButton}
          handleReset={handleReset}
        />
      )} */}
      {/* Franchise Enquire */}

      {/* Advertising Enquire */}
      {/* {step === 3 && radios === "Advertising Enquiry" && (
        <SelectPostCode
          currentOrganization={boxInfo}
          advertisingEnquiry={true}
          handlePostCode={handlePostCode}
          setPostCode={setPostCode}
          handleSuburb={handleSuburb}
          handleFranchise={handleFranchise}
          setSuspendedFranchisee={setSuspendedFranchisee}
          postCode={postCode}
          suburb={suburb}
          franchise={franchise}
          setState={setState}
          setStateID={setStateID}
          setCompanyID={setCompanyID}
          setSuburb={setSuburb}
          setFranchise={setFranchise}
          setOperatorName={setOperatorName}
          handleBack={handleBack}
          handleContinue={handleContinueAdEnquiry}
          handleSaveCall={handleSaveCall}
          saveCall={false}
          suburbList={suburbList}
          setSuburbList={setSuburbList}
          franchiseList={franchiseList}
          setFranchiseList={setFranchiseList}
          suburbCurrent={suburbCurrent}
          setSuburbCurrent={setSuburbCurrent}
          currentFranchiseList={currentFranchiseList}
          setCurrentFranchiseList={setCurrentFranchiseList}
          suburbStackList={suburbStackList}
          setSuburbStackList={setSuburbStackList}
          radios={radios}
          setPreviousForwardedScenario={setPreviousForwardedScenario}
          inputValue={inputValue}
          setInputValue={setInputValue}
          currentSelectedFranchise={currentSelectedFranchise}
          setCurrentSelectedFranchise={setCurrentSelectedFranchise}
          franchiseInput={franchiseInput}
          setFranchiseInput={setFranchiseInput}
          franchiseSuburbsList={franchiseSuburbsList}
          setFranchiseSuburbsList={setFranchiseSuburbsList}
          franchiseSuburb={franchiseSuburb}
          setFranchiseSuburb={setFranchiseSuburb}
        />
      )} */}

      {step === 3 && radios === "Advertising Enquiry" && (
        <ContinueEnquiry advertisingEnquiry {...continueEnquiryProps} />
      )}
      {/* {step === 5 && adEnquiryStep === 2 && (
        <ReferallEnquiry
          advertisingEnquiry={true}
          handleEnquiryChange={handleEnquiryChange}
          handleEnquiryPress={handleEnquiryPress}
          enquiryDetails={enquiryDetails}
          enquiryPressed={enquiryPressed}
          suburb={suburb}
          state={state}
          postCode={postCode}
          handleChange={handleChange}
          handleBack={handleBackAd}
          handleSubmitFranchiseEnquiry={handleSubmitAdEnquiry}
          showResetButton={showResetButton}
          handleReset={handleReset}
        />
      )} */}
      {/* Advertising Enquire */}

      {/* Complaint */}
      {step === 3 && radios === "Complaint" && (
        <SelectPostCode
          currentOrganization={boxInfo}
          complaint={true}
          handlePostCode={handlePostCode}
          setPostCode={setPostCode}
          handleSuburb={handleSuburb}
          handleFranchise={handleFranchise}
          setSuspendedFranchisee={setSuspendedFranchisee}
          postCode={postCode}
          suburb={suburb}
          franchise={franchise}
          setState={setState}
          setStateID={setStateID}
          setCompanyID={setCompanyID}
          setSuburb={setSuburb}
          setFranchise={setFranchise}
          handleChange={handleChange}
          setOperatorName={setOperatorName}
          handleBack={handleBack}
          handleContinue={handleContinueComplaint}
          handleSaveCall={handleSaveCall}
          suburbList={suburbList}
          setSuburbList={setSuburbList}
          franchiseList={franchiseList}
          setFranchiseList={setFranchiseList}
          suburbCurrent={suburbCurrent}
          setSuburbCurrent={setSuburbCurrent}
          currentFranchiseList={currentFranchiseList}
          setCurrentFranchiseList={setCurrentFranchiseList}
          suburbStackList={suburbStackList}
          setSuburbStackList={setSuburbStackList}
          radios={radios}
          setPreviousForwardedScenario={setPreviousForwardedScenario}
          inputValue={inputValue}
          setInputValue={setInputValue}
          currentSelectedFranchise={currentSelectedFranchise}
          setCurrentSelectedFranchise={setCurrentSelectedFranchise}
          franchiseInput={franchiseInput}
          setFranchiseInput={setFranchiseInput}
          franchiseSuburbsList={franchiseSuburbsList}
          setFranchiseSuburbsList={setFranchiseSuburbsList}
          franchiseSuburb={franchiseSuburb}
          setFranchiseSuburb={setFranchiseSuburb}
        />
      )}

      {step === 4 && complaintStep === 1 && (
        <ContinueComplaint
          handleEnquiryChange={handleEnquiryChange}
          handleEnquiryPress={handleEnquiryPress}
          enquiryDetails={enquiryDetails}
          enquiryPressed={enquiryPressed}
          suburb={suburb}
          state={state}
          postCode={postCode}
          suspendedFranchisee={suspendedFranchisee}
          handleChange={handleChange}
          handleBack={handleBackComplaint}
          handleSubmitComplaint={handleSubmitComplaint}
          showResetButton={showResetButton}
          handleReset={handleReset}
        />
      )}
      {/* Complaint */}

      {/* Declined to proceed */}
      {step === 3 && radios === "Declined to Proceed" && (
        <SelectPostCode
          currentOrganization={boxInfo}
          declined={true}
          handlePostCode={handlePostCode}
          setPostCode={setPostCode}
          handleSuburb={handleSuburb}
          handleFranchise={handleFranchise}
          setSuspendedFranchisee={setSuspendedFranchisee}
          postCode={postCode}
          suburb={suburb}
          franchise={franchise}
          setState={setState}
          setStateID={setStateID}
          setCompanyID={setCompanyID}
          setSuburb={setSuburb}
          setFranchise={setFranchise}
          handleChange={handleChange}
          setOperatorName={setOperatorName}
          handleBack={handleBack}
          handleContinue={handleContinueDecline}
          handleSaveCall={handleSaveCall}
          suburbList={suburbList}
          setSuburbList={setSuburbList}
          franchiseList={franchiseList}
          setFranchiseList={setFranchiseList}
          suburbCurrent={suburbCurrent}
          setSuburbCurrent={setSuburbCurrent}
          currentFranchiseList={currentFranchiseList}
          setCurrentFranchiseList={setCurrentFranchiseList}
          suburbStackList={suburbStackList}
          setSuburbStackList={setSuburbStackList}
          radios={radios}
          setPreviousForwardedScenario={setPreviousForwardedScenario}
          inputValue={inputValue}
          setInputValue={setInputValue}
          currentSelectedFranchise={currentSelectedFranchise}
          setCurrentSelectedFranchise={setCurrentSelectedFranchise}
          franchiseInput={franchiseInput}
          setFranchiseInput={setFranchiseInput}
          franchiseSuburbsList={franchiseSuburbsList}
          setFranchiseSuburbsList={setFranchiseSuburbsList}
          franchiseSuburb={franchiseSuburb}
          setFranchiseSuburb={setFranchiseSuburb}
        />
      )}
      {step === 4 && declineStep === 1 && (
        <ContinueDeclineOperator
          handleChangeOperator={handleChangeOperator}
          handlePressOperator={handlePressOperator}
          suburb={suburb}
          messageOperatorDetails={messageOperatorDetails}
          messageOperatorPressed={messageOperatorPressed}
          handleBack={handleBackDecline}
          suspendedFranchisee={suspendedFranchisee}
          handleDeclinedSubmit={handleDeclinedSubmit}
          showResetButton={showResetButton}
          handleReset={handleReset}
        />
      )}
      {/* Declined to proceed */}

      {/*Save Call */}
      {saveStep === 1 && endCall === 1 && (
        <SaveCall
          saveCaller={saveCaller}
          saveCallerPressed={saveCallerPressed}
          handleChangeSave={handleChangeSave}
          handleChangeSavePressed={handleChangeSavePressed}
          handleBack={handleBackEndCall}
          handleEndCall={handleEndCall}
          showResetButton={showResetButton}
          handleReset={handleReset}
        />
      )}
      {/*Save Call */}
      <NotificationContainer />
    </>
  );
};

export default lodgeCall2;
