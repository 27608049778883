import { NotificationManager } from "react-notifications";

/**
 * Validates a phone number using a regular expression.
 *
 * The regular expression /^[0-9]{10}$/ ensures that the phone number consists of exactly 10 digits.
 * Additional validation regex may be added and exported if required.
 *
 * @param {string} phoneNumber - The phone number to validate.
 * @returns {boolean} True if the phone number is valid, otherwise false.
 */
const isValidPhoneNumber = (phoneNumber) => {
  const regex = /^[0-9]{10}$/;
  return regex.test(phoneNumber);
};

/**
 * Validates two phone numbers
 *
 * @param {string} number1 - First phone number to validate.
 * @param {string} number2 - Second phone number to validate.
 * @returns {boolean} True if the phone number is/are valid, otherwise false.
 */

export const handlePhoneNumberValidation = (number1, number2) => {
  if (number1 && number2) {
    if (!isValidPhoneNumber(number1) && !isValidPhoneNumber(number2)) {
      NotificationManager.warning("Invalid Phone Number");
      return false;
    }
    if (!isValidPhoneNumber(number1)) {
      NotificationManager.warning("Invalid Phone Number 1");
      return false;
    }
    if (!isValidPhoneNumber(number2)) {
      NotificationManager.warning("Invalid Phone Number 2");
      return false;
    }
  }

  if (number1 && !isValidPhoneNumber(number1)) {
    NotificationManager.warning("Invalid Phone Number 1");
    return false;
  }

  if (number2 && !isValidPhoneNumber(number2)) {
    NotificationManager.warning("Invalid Phone Number 2");
    return false;
  }

  return true;
};
