import React, { Component } from "react";
import { Link } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { Redirect } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  NotificationContainer,
  // NotificationManager
} from "react-notifications";
import {
  //hideMessage,
  //showAuthLoader,
  GetAdminVerify,
} from "actions/AuthAdmin";
import ReCAPTCHA from "react-google-recaptcha";
const recaptchaRef = React.createRef();

var isAuthenticated = "A";

class Admin extends Component {
  constructor() {
    super();

    this.state = {
      email: "",
      password: "",
      capcha: "",
    };
  }
  
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    // let capcha = recaptchaRef.current.getValue();
    // console.log('capcha is', capcha);
  };

  handleCapcha = () => {
    this.setState({
      capcha: recaptchaRef.current.getValue()
    })
  }

  submit = (e) => {
    e.preventDefault();
   
  };

  render() {
    const { email, password, capcha } = this.state;

    if (isAuthenticated === 1) {
      return <Redirect to={"/ForgotPassword"} />;
    }

    // console.log('capcha is', this.state.capcha);

    return (
      <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">
          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="Mate">
              <img
                src={require("assets/images/logo.png")}
                alt="Mate"
                title="Mate"
              />
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1>Login to Call Center</h1>
            </div>

            <div className="app-login-form">
              <form onSubmit={this.submit}>
                <fieldset>
                  <TextField
                    label={<IntlMessages id="appModule.email" />}
                    fullWidth
                    defaultValue={email}
                    name="email"
                    value={this.state.textValue}
                    onChange={this.handleChange}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />
                  <TextField
                    type="password"
                    label={<IntlMessages id="appModule.password" />}
                    fullWidth
                    name="password"
                    defaultValue={password}
                    onChange={this.handleChange}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6Ldt_ksbAAAAABYGmSe4ew7z_VD03Lp1bXH7yBI1"
                        onChange={this.handleCapcha}
                    />  
                  <div className="mt-2 mb-3 d-flex align-items-center justify-content-between">
                    <Button
                      type="submit"
                      onClick={() => {
                        // this.handleLogin();
                        // alert(isAuthenticated)
                        // NotificationManager.info('Verifying User Detail');
                        GetAdminVerify({ email, password });
                        //alert (isAuthenticated)
                      }}
                      variant="contained"
                      color="primary"
                      disabled={capcha && email && password ? false : true}
                    >
                      Login
                    </Button>

                    {/*<Link to="/ForgotPassword">
                            <IntlMessages id="signIn.forgotPassword"/>
                          </Link>*/}
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
        {/*
              {
                loader &&
                <div className="loader-view">
                  <CircularProgress/>
                </div>
              }
              
      
              {showMessage && NotificationManager.error(alertMessage)}
      */}
        <NotificationContainer />
      </div>
    );
  }
}

export default Admin;
