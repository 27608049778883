import axios from "axios";
import { config } from "./global";

/*
export default axios.create({
  //baseURL: `http://67.205.129.195/medifellows/api/public/api/v1/`
	baseURL: 'http://localhost:3000/php/'
});
*/

export const httpClient = axios.create({
  baseURL: config.apiUrl,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
  },
});
