import React,{useEffect, useState} from 'react'
import { Button, Input, Card, Badge } from "reactstrap";
import { ResponsiveContainer } from "recharts";
import { httpClient } from "appUtility/Api";
import {
  NotificationManager,
  NotificationContainer
} from "react-notifications";
import { history } from '../../../store';

const qs = require("qs");
const loginData = JSON.parse(window.localStorage.getItem("login"));

const ResetPassword = (props) => {
    const [passwordData, setPassWordData] = useState({
        userID: loginData.uid,
        userCode: loginData.aCode,
        username: "",
        appID: "1",
        // currentpassword: "",
        newpassword: "",
        renewpassword: "",
        editMemberID: memID ? memID : "0",
      });

    const [repeatPassword, setRepeatPassword] = useState("");
    const [memID, setMemID] = useState(props.location.pathname.substring(19));
    // const [oldPasswordCheck, setOldPasswordCheck] = useState(false);
    const [newPasswordCheck, setNewPasswordCheck] = useState(false);
    const [repeatPasswordCheck, setRepeatPasswordCheck] = useState(false);


    useEffect(() => {
        getMemberDetail();
    },[])

    const handleChange = e => {
        const { name, value } = e.target;
        setPassWordData({ ...passwordData, [name]: value });
    };  

    // const handleRepeatPassword = e => {
    //     setRepeatPassword(e.target.value);
    // };


    const getMemberDetail = () => {
        const memberDetail = {
          userID: loginData && loginData.uid,
          userCode: loginData && loginData.aCode,
          appID: "1",
          profileOfMemberID: memID ? memID : 0
        }
      
        memID !== "" && (
          httpClient
              .post(
                "getMemberDetail.php",
                qs.stringify({
                  action: "get_member_detail",
                  source: memberDetail,
                })
              )
              .then((res) => {
                console.log('member after response', res.data);
                setPassWordData({
                  ...passwordData,
                  username: res.data[0].username,
                  editMemberID: memID ? memID : "0",
                });
              })
        )
      }

      const requestPasswordChange = () => {

        if (passwordData.newpassword === "") {
          setNewPasswordCheck(true);
        }
    
        if (passwordData.renewpassword === "") {
          setRepeatPasswordCheck(true);
        }
    
        if (passwordData.renewpassword !== passwordData.newpassword) {
          NotificationManager.error("New passwords don't match !");
        }
    
        if (
          passwordData.renewpassword !== "" &&
          passwordData.newpassword !== "" &&
        //   repeatPassword !== "" &&
          passwordData.renewpassword === passwordData.newpassword
        ) {
          httpClient
            .post(
              "resetPassword.php",
              qs.stringify({
                action: "reset_password",
                source: passwordData
              })
            )
            .then(res => {
              if (res.data.success !== "true") {
                NotificationManager.warning(res.data.msg);
              } else {
                NotificationManager.success(res.data.msg);
                setTimeout(() => {
                  if (typeof window !== "undefined") {
                    window.location.href = "/app/adminDashboard";
                  }
                }, 400);
              }
            });
        }else{
            console.log('failed to reset password');
        }
      }; 

      const cancelChange = () => {
        history.push("/app/adminDashboard") 
      };

      console.log('state passworddddd'. passwordData);

    return (
        <ResponsiveContainer>
      <div className="changePassword-section">
        <Card className="password-card">
          <div style={{ margin: "2%" }}>
            <h1 className="font-weight-semibold">
              <Badge color="light" pill>
                Reset Password
              </Badge>
            </h1>

            <Input
              type="text"
              name="username"
              value={passwordData.username}
              disabled
            />
            <br />


            <Input
              type="password"
              placeholder="New password"
              name="newpassword"
              value={passwordData.newpassword}
              onChange={e => handleChange(e)}
              error={newPasswordCheck}
            />
            <div>
              {newPasswordCheck && (
                <div style={{ fontSize: "12px", color: "rgb(244, 67, 54)" }}>
                  Please enter your new password !
                </div>
              )}
            </div>
            <br />

            <Input
              type="password"
              placeholder="Re-enter New password"
              name="renewpassword"
              value={passwordData.renewpassword}
              onChange={e => handleChange(e)}
              error={repeatPasswordCheck}
            />
            <div>
              {repeatPasswordCheck && (
                <div style={{ fontSize: "12px", color: "rgb(244, 67, 54)" }}>
                  Please Repeat your new password !
                </div>
              )}
            </div>
            <br />

            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <Button
                type="submit"
                color="primary"
                size="sm"
                onClick={requestPasswordChange}
              >
                Change
              </Button>

              <Button
                type="submit"
                color="default"
                size="sm"
                onClick={cancelChange}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Card>
        <NotificationContainer />
      </div>
    </ResponsiveContainer>
    )
}

export default ResetPassword
