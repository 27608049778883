import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import FormDialog from "./FormDialog";
import { httpClient } from "appUtility/Api";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import moment from "moment";
import { DatePicker } from "material-ui-pickers";
import Button from "@material-ui/core/Button";
import { ResponsiveContainer } from "recharts";
import LinearBuffer from "./LinearBuffer";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import MenuIcon from "@material-ui/icons/Menu";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from "@material-ui/core";

import { KeyboardArrowRight, KeyboardArrowLeft } from "@material-ui/icons";
import PropTypes from "prop-types";

const qs = require("qs");
const loginData = JSON.parse(window.localStorage.getItem("login"));

// var counter = 1;

const columnHead = [
  // {
  //   id: "_id",
  //   align: false,
  //   disablePadding: "default",
  //   label: "SN"
  // },
  {
    id: "date",
    align: "center",
    disablePadding: "default",
    label: "Added Date/Time",
  },
  {
    id: "franchiseeName",
    align: false,
    disablePadding: "default",
    label: "Franchisee Name",
  },
  {
    id: "customer_name",
    align: false,
    disablePadding: "default",
    label: "Customer",
  },
  { id: "phone", align: "center", disablePadding: "default", label: "Phone" },
  {
    id: "address",
    align: "center",
    disablePadding: "default",
    label: "Address",
  },
  {
    id: "suburb",
    align: "center",
    disablePadding: "default",
    label: "Suburb",
  },
  // {
  //   id: "addedBy",
  //   align: "center",
  //   disablePadding: "default",
  //   label: "Added By",
  // },
    {
    id: "callType",
    align: "center",
    disablePadding: "default",
    label: "Call Type",
  },
  {
    id: "action",
    align: "center",
    disablePadding: "default",
    label: "Actions",
  },
];

function TablePaginationActions(props) {
  // const classes = useStyles();
  const { count, page, rowsPerPage, onChangePage } = props;
  const theme = useTheme();
  // console.log("props", props);

  const handleBackButtonClick = (event) => {
    onChangePage(event, parseInt(page) - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, parseInt(page) + 1);
  };

  return (
    // <div className={classes.flexBtn}>
    <div style={{ display: "flex" }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={(page) == 1}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={(page) >= Math.ceil(count / rowsPerPage)}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const EnhancedTableHead = () => {
  return (
    <>
      <TableHead>
        <TableRow>
          {columnHead.map((column) => {
            return (
              <TableCell
                key={column.id}
                numeric={column.numeric}
                padding={column.disablePadding}
              >
                {column.label}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

const EnhancedTable = () => {
  const classes = useStyles();

  var [page, setPage] = useState(1);
  var [rowsPerPage, setRowsPerPage] = useState(20);
  var [from, setFrom] = useState("");
  var [to, setTo] = useState("");
  // var page = 0;
  // var rowsPerPage = 20;
  const [total, setTotal] = useState(0);

  const [tableState, setTableState] = useState({
    order: "asc",
    orderBy: "_id",
    selected: [],
    getCallQuery: {
      userID: loginData && loginData.uid,
      userCode: loginData && loginData.aCode,
    },
    callerData: [],
    page: 1,
    rowsPerPage: 20,
  });

  const [filterDate, setFilterDate] = useState({
    userID: loginData && loginData.uid,
    userCode: loginData && loginData.aCode,
    appID: "1",
    start_date: null,
    end_date: null,
  });

  const [dateCheck, setDateCheck] = useState(false); //---This is a boolean that is used to check if user tries to search without entering the date
  // const [accordCheck, setAccordCheck] = useState(false);
  const [filterCheck, setFilterCheck] = useState(false); //---This is a boolean that will be true when the response of the API is null

  //************************search box states *************************//
  const [filterQuery, setFilterQuery] = useState({
    userID: loginData && loginData.uid,
    userCode: loginData && loginData.aCode,
    search: "",
    callType: "",
    franchiseName: "",
  });

  const [searchMode, setSearchMode] = useState(false); //---this state enables to show the "latest search result section" depending upon the boolean
  // const [queryCheck, setQueryCheck] = useState(false);
  const [filterCount, setFilterCount] = useState(); //----this state stores the value of count that is received in the API---//
  //******************************************************************//
  const [franchiseList, setFranchiseList] = useState();

  useEffect(() => {
    getTableData();
    getAllFranchise();
  }, []);

  const getAllFranchise = () => {
    const params = {
      userID: loginData && loginData.uid,
      userCode: loginData && loginData.aCode,
      appID: loginData && loginData.aid,
    };
    httpClient
      .post(
        "getAllFranchisee.php",
        qs.stringify({
          action: "all_franchisee",
          source: params,
        })
      )
      .then(({ data }) => {
        if (data) {
          setFranchiseList(data.data);
        }
      });
  };
  // console.log("frachise list", franchiseList);
  //*********************************Paginations Functions *************************************************//
  const handleChangePage = (event, newPage) => {
    // setPage(newPage + 1);
    page = newPage;
    
    setTimeout(() => {
      getSearchResults();
    }, 500);
  };
  

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(event.target.value);
    // setPage(0);
    rowsPerPage = event.target.value;
    page = 1;
   
    setTimeout(() => {
      getSearchResults();
    }, 500);
  };
  //*********************************Paginations Functions *************************************************//

  //*********************************Search box functions *************************************************//
  const handleSearchQuery = (e) => {
    setFilterQuery({ ...filterQuery, search: e.target.value });
  };

  const getSearchResults = () => {
    //-------This function filters the list of all calls using the query entered in the search box"---//
    setFilterCheck(false);
    setFilterCount(null);
    // setFilterDate({ ...filterDate, start_date: null, end_date: null });
    
    let newParams = {
      userID: loginData && loginData.uid,
      userCode: loginData && loginData.aCode,
      start_date: filterDate.start_date,
      end_date: filterDate.end_date,
      franchiseeName: filterQuery.franchiseName,
      search: filterQuery.search,
      page: page,
      per_page: rowsPerPage,
      messageType: filterQuery.callType,

    };
    // if (
    //   filterQuery.callType == "Service_Required" ||
    //   filterQuery.callType == ""
    // ) {
      // setSearchMode(false);
      setFilterCheck(false);
      // setFilterQuery({ ...filterQuery, search: "" });
      setTableState({ ...tableState, callerData: [] });
      httpClient
        .post(
          "getCallLog2.php",
          // "getOtherLog.php",

          qs.stringify({
            action: "call_log",
            source: newParams,
          })
        )
        .then((res) => {
          if (res.data === null) {
            NotificationManager.warning("Data not available right now");
          } else {
            if (res.data.data === null || res.data.data === "") {
              setFilterCheck(true);
              // setSearchMode(true);
            }
            // setSearchMode(true);

            // setFilterDate({ ...filterDate, start_date: null, end_date: null });
            setTableState({ ...tableState, callerData: res.data.data });
            setTotal(res.data.meta.total);
            setPage(res.data.meta.current_page);
            setRowsPerPage(res.data.meta.per_page);
            setFrom(res.data.meta.from);
            setTo(res.data.meta.to);
          }
        });
   
  };

  const filterRecentCalls = () => {
    //--------This function filters the data for "Recent Calls"---------//
    // if (filterQuery.search === "") {
    if (filterQuery.callType === "" && filterQuery.franchiseName === "" && filterQuery.search === "" && filterDate.start_date === null && filterDate.end_date === null) {
      // setQueryCheck(true);
      setSearchMode(false);
    } else {
      // setQueryCheck(false);
      setSearchMode(true);
      getSearchResults();
    }
  };

  //*************************************************************************************************//

  const getTableData = () => {
    setSearchMode(false);
    setFilterCheck(false);
    setTableState({ ...tableState, callerData: [] });
    setFilterQuery({ search: "", callType: "", franchiseName: "" });
    let newParams2 = {
      userID: loginData && loginData.uid,
      userCode: loginData && loginData.aCode,
      start_date: filterDate.start_date,
      end_date: filterDate.end_date,
      // franchiseeName: filterQuery.franchiseName,
      search: filterQuery.search,
      page: page,
      per_page: rowsPerPage,
    };
    httpClient
      .post(
        "getCallLog2.php",
        // "getOtherLog.php",

        qs.stringify({
          action: "call_log",
          // source: tableState.getCallQuery,
          source: newParams2,
        })
      )
      .then((res) => {
        if (res.data === null) {
          NotificationManager.warning("Data not available right now");
        } else {
          if (res.data.data === null) {
            setFilterCheck(true);
            setSearchMode(true);
          }
          page =  
          setTotal(res.data.meta.total);
          setPage(res.data.meta.current_page);
          setRowsPerPage(res.data.meta.per_page);
          setFrom(res.data.meta.from);
          setTo(res.data.meta.to);
          setFilterDate({ ...filterDate, start_date: null, end_date: null });
          setTableState({ ...tableState, callerData: res.data.data });
          setFilterQuery({  search: "", callType: "", franchiseName: "" });
        }
      });
  };

  const handleFromDate = (info) => {
    setFilterDate({ ...filterDate, start_date: info });
  };

  const handleToDate = (info) => {
    setFilterDate({ ...filterDate, end_date: info });
  };

  const handleChangeCallType = (e) => {
    // setPage(0);
    page = 1;
    setFilterQuery({
      ...filterQuery,
      callType: e.target.value,
    });
  };

  const handleChangeFranchisee = (e) =>{
    // setPage(0);
    page = 1;
    setFilterQuery({
      ...filterQuery,
      franchiseName: e.target.value,
    });
  }

  const filterCallList = () => {
    //----This function fetches the filtered data and maps it into the "tableState" which gets mapped into the table---//
    setFilterQuery({ ...filterQuery, search: "" });
    setFilterCount(null);

    if (filterDate.start_date === null && filterDate.end_date === null) {
      setDateCheck(true);
    } else {
      setDateCheck(false);
      setTableState({ ...tableState, callerData: [] });
      httpClient
        .post(
          "getCallLog2.php",
          qs.stringify({
            action: "call_log",
            source: filterDate,
          })
        )
        .then((res) => {
          if (res.data.data === null) {
            setFilterCheck(true);
            setSearchMode(true);
          } else {
            setFilterCheck(false);
            setSearchMode(true);
            setTableState({ ...tableState, callerData: res.data.data });
          }
        });
    }
  };

  // const handleChangePage = (event, page) => {
  //   setTableState({ ...tableState, page });
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setTableState({ ...tableState, rowsPerPage: event.target.value });
  // };

  // console.log("data", tableState.callerData);

  // console.log('filter check', filterCheck);

  // console.log('page is', page);
  // console.log('rows per page', rowsPerPage);


  return (
    <ResponsiveContainer>
      <div>
        <h2 className="title pb-3 pt-3 pl-4 font-weight-bold">List of calls</h2>
        <div
          // style={{
          //   display: "flex",
          //   justifyContent: "space-around",
          //   flexWrap: "wrap",
          // }}
          // className="row"
        >
          <div
            className="page-heading d-sm-flex flex-wrap align-items-sm-center"
            style={{
              // marginTop: "2%",
              width: "96%",
            }}
          >
            <div
              className="from-to-date-wrapper row"
              style={{
                width:"100%"
              }}
            >
              <div className="col-md-6 start-date mb-3">
                <h3 style={{ color: "#8f8f8f", fontWeight: "bold" }}>From</h3>
                <DatePicker
                  value={filterDate.start_date}
                  onChange={(e) => handleFromDate(e)}
                  animateYearScrolling={false}
                  leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                  rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                  errorText={dateCheck}
                  variant="outlined"
                  style={{ width:"100%" }}
                />
                <div>
                  {dateCheck && (
                    <div
                      style={{
                        fontSize: "12px",
                        color: "rgb(244, 67, 54)",
                      }}
                    >
                      Please enter the Start Date !
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-6 end-date mb-3" 
              // style={{ marginLeft: "50px" }}
              >
                <h3 style={{ color: "#8f8f8f", fontWeight: "bold" }}>To</h3>
                <DatePicker
                  value={filterDate.end_date}
                  onChange={(e) => handleToDate(e)}
                  animateYearScrolling={false}
                  leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                  rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                  errorText={dateCheck}
                  variant="outlined"
                  style={{ width:"100%" }}
                />
                <div>
                  {dateCheck && (
                    <div
                      style={{
                        fontSize: "12px",
                        color: "rgb(244, 67, 54)",
                      }}
                    >
                      Please enter the end Date !
                    </div>
                  )}
                </div>
              </div>

              {/* <div className="search-date-icon">
                <IconButton
                  style={{ marginTop: "20px" }}
                  aria-label="search"
                  onClick={filterCallList}
                >
                  <SearchIcon />
                </IconButton>
              </div> */}

              <div
                className="col-md-4"
                // style={{
                //   width: "350px",
                //   marginLeft: "10px",
                //   marginRight: "10px",
                // }}
              >
                <InputLabel
                  // id="demo-simple-select-label"
                  className="font-weight-bold mb-2"
                >
                  Select Call Type
                </InputLabel>
                <FormControl fullWidth style={{ width: "100%" }}>
                  <Select
                    // labelId="demo-simple-select-label"
                    // id="demo-simple-select"
                    value={filterQuery.callType}
                    variant="outlined"
                    // label="Select Call Type"
                    onChange={handleChangeCallType}
                  >
                    <MenuItem value={""}>
                      <em>select call type</em>
                    </MenuItem>

                    <MenuItem value={"Service_Required"}>
                      Service Required
                    </MenuItem>
                    <MenuItem value={"Cancel_Booking"}>Cancel Booking</MenuItem>
                    <MenuItem value={"Message_for_operator"}>
                      Message for operator
                    </MenuItem>
                    {/* <MenuItem value={"Change Suburb"}>Change Suburb</MenuItem> */}
                    <MenuItem value={"Franchise_Enquiry"}>
                      Franchise Enquiry
                    </MenuItem>
                    <MenuItem value={"Advertising_Enquiry"}>
                      Advertising Enquiry
                    </MenuItem>
                    <MenuItem value={"Complaint"}>Complaint</MenuItem>
                    <MenuItem value={"Declined_to_Proceed"}>
                      Declined to Proceed
                    </MenuItem>
                    <MenuItem value={"end_call"}>
                      End Call
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div
                className=" col-md-4"
                // style={{
                //   width: "350px",
                //   marginLeft: "10px",
                //   marginRight: "10px",
                // }}
              >
                <InputLabel
                  // id="demo-simple-select-label"
                  className="font-weight-bold mb-2"
                >
                  Select Franchisee
                </InputLabel>
                <FormControl fullWidth style={{ width: "100%" }}>
                  <Select
                    // labelId="demo-simple-select-label"
                    // id="demo-simple-select"
                    value={filterQuery.franchiseeName}
                    variant="outlined"
                    // label="Select Call Type"
                    onChange={handleChangeFranchisee}
                  >
                    <MenuItem value={""}>
                      <em>select franchisee</em>
                    </MenuItem>
                    {franchiseList && franchiseList.map((fra) => (
                      <MenuItem value={fra.franchiseeID} key={fra.franchiseeID}>
                      {fra.franchiseeName}
                    </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div
                className="col-md-4"
                // style={{ marginLeft: "10px" }}
              >
                {/* <Paper className={classes.root}> */}
                  {/* <span style={{ padding: 10 }}>
                    <MenuIcon />
                  </span> */}
                  <InputLabel
                  className="font-weight-bold mb-2"
                >
                  Search Call Log
                </InputLabel>
                  <TextField
                    // className={classes.input}
                    style={{ width:"100%" }}
                    variant="outlined"
                    placeholder="Search Call Log"
                    // inputProps={{ "aria-label": "Search Call Log" }}
                    name="search"
                    value={filterQuery.search}
                    onChange={(e) => handleSearchQuery(e)}
                  />
                  {/* <InputBase
                    className={classes.input}
                    placeholder="Search Call Log"
                    inputProps={{ "aria-label": "Search Call Log" }}
                    name="search"
                    value={filterQuery.search}
                    onChange={(e) => handleSearchQuery(e)}
                  /> */}
                  {/* <IconButton
                    className={classes.iconButton}
                    aria-label="search"
                    onClick={filterRecentCalls}
                  >
                    <SearchIcon />
                  </IconButton> */}
                  {/* <Divider className={classes.divider} orientation="vertical" /> */}
                {/* </Paper> */}
                {/* <div style={{ marginTop: "10px" }}>
                  {queryCheck && (
                    <div
                      style={{ fontSize: "12px", color: "rgb(244, 67, 54)" }}
                    >
                      Search field cannot be empty !
                    </div>
                  )}
                </div> */}
              </div>
              <div className="col-md-12 mt-3">
                <div style={{ float:"right" }}>
                  <Button color="primary" variant="contained" onClick={filterRecentCalls}
                  disabled={(filterQuery.callType === "" && filterQuery.franchiseName === "" && filterQuery.search === "" && filterDate.start_date === null && filterDate.end_date === null) ? true : false}
                  >
                    Search
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {searchMode ? (
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <h2
              style={{
                marginLeft: "3%",
                fontWeight: "600",
              }}
            >
              Latest Search Results 
              {/* {filterCount === null ? "---" : filterCount}) */}
            </h2>

            <Button
              variant="contained"
              color="primary"
              className="jr-btn text-white"
              style={{
                marginLeft: "5%",
              }}
              onClick={getTableData}
            >
              See all Calls
            </Button>
          </div>
        ) : null}

        <div className="flex-auto" style={{ marginTop: "2%" }}>
          <div
            className="table-responsive-material"
            style={{ marginLeft: "2%", marginRight: "2%" }}
          >
            <Table>
              <EnhancedTableHead
                order={tableState.order}
                orderBy={tableState.orderBy}
                rowCount={tableState.callerData && tableState.callerData.length}
              />

              <TableBody>
                {/*------Mapping the table Data involves slicing the array of data-------*/}
                {tableState.callerData && tableState.callerData.length > 0 ? (
                  tableState.callerData
                    // .slice(
                    //   tableState.page * tableState.rowsPerPage,
                    //   tableState.page * tableState.rowsPerPage +
                    //     tableState.rowsPerPage
                    // )
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((each, index) => {
                      return (
                        <TableRow tabIndex={-1} key={index}>
                          {/* <TableCell padding="default">{counter++}</TableCell> */}
                          <TableCell padding="default">
                            {each.addedDate == "0000-00-00" ||
                            each.addedDate == ""
                              ? "-"
                              : moment(
                                  each.addedDate + " " + each.addedTime
                                ).format("Do MMM YYYY, h:mm a")}
                          </TableCell>
                          <TableCell padding="default">{each.franchiseeName}</TableCell>
                          <TableCell padding="default">
                            {each.firstName} {each.lastName}
                          </TableCell>
                          <TableCell padding="default">{each.phone}</TableCell>
                          <TableCell padding="default">
                            {each.address}
                          </TableCell>
                          <TableCell padding="default">{each.suburb}</TableCell>
                          <TableCell
                            padding="default"
                            style={{ color: "#b5b5b5" }}
                          >
                            {each.messageType == "" ? "-" : each.messageType}
                            {/* {each.addedBy === "" ? (
                              "---"
                            ) : (
                              <span>{each.addedBy}</span>
                            )} */}
                          </TableCell>
                          <TableCell padding="default">
                            {
                              //----Passing Data to the "FormDialog" component that shows the details of each call----//
                              <>
                                <FormDialog
                                  rowID={each.logID}
                                  rowDate={each.addedDate}
                                  rowTime={each.addedTime}
                                  rowPhone={each.phone}
                                  rowPhone2={each.phone2}
                                  rowSuburb={each.suburb}
                                  rowFirstName={each.firstName}
                                  rowLastName={each.lastName}
                                  rowAddress={each.address}
                                  rowPetName={each.petName}
                                  rowPetBreed={each.petBreed}
                                  rowPetGender={each.gender}
                                  interestedService={each.interestedService}
                                  messageType={each.messageType}
                                  rowEmail={each.email}
                                  notes={each.notes}
                                  commentText={each.commentText || each.message}
                                  reschedule={each.reschedule}
                                  addedBy={each.addedBy}
                                  referredby={each.referredby}
                                  franchiseeName={each.franchiseeName}

                                />
                              </>
                            }
                          </TableCell>
                        </TableRow>
                      );
                    })
                ) : (
                  <>
                    {filterCheck ? (
                      <div>
                        <br />
                        <h3 style={{ marginLeft: "2%" }}>
                          No Records for that Time period...
                        </h3>
                      </div>
                    ) : (
                        <LinearBuffer />
                    )}
                  </>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    // style={{ width:"max-content" }}
                    // count={
                    //   tableState.callerData && tableState.callerData.length
                    // }
                    // rowsPerPage={tableState.rowsPerPage}
                    // page={tableState.page}
                    // onChangePage={handleChangePage}
                    // onChangeRowsPerPage={handleChangeRowsPerPage}

                    rowsPerPageOptions={[20, 50, 100]}
                    // component="div"
                    count={total}
                    // count={tableState.callerData && tableState.callerData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelDisplayedRows={() => `${from} - ${to} to ${total}`}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        </div>
        <NotificationContainer />
      </div>
    </ResponsiveContainer>
  );
};

export default EnhancedTable;
