import React from "react";
import { Link } from "react-router-dom";
// import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
// import { connect } from "react-redux";
// import TextField from "@material-ui/core/TextField";
// import IconButton from "@material-ui/core/IconButton";
// import Button from "@material-ui/core/Button";
// import IntlMessages from "util/IntlMessages";
// import Router from "Router";
import {
  NotificationContainer
  // NotificationManager
} from "react-notifications";
import CircularProgress from "@material-ui/core/CircularProgress";

const loginData = JSON.parse(localStorage.getItem("login"));

class Logout extends React.Component {
  componentDidMount() {
    localStorage.clear();

    setTimeout(function() {
      window.location = loginData && loginData.userType === "admin" ? "/admin" :  "/signin";
    }, 2000);
  }

  render() {
    return (
      <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">
          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="Mate">
              <img
                src={require("assets/images/logo.png")}
                alt="Mate"
                title="Mate"
              />
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1>Logging Out...</h1>
              <CircularProgress
                size={70}
                color="secondary"
                style={{ marginRight: "2%" }}
              />
            </div>

            <div className="app-login-form"></div>
          </div>
        </div>

        <NotificationContainer />
      </div>
    );
  }
}

export default Logout;
