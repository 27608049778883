// import React, { Component } from "react";
// import ReactDOM from "react-dom";
import { httpClient } from "appUtility/Api";
import {
  // NotificationContainer,
  NotificationManager
} from "react-notifications";
//import Snackbar from "react-native-snackbar";
//import Snackbar from '@material-ui/core/Snackbar';
// import App from "../containers/App";
// import asyncComponent from "util/asyncComponent";
//import authUserState from 'components/signIn'
//import {Actions} from 'react-native-router-flux'
import { SIGNOUT_USER, SIGNOUT_USER_SUCCESS } from "constants/ActionTypes";

const qs = require("qs");

// var param;
//const param = 3;
//this.setState = {param:3 }
//return param;
//

export const GetUserVerify = uname => {
  if (uname.email !== "" && uname.password !== "") {
    NotificationManager.info("Verifying User Detail.");
  }

  httpClient
    .post(
      "login.php",
      qs.stringify({
        action: "verify_login",
        source: uname
      })
    )
    .then(({ data }) => {
      if (data.verified !== "success") {
        NotificationManager.warning(data);
      } else {
        //Now clear all local storage elements because new values are going to be downloaded soon.
        localStorage.clear();
        localStorage.setItem("login", JSON.stringify(data));
        NotificationManager.success("Logged in Successfully.");
        setTimeout(function() {
          document.location.href = "/app/dashboard/CRM";
        }, 1000);
      }
    });
};

//-----Sign Out Actions-----
export const userSignOut = () => {
  return {
    type: SIGNOUT_USER
  };
};

export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS
  };
};
//-----------------------------
