import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
// import Select from "react-select";
import { DatePicker } from "material-ui-pickers";
import TimePicker from "./TimePickers";
import moment from "moment";
import { ResponsiveContainer } from "recharts";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import { httpClient } from "appUtility/Api";
import moment_timezone from "moment-timezone";
import { history } from "../../../store/index";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Grid,
  FormControl,
  MenuItem,
  Select,
  FormHelperText,
  Card,
  IconButton,
} from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";

const qs = require("qs");
const loginData = JSON.parse(window.localStorage.getItem("login"));

//Here "tzone" is being converted to (Australia/Melbourne)
if (loginData && loginData.tzone !== "") {
  moment_timezone.tz.setDefault(loginData.tzone);
} else {
  moment_timezone.tz.setDefault("Australia/Melbourne");
}

const lodgeCall = () => {
  //here "GetServiceQuery" is a state that stores the necessary values picked from the local storage which will be used in the API calls
  const [getServiceQuery] = useState({
    userID: loginData && loginData.uid,
    userCode: loginData && loginData.aCode,
    appID: 1,
  });

  const genderList = [
    { value: "Unknown", label: "Unknown" },
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];

  const timeList = [
    { value: "Morning", label: "Morning" },
    { value: "Late Morning", label: "Late Morning" },
    { value: "Afternoon", label: "Afternoon" },
    { value: "Late Afternoon", label: "Late Afternoon" },
  ];

  // const [serviceList, setServiceList] = useState([]);

  const [serviceList] = useState([
    { value: "Wash", label: "Wash" },
    { value: "Wash & Groom", label: "Wash & Groom" },
  ]);

  const [suburbList, setSuburbList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [step, setStep] = useState(1);
  const [franchiseList, setFranchiseList] = useState([]);
  const [count, setCount] = useState("");
  const [message, setMessage] = useState(true);

  const [postCodeError, setPostCodeError] = useState("");

  const [callDetail, setCallDetail] = useState({
    userID: loginData && loginData.uid,
    userCode: loginData && loginData.aCode,
    appID: "1",
    firstName: "",
    lastName: "",
    address: "",
    postCode: "",
    state: null,
    suburb: null,
    franchiseName: null,
    phone: "",
    email: "",
    petName: "",
    petBreed: "",
    gender: null,
    interestedService: null,
    addedDate: moment(),
    addedTime: moment(),
    preferredDate: null,
    preferredTime: null,
    notes: "",
    referredby: "",
    action: "save_call",
  });

  //----The states defined below are booleans that are used for validating the form---//
  const [firstNameCheck, setFirstNameCheck] = useState(false);
  const [lastNameCheck, setLastNameCheck] = useState(false);
  const [addressCheck, setAddressCheck] = useState(false);
  const [phoneCheck, setPhoneCheck] = useState(false);
  const [stateCheck, setStateCheck] = useState(false);
  const [suburbCheck, setSuburbCheck] = useState(false);
  const [serviceCheck, setServiceCheck] = useState(false);
  const [timeCheck, setTimeCheck] = useState(false);
  const [dateCheck, setDateCheck] = useState(false);
  const [referCheck, setReferCheck] = useState(false);

  //---------------------------------------------------------------//

  const [circular, setCircular] = useState(false);

  useEffect(() => {
    getStateList();
    // callDetail.state && callDetail.state.ID && getSuburbList();
    let franchiseLists = [];
    suburbList.map((sub) => {
      if (callDetail.suburb === sub.suburbName) {
        setCount(sub.count);
        if (sub.memberNames && sub.memberNames !== null) {
          return franchiseLists.push(sub.memberNames);
        }
      }
    });
    setFranchiseList(franchiseLists);
    if(franchiseLists.length > 0 && franchiseLists[0] != undefined){
      setCallDetail({
        ...callDetail,
        franchiseName: franchiseLists[0] != undefined && franchiseLists[0].companyID
      })
    }

  }, [callDetail.suburb]);

  const handleFranchise = (e) => {
    const { name, value } = e.target;
    setCallDetail({
      ...callDetail,
      franchiseName: value,
    });
  };

  const handlePostCode = (e) => {
    const { name, value } = e.target;
    setCallDetail({
      ...callDetail,
      postCode: value,
    });
  };

  const handlePostCodePress = (e) => {
    if (e.key === "Enter") {
      var postQuery = {
        userID: loginData && loginData.uid,
        userCode: loginData && loginData.aCode,
        appID: 1,
        postCode: callDetail.postCode,
      };
      httpClient
        .post(
          "getSuburbs_Franchise.php",
          qs.stringify({
            action: "get_suburbs",
            source: postQuery,
          })
        )
        .then((res) => {
          if (res.data.error) {
            console.log("failed to set sububrb");
            setPostCodeError("Invalid Post Code. Please Re-Enter your Post Code");
            setSuburbList([]);
            setCallDetail({
              ...callDetail,
              suburb: null,
              state: null,
            });
          } else {
            setPostCodeError("");
            let datas = res.data;
            let suburbLists = [];

            datas.map((data) => {
              return suburbLists.push(data);
            });

            setSuburbList(suburbLists);
            setMessage(true);
            setCallDetail({
              ...callDetail,
              suburb: suburbLists[0].suburbName,
              state: suburbLists[0].stateID,
            });
          }
        });
    }
  };

  // console.log("suburb list>", suburbList);
  // console.log("franchise list>", franchiseList);
  // console.log('count >>>', count);

  const getStateList = () => {
    var stateQuery = {
      userID: loginData && loginData.uid,
      userCode: loginData && loginData.aCode,
      appID: 1,
    };
    httpClient
      .post(
        "getStates.php",
        qs.stringify({
          action: "get_states",
          source: stateQuery,
        })
      )
      .then((res) => {
        if (res.data === null) {
          NotificationManager.warning("State not available right now");
        } else {
          const stateData = res.data.map((d) => ({
            value: d.stateName,
            label: d.stateName,
            ID: d.stateID,
          }));
          setStateList(stateData);
        }
      });
  };

  const getSuburbList = () => {
    /*---------A function that fetches the list of options for "Suburb" ---------*/

    var suburbQuery = {
      userID: loginData && loginData.uid,
      userCode: loginData && loginData.aCode,
      appID: 1,
      stateID: callDetail.state && callDetail.state.ID,
    };

    httpClient
      .post(
        "getSuburbs.php",
        qs.stringify({
          action: "get_suburbs",
          source: suburbQuery,
        })
      )
      .then((res) => {
        if (res.data === null) {
          NotificationManager.warning("Suburb not available right now");
        } else {
          const suburbData = res.data.map((d) => ({
            value: d.suburbName,
            label: d.suburbName,
          }));
          setSuburbList(suburbData);
        }
      });
  };

  const handleChangeSuburb = (e) => {
    const { name, value } = e.target;
    setCallDetail({
       ...callDetail,
       [name]: value,
     });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCallDetail({ ...callDetail, [name]: value });
  };

  const handleDateChange = (info) => {
    setCallDetail({ ...callDetail, preferredDate: info });
  };

  const handleGender = (info) => {
    setCallDetail({ ...callDetail, gender: info });
  };

  const handleTime = (info) => {
    setCallDetail({ ...callDetail, preferredTime: info });
  };

  const handleSuburb = (value) => {
    setCallDetail({ ...callDetail, suburb: value });
  };

  const handleState = (info) => {
    setCallDetail({ ...callDetail, state: info });
  };

  const handleServices = (info) => {
    setCallDetail({ ...callDetail, interestedService: info });
  };

  const handleSaveForm = (e) => {
    //------Function that validates the form of Lodge call and submits it to the API using HttpClient----//
    e.preventDefault();

    if (callDetail.firstName === "") {
      setFirstNameCheck(true);
    }
    if (callDetail.lastName === "") {
      setLastNameCheck(true);
    }

    if (callDetail.address === "") {
      setAddressCheck(true);
    }
    if (callDetail.state === null) {
      setStateCheck(true);
    }
    if (callDetail.suburb === null) {
      setSuburbCheck(true);
    }

    if (callDetail.phone === "") {
      setPhoneCheck(true);
    }

    if (callDetail.interestedService === null) {
      setServiceCheck(true);
    }

    if (callDetail.preferredTime === null) {
      setTimeCheck(true);
    }

    if (callDetail.preferredDate === null) {
      setDateCheck(true);
    }

    if (callDetail.referredby === "") {
      setReferCheck(true);
    }

    if (
      callDetail.firstName !== "" &&
      callDetail.lastName !== "" &&
      callDetail.address !== "" &&
      callDetail.postCode !== "" &&
      callDetail.state !== null &&
      callDetail.suburb !== null &&
      callDetail.phone !== "" &&
      callDetail.interestedService !== null &&
      callDetail.preferredTime !== null &&
      callDetail.preferredDate !== null &&
      callDetail.referredby !== ""
    ) {
      setCircular(true);
      httpClient
        .post(
          "saveCallLog.php",
          qs.stringify({
            action: "save_call",
            source: callDetail,
          })
        )
        .then((res) => {
          if (res.data.success !== "true") {
            NotificationManager.warning(res.data.msg);
            setCircular(false);
          } else {
            NotificationManager.success(res.data.msg);
            history.push("/app/listCall");
            setTimeout(() => {
              setCallDetail({
                ...callDetail,
                firstName: "",
                lastName: "",
                address: "",
                postCode: "",
                state: null,
                suburb: null,
                franchiseName: null,
                phone: "",
                email: "",
                gender: null,
                petName: "",
                petBreed: "",
                interestedService: null,
                preferredDate: null,
                preferredTime: null,
                notes: "",
                referredby: "",
              });
              setCircular(false);
              setFirstNameCheck(false);
              setLastNameCheck(false);
              setAddressCheck(false);
              setPhoneCheck(false);
              setStateCheck(false);
              setSuburbCheck(false);
              setServiceCheck(false);
              setTimeCheck(false);
              setDateCheck(false);
              setReferCheck(false);
            }, 1000);
          }
        });
    }
  };

  const handleSaveFormTwo = (e) => {
    e.preventDefault();
    if (callDetail.firstName === "") {
      setFirstNameCheck(true);
    }

    if (callDetail.phone === "") {
      setPhoneCheck(true);
    }

    if (
      callDetail.firstName !== "" &&
      callDetail.phone !== ""
    ) {
      setCircular(true);
      let callData = {
        userID: callDetail.userID,
        userCode: callDetail.userCode,
        appID: callDetail.appID,
        addedDate: callDetail.addedDate,
        addedTime: callDetail.addedTime,
        firstName: callDetail.firstName,
        lastName: callDetail.lastName,
        address: callDetail.address,
        postCode: callDetail.postCode,
        state: callDetail.state,
        suburb: callDetail.suburb,
        phone: callDetail.phone,
        interestedService: callDetail.interestedService,
        referredby: callDetail.referredby,
      };
      httpClient
        .post(
          "saveCallLog_Mini.php",
          qs.stringify({
            action: "save_call_mini",
            source: callData,
          })
        )
        .then((res) => {
          if (res.data.success !== "true") {
            NotificationManager.warning(res.data.msg);
            setCircular(false);
          } else {
            NotificationManager.success(res.data.msg);
            history.push("/app/listCall");

            setTimeout(() => {
              setCallDetail({
                ...callDetail,
                firstName: "",
                lastName: "",
                address: "",
                postCode: "",
                state: null,
                suburb: null,
                phone: "",
                email: "",
                gender: null,
                petName: "",
                petBreed: "",
                interestedService: null,
                preferredDate: null,
                preferredTime: null,
                notes: "",
                referredby: "",
              });
              setCircular(false);
              setFirstNameCheck(false);
              setPhoneCheck(false);
            }, 1000);
          }
        });
    }
  };

  const handleCancelForm = () => {
    history.push("/app/dashboard");
  };


  const clearAll = () => {
    setCallDetail({
      ...callDetail,
      postCode: "",
      state: null,
      suburb: null,
      franchiseName: null
    })
    setSuburbList([]);
    setFranchiseList([]);
    setMessage(true);
  }

  const handleCallClosed = () => {
    var text = window.confirm("Are you sure you want to Close the Call without entering 'Expression of Interest'? ");
    var params = {
      userID: callDetail.userID,
      userCode: callDetail.userCode,
      appID: callDetail.appID,
      postcode: callDetail.postCode,
      memberid: callDetail.franchiseName
    }
    if(text == true){
      httpClient.post('closeCall.php',qs.stringify({
        action: "close_call",
        source: params
      })).then(({data}) => {
        if(data.success == "true"){
          NotificationManager.success(data.msg)
        }else{
          NotificationManager.warning(data.msg)
        }
      })
      clearAll();
    }
  }

  const closeMessage = () => {
    setMessage(false);
  }

  console.log("Form values in local-state::::: >>", callDetail);
  var suspendedFranshise = {};
  // let suspendedText = "";
  franchiseList.map((fList) => {
    if(fList.companyID == callDetail.franchiseName){
      suspendedFranshise = fList
    }
  })

  let suspendedText = `Unfortunately ${suspendedFranshise.businessType} - ${suspendedFranshise.name} is not accepting any new customer from ${moment(suspendedFranshise.leadSuspendStartDate).format("dddd, Do MMMM YYYY")} to ${moment(suspendedFranshise.leadSuspendEndDate).format("dddd, Do MMMM YYYY")} . Sorry for the inconveinence.`


  return (
    <>
      {step === 1 && (
        <ResponsiveContainer>
          <div
            className="lodge-call-section"
            style={{ width: "60%", margin: "40px auto" }}
          >
            <p className="lodge-call-header">Select Post Code</p>
            <div style={{ padding: "30px" }}>
              <div className="row">
                <div className="col-md-3 col-12">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <InputLabel>Post Code</InputLabel>
                  </div>
                </div>
                <div className="col-md-9 col-12">
                  <TextField
                    variant="outlined"
                    name="postCode"
                    value={callDetail.postCode}
                    onChange={(e) => handlePostCode(e)}
                    onKeyPress={(e) => handlePostCodePress(e)}
                    helperText={
                      postCodeError ? postCodeError :
                      (callDetail.postCode &&
                      "Please Press Enter after you have inserted your Post Code")
                    }
                    margin="normal"
                    fullWidth
                  />
                </div>
              </div>

              {suburbList.length > 0 && (
                <div className="row">
                  <div className="col-md-3 col-12">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <InputLabel>Suburb</InputLabel>
                    </div>
                  </div>
                  <div className="col-md-9 col-12">
                    <FormControl
                      variant="outlined"
                      style={{ width: "100%", marginTop: "20px" }}
                    >
                      <Select
                        name="suburb"
                        // onChange={(e) => handleSuburb(e)}
                        value={callDetail.suburb}
                        onChange={handleChangeSuburb}
                      >
                        <MenuItem value="">
                          <em>Select</em>
                        </MenuItem>
                        {suburbList &&
                          suburbList.map((sub) => (
                            sub.count == "0" ?
                            <MenuItem style={{color: "red"}} key={sub.suburbID} value={sub.suburbName}>
                              <span style={{color: "red"}}>
                              {sub.suburbName +
                                "," +
                                " " +
                                sub.stateShortName + " " +
                                "(" +
                                sub.count +
                                ")"}
                                </span>
                            </MenuItem>
                            :
                            <MenuItem style={{color: "green"}} key={sub.suburbID} value={sub.suburbName}>
                              <span style={{color: "green"}}>
                              {sub.suburbName +
                                "," +
                                " " +
                                sub.stateShortName + " " +
                                "(" +
                                sub.count +
                                ")"}
                                </span>
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div className="col-md-3 col-12">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <InputLabel>Franchise</InputLabel>
                    </div>
                  </div>

                  <div className="col-md-9 col-12">
                    <FormControl
                      variant="outlined"
                      style={{ width: "100%", marginTop: "20px"}}
                    >
                      <Select
                        name="franchiseName"
                        onChange={handleFranchise}
                        value={callDetail.franchiseName}
                        // onChange={handleChange}
                        style={{backgroundColor: suspendedFranshise.leadSuspend == "1" ? "#ffd4d4" : suspendedFranshise.leadSuspend == "0" ? "#c6fac6" : ""  }}
                      >
                        <MenuItem value="">
                          <em>Select</em>
                        </MenuItem>
                        {franchiseList.length > 0 ? (
                          franchiseList.map((fran) => (
                            fran.leadSuspend === 0 ?
                            <MenuItem
                              value={fran.companyID}
                              key={fran.companyID}
                              style={{background: "#c6fac6"}}
                            >
                             {fran.businessType + " " + "-" + " " + fran.name}
                            </MenuItem>
                            :
                            <MenuItem
                              value={fran.companyID}
                              key={fran.companyID}
                              style={{background: "#ffd4d4"}}
                            >
                              {fran.businessType + " " + "-" + " " + fran.name}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value="">No Franchise Found</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              )}

              {suspendedFranshise.leadSuspend == "1" &&
              <div className="row">
                <div className="col-md-3 col-12">
                  
                </div>

                <div className="col-md-9 col-12">
                  <Card className="mt-4 p-4" style={{ backgroundColor: "#ffffb7" }}>
                    <p>Hello {suspendedFranshise.name}, <br/> {suspendedText}</p>
                  </Card>
                </div>
              </div>
              }

              <div className="row">
                <div className="col-12 col-md-12">
                {callDetail.suburb &&
                <Button variant="contained" color="secondary" onClick={clearAll} className="mt-4 jr-btn text-white" style={{position: "absolute"}}>
                  Clear All
                </Button>
                }
                {suspendedFranshise.leadSuspend == "1" ?
                <div className="pt-4 text-right">
                <Button variant="contained" color="secondary" className="jr-btn text-white" onClick={handleCallClosed}>
                  Call Closed
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className="jr-btn text-white"
                  onClick={() => setStep(2)}
                  disabled={
                    callDetail.suburb
                      ? count == 0
                        ? false
                        : callDetail.franchiseName
                        ? false
                        : true
                      : true
                  }
                >
                  Next
                </Button>
              </div>
              :
                  <div className="pt-4 text-right">
                    <Button
                      variant="contained"
                      color="primary"
                      className="jr-btn text-white text-right"
                      onClick={() => setStep(2)}
                      disabled={
                        callDetail.suburb
                          ? count == 0
                            ? false
                            : callDetail.franchiseName
                            ? false
                            : true
                          : true
                      }
                    >
                      Next
                    </Button>
                  </div>  
               
                
                }
                  
                  
                </div>
              </div>
            </div>
          </div>
        </ResponsiveContainer>
      )}
      {step === 2 &&
        (callDetail.franchiseName && suspendedFranshise.leadSuspend == "0" ? (
          <ResponsiveContainer>
            <div className="lodge-call-section">
              <p className="lodge-call-header">Lodge A Call</p>
              
              <form style={{ width: "100%" }} onSubmit={handleSaveForm}>
                <div
                  style={{ display: "flex", flexWrap: "wrap", marginTop: "1%" }}
                >
                  <div className="col-md-6 col-12">
                    <h3 style={{ color: "#8f8f8f", fontWeight: "bold" }}>
                      Current Date
                    </h3>
                    <DatePicker
                      disabled={true}
                      fullWidth
                      value={callDetail.current_date}
                    />
                  </div>

                  <div className="col-md-6 col-12">
                    <h3 style={{ color: "#8f8f8f", fontWeight: "bold" }}>
                      Current Time
                    </h3>

                    <TimePicker margin="normal" />
                  </div>
                </div>

                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div className="col-md-6 col-12">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      label="First Name *"
                      name="firstName"
                      value={callDetail.firstName}
                      onChange={(e) => handleChange(e)}
                      error={firstNameCheck}
                      helperText={
                        firstNameCheck === true
                          ? "Enter your First Name !"
                          : null
                      }
                      margin="normal"
                      fullWidth
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      label="Last Name *"
                      name="lastName"
                      value={callDetail.lastName}
                      onChange={(e) => handleChange(e)}
                      error={lastNameCheck}
                      helperText={
                        lastNameCheck === true ? "Enter your Last Name !" : null
                      }
                      margin="normal"
                      fullWidth
                    />
                  </div>
                </div>

                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div className="col-md-6 col-12">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      label="Address *"
                      name="address"
                      value={callDetail.address}
                      onChange={(e) => handleChange(e)}
                      error={addressCheck}
                      helperText={
                        addressCheck === true
                          ? " Please provide an address!"
                          : null
                      }
                      margin="normal"
                      fullWidth
                    />
                  </div>

                  <div className="col-md-6 col-12">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      label="Email"
                      name="email"
                      value={callDetail.email}
                      onChange={(e) => handleChange(e)}
                      margin="normal"
                      fullWidth
                    />
                  </div>
                </div>

                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div className="col-md-3 col-12">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      label="PostCode *"
                      name="postCode"
                      value={callDetail.postCode}
                      onChange={(e) => handleChange(e)}
                      margin="normal"
                      fullWidth
                    />
                  </div>
                  <div className="col-md-3 col-12">
                    <div style={{ margin: "10px 0" }}>
                      {/* <InputLabel htmlFor="gender-native">State</InputLabel> */}
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%", marginTop: "6px" }}
                        error={stateCheck}
                      >
                        <InputLabel>State *</InputLabel>
                        <Select
                          name="state"
                          label="State *"
                          value={callDetail.state}
                          onChange={handleChange}
                        >
                          <MenuItem value="">
                            <em>Select</em>
                          </MenuItem>
                          {stateList &&
                            stateList.map((state) => (
                              <MenuItem key={state.ID} value={state.ID}>
                                {state.value}
                              </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>
                          {stateCheck === true
                            ? " Please select your State !"
                            : null}
                        </FormHelperText>
                      </FormControl>

                      {/* <Select
                        name="state"
                        options={stateList}
                        onChange={(e) => handleState(e)}
                        value={callDetail && callDetail.state}
                      /> */}
                      {/* <div
                        style={{ fontSize: "12px", color: "rgb(244, 67, 54)" }}
                      >
                        {stateCheck && <div>Please choose your State !</div>}
                      </div> */}
                    </div>
                  </div>

                  <div className="col-md-6 col-12">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      label="Phone *"
                      name="phone"
                      value={callDetail.phone}
                      onChange={(e) => handleChange(e)}
                      error={phoneCheck}
                      helperText={
                        phoneCheck === true
                          ? " Please provide your phone number !"
                          : null
                      }
                      margin="normal"
                      fullWidth
                    />
                  </div>
                </div>

                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div className="col-md-3 col-12">
                    <div style={{ marginTop: "10px" }}>
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%", marginTop: "6px" }}
                        error={suburbCheck}
                      >
                        <InputLabel>Suburb *</InputLabel>
                        <Select
                          name="suburb"
                          label="Suburb *"
                          value={callDetail.suburb}
                          onChange={handleChange}
                        >
                          <MenuItem value="">
                            <em>Select</em>
                          </MenuItem>
                          {suburbList &&
                            suburbList.map((sub) => (
                              <MenuItem
                                key={sub.suburbID}
                                value={sub.suburbName}
                              >
                                {sub.suburbName}
                              </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>
                          {suburbCheck === true
                            ? " Please select your Suburb !"
                            : null}
                        </FormHelperText>
                      </FormControl>
                    </div>
                  </div>

                  <div className="col-md-3 col-12">
                    <div style={{ marginTop: "10px" }}>
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%", marginTop: "6px" }}
                      >
                        <InputLabel>Pet Gender</InputLabel>
                        <Select
                          name="gender"
                          label="Pet Gender"
                          value={callDetail.gender}
                          onChange={handleChange}
                        >
                          <MenuItem value="">
                            <em>Select</em>
                          </MenuItem>
                          {genderList &&
                            genderList.map((gen) => (
                              <MenuItem key={gen.value} value={gen.value}>
                                {gen.label}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>

                      {/* <InputLabel htmlFor="gender-native">
                        Pet Gender
                      </InputLabel>
                      <Select
                        name="gender"
                        defaultValue={genderList[0]}
                        options={genderList}
                        value={callDetail && callDetail.gender}
                        onChange={(e) => handleGender(e)}
                      /> */}
                    </div>
                  </div>
                  <div className="col-md-3 col-12">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      label="Pet-Name"
                      name="petName"
                      value={callDetail.petName}
                      onChange={(e) => handleChange(e)}
                      margin="normal"
                      fullWidth
                    />
                  </div>
                  <div className="col-md-3 col-12">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      label="PetBreed"
                      name="petBreed"
                      value={callDetail.petBreed}
                      onChange={(e) => handleChange(e)}
                      margin="normal"
                      fullWidth
                    />
                  </div>
                </div>

                <div
                  style={{ display: "flex", marginTop: "1%", flexWrap: "wrap" }}
                >
                  <div className="col-md-6 col-12">
                    <div style={{ marginTop: "10px" }}>
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%", marginTop: "6px" }}
                        error={serviceCheck}
                      >
                        <InputLabel>Interested Services *</InputLabel>
                        <Select
                          required
                          name="interestedService"
                          label="Interested Services *"
                          value={callDetail.interestedService}
                          onChange={handleChange}
                        >
                          <MenuItem value="">
                            <em>Select</em>
                          </MenuItem>
                          {serviceList &&
                            serviceList.map((ser) => (
                              <MenuItem key={ser.value} value={ser.value}>
                                {ser.label}
                              </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>
                          {serviceCheck === true
                            ? " Please select your required service !"
                            : null}
                        </FormHelperText>
                      </FormControl>
                    </div>
                  </div>

                  <div className="col-md-6 col-12">
                    <TextField
                      label="Referred By *"
                      name="referredby"
                      value={callDetail.referredby}
                      onChange={(e) => handleChange(e)}
                      error={referCheck}
                      helperText={
                        referCheck === true
                          ? "Please mention how you referred to us !"
                          : null
                      }
                      margin="normal"
                      fullWidth
                    />
                  </div>
                </div>

                <div
                  style={{ display: "flex", marginTop: "2%", flexWrap: "wrap" }}
                >
                  <div className="col-md-6 col-12">
                    <h3 style={{ color: "#8f8f8f", fontWeight: "bold" }}>
                      Preferred Date *
                    </h3>
                    <DatePicker
                      fullWidth
                      value={callDetail.preferredDate}
                      onChange={(e) => handleDateChange(e)}
                      animateYearScrolling={false}
                      leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                      rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                      errorText={dateCheck}
                    />
                    <div>
                      {dateCheck && (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "rgb(244, 67, 54)",
                          }}
                        >
                          Please choose your preferred Date !
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-12">
                    <h3 style={{ color: "#8f8f8f", fontWeight: "bold" }}>
                      Preferred Time *
                    </h3>

                    <FormControl
                      variant="outlined"
                      style={{ width: "100%", marginTop: "6px" }}
                      error={timeCheck}
                    >
                      <Select
                        name="preferredTime"
                        // label="Interested Services"
                        value={callDetail.preferredTime}
                        onChange={handleChange}
                      >
                        <MenuItem value="">
                          <em>Select</em>
                        </MenuItem>
                        {timeList &&
                          timeList.map((time) => (
                            <MenuItem key={time.value} value={time.value}>
                              {time.label}
                            </MenuItem>
                          ))}
                      </Select>
                      <FormHelperText>
                          {timeCheck === true
                            ? " Please choose your time !"
                            : null}
                        </FormHelperText>
                    </FormControl>

                    {/* <Select
                      name="time"
                      options={timeList}
                      value={callDetail && callDetail.preferredTime}
                      onChange={(e) => handleTime(e)}
                    />
                    <div>
                      {timeCheck && (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "rgb(244, 67, 54)",
                          }}
                        >
                          Please choose your time !
                        </div>
                      )}
                    </div> */}
                  </div>
                </div>

                <div className="col-md-6 col-12" style={{ marginTop: "2.5%" }}>
                  <div className="form-group">
                    <label>
                      <h3 style={{ color: "#8f8f8f", fontWeight: "bold" }}>
                        Notes :
                      </h3>
                    </label>
                    <textarea
                      className="form-control form-control-lg"
                      rows="6"
                      name="notes"
                      value={callDetail.notes}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>

                <div className="lodge-call-buttons">
                  {/*---Here Circular is a boolean that is defined to show a circular-progress-element after submitting a form ---*/}
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="jr-btn bg-grey text-black"
                    onClick={() => setStep(1)}
                  >
                    Back
                  </Button>
                  {circular ? (
                    <CircularProgress
                      size={20}
                      color="secondary"
                      style={{ marginRight: "2%" }}
                    />
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className="jr-btn text-white"
                      onClick={handleSaveForm}
                    >
                      Save
                    </Button>
                  )}

                  <Button
                    variant="contained"
                    className="jr-btn bg-blue-grey text-white"
                    onClick={handleCancelForm}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
              <NotificationContainer />
            </div>
          </ResponsiveContainer>
        ) : (
          <ResponsiveContainer>
            <div className="lodge-call-section">
              <p className="lodge-call-header">Expression of Interest</p>
              {message && suspendedFranshise.leadSuspend == "1" &&
              <div>
                <Card className="m-4 p-4" style={{ backgroundColor: "#ffffb7" }}>
                  <IconButton style={{position: "absolute", right: "80px"}} onClick={closeMessage}>
                  <CloseRounded fontSize="small" />
                  </IconButton>
                <p>Hello {suspendedFranshise.name}, <br/> {suspendedText}</p>
                </Card>
              </div>
              }
              <form style={{ width: "100%" }} onSubmit={handleSaveFormTwo}>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
               
                  <div className="col-md-6 col-12">
                    <h3 style={{ color: "#8f8f8f", fontWeight: "bold" }}>
                      Current Date
                    </h3>
                    <DatePicker
                      disabled={true}
                      fullWidth
                      value={callDetail.current_date}
                    />
                  </div>

                  <div className="col-md-6 col-12">
                    <h3 style={{ color: "#8f8f8f", fontWeight: "bold" }}>
                      Current Time
                    </h3>

                    <TimePicker margin="normal" />
                  </div>
                  <div className="col-md-6 col-12">
                    <TextField
                      required
                      id="outlined-basic"
                      variant="outlined"
                      label="First Name"
                      name="firstName"
                      value={callDetail.firstName}
                      onChange={(e) => handleChange(e)}
                      error={firstNameCheck}
                      helperText={
                        firstNameCheck === true
                          ? "Enter your First Name !"
                          : null
                      }
                      margin="normal"
                      fullWidth
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <TextField
                      // required
                      id="outlined-basic"
                      variant="outlined"
                      label="Last Name"
                      name="lastName"
                      value={callDetail.lastName}
                      onChange={(e) => handleChange(e)}
                      // error={lastNameCheck}
                      // helperText={
                      //   lastNameCheck === true ? "Enter your Last Name !" : null
                      // }
                      margin="normal"
                      fullWidth
                    />
                  </div>
                </div>

                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div className="col-md-6 col-12">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      label="Address"
                      name="address"
                      value={callDetail.address}
                      onChange={(e) => handleChange(e)}
                      error={addressCheck}
                      helperText={
                        addressCheck === true
                          ? " Please provide an address!"
                          : null
                      }
                      margin="normal"
                      fullWidth
                    />
                  </div>

                  <div className="col-md-6 col-12">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      label="Email"
                      name="email"
                      value={callDetail.email}
                      onChange={(e) => handleChange(e)}
                      margin="normal"
                      fullWidth
                    />
                  </div>
                </div>

                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div className="col-md-3 col-12">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      label="PostCode"
                      name="postCode"
                      value={callDetail.postCode}
                      onChange={(e) => handleChange(e)}
                      margin="normal"
                      fullWidth
                    />
                  </div>
                  <div className="col-md-3 col-12">
                    <div style={{ margin: "10px 0" }}>
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%", marginTop: "6px" }}
                      >
                        <InputLabel>State</InputLabel>
                        <Select
                          name="state"
                          label="State"
                          value={callDetail.state}
                          onChange={handleChange}
                        >
                          <MenuItem value="">
                            <em>Select</em>
                          </MenuItem>
                          {stateList &&
                            stateList.map((state) => (
                              <MenuItem key={state.ID} value={state.ID}>
                                {state.value}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="col-md-6 col-12">
                    <TextField
                      required
                      id="outlined-basic"
                      variant="outlined"
                      label="Phone"
                      name="phone"
                      value={callDetail.phone}
                      onChange={(e) => handleChange(e)}
                      error={phoneCheck}
                      helperText={
                        phoneCheck === true
                          ? "Please provide your phone number !"
                          : null
                      }
                      margin="normal"
                      fullWidth
                    />
                  </div>
                </div>

                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div className="col-md-3 col-12">
                    <div style={{ marginTop: "10px" }}>
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%", marginTop: "6px" }}
                      >
                        <InputLabel>Suburb</InputLabel>
                        <Select
                          name="suburb"
                          label="Suburb"
                          value={callDetail.suburb}
                          onChange={handleChange}
                        >
                          <MenuItem value="">
                            <em>Select</em>
                          </MenuItem>
                          {suburbList &&
                            suburbList.map((sub) => (
                              <MenuItem
                                key={sub.suburbID}
                                value={sub.suburbName}
                              >
                                {sub.suburbName}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", marginTop: "1%", flexWrap: "wrap" }}
                >
                  <div className="col-md-6 col-12">
                    <div style={{ marginTop: "10px" }}>
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%", marginTop: "6px" }}
                      >
                        <InputLabel>Interested Services</InputLabel>
                        <Select
                          name="interestedService"
                          label="Interested Services"
                          value={callDetail.interestedService}
                          onChange={handleChange}
                        >
                          <MenuItem value="">
                            <em>Select</em>
                          </MenuItem>
                          {serviceList &&
                            serviceList.map((ser) => (
                              <MenuItem key={ser.value} value={ser.value}>
                                {ser.label}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="col-md-6 col-12">
                    <TextField
                      label="Referred By"
                      name="referredby"
                      value={callDetail.referredby}
                      onChange={(e) => handleChange(e)}
                      error={referCheck}
                      helperText={
                        referCheck === true
                          ? "Please mention how you referred to us !"
                          : null
                      }
                      margin="normal"
                      fullWidth
                    />
                  </div>
                </div>
                <div className="lodge-call-buttons">
                  {/*---Here Circular is a boolean that is defined to show a circular-progress-element after submitting a form ---*/}
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="jr-btn bg-grey text-black"
                    onClick={() => setStep(1)}
                  >
                    Back
                  </Button>
                  {circular ? (
                    <CircularProgress
                      size={20}
                      color="secondary"
                      style={{ marginRight: "2%" }}
                    />
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className="jr-btn text-white"
                      onClick={handleSaveFormTwo}
                    >
                      Save
                    </Button>
                  )}

                  <Button
                    variant="contained"
                    className="jr-btn bg-blue-grey text-white"
                    onClick={handleCancelForm}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          </ResponsiveContainer>
        ))}
        <NotificationContainer />
    </>
  );
};
export default lodgeCall;
