import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import FormDialog from "./FormDialog";
import { httpClient } from "appUtility/Api";
import {
  NotificationManager,
  NotificationContainer
} from "react-notifications";
import moment from "moment";
import { DatePicker } from "material-ui-pickers";
import Button from "@material-ui/core/Button";
import { ResponsiveContainer } from "recharts";
import LinearBuffer from "./LinearBuffer";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import MenuIcon from "@material-ui/icons/Menu";
// import Toolbar from "@material-ui/core/Toolbar";
// import Accordion from "@material-ui/core/Accordion";
// import AccordionSummary from "@material-ui/core/AccordionSummary";
// import AccordionDetails from "@material-ui/core/AccordionDetails";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import { SearchOutlined } from "@material-ui/icons";
// import Tooltip from "@material-ui/core/Tooltip";
// import TableSortLabel from "@material-ui/core/TableSortLabel";

const qs = require("qs");
const loginData = JSON.parse(window.localStorage.getItem("login"));

// var counter = 1;

const columnHead = [
  // {
  //   id: "_id",
  //   align: false,
  //   disablePadding: "default",
  //   label: "SN"
  // },
  {
    id: "date",
    align: "center",
    disablePadding: "default",
    label: "Added Date/Time"
  },

  {
    id: "customer_name",
    align: false,
    disablePadding: "default",
    label: "Customer"
  },
  { id: "phone", align: "center", disablePadding: "default", label: "Phone" },
  {
    id: "address",
    align: "center",
    disablePadding: "default",
    label: "Address"
  },
  {
    id: "suburb",
    align: "center",
    disablePadding: "default",
    label: "Suburb"
  },
  {
    id: "addedBy",
    align: "center",
    disablePadding: "default",
    label: "Added By"
  },
  {
    id: "action",
    align: "center",
    disablePadding: "default",
    label: "Actions"
  }
];

const EnhancedTableHead = () => {
  return (
    <>
      <TableHead>
        <TableRow>
          {columnHead.map(column => {
            return (
              <TableCell
                key={column.id}
                numeric={column.numeric}
                padding={column.disablePadding}
              >
                {column.label}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  }
}));

const EnhancedTable = () => {
  const classes = useStyles();

  const [tableState, setTableState] = useState({
    order: "asc",
    orderBy: "_id",
    selected: [],
    getCallQuery: {
      userID: loginData && loginData.uid,
      userCode: loginData && loginData.aCode
    },
    callerData: [],
    page: 0,
    rowsPerPage: 10
  });

  const [filterDate, setFilterDate] = useState({
    userID: loginData && loginData.uid,
    userCode: loginData && loginData.aCode,
    appID: "1",
    start_date: null,
    end_date: null
  });

  const [dateCheck, setDateCheck] = useState(false); //---This is a boolean that is used to check if user tries to search without entering the date
  // const [accordCheck, setAccordCheck] = useState(false);
  const [filterCheck, setFilterCheck] = useState(false); //---This is a boolean that will be true when the response of the API is null

  //************************search box states *************************//
  const [filterQuery, setFilterQuery] = useState({
    userID: loginData && loginData.uid,
    userCode: loginData && loginData.aCode,
    search: ""
  });

  const [searchMode, setSearchMode] = useState(false); //---this state enables to show the "latest search result section" depending upon the boolean
  // const [queryCheck, setQueryCheck] = useState(false);
  const [filterCount, setFilterCount] = useState(); //----this state stores the value of count that is received in the API---//
  //******************************************************************//

  useEffect(() => {
    getTableData();
  }, []);

  //*********************************Search box functions *************************************************//
  const handleSearchQuery = e => {
    setFilterQuery({ ...filterQuery, search: e.target.value });
  };

  const getSearchResults = () => {
    //-------This function filters the list of all calls using the query entered in the search box"---//
    setFilterCheck(false);
    setFilterCount(null);
    setFilterDate({ ...filterDate, start_date: null, end_date: null });
    httpClient
      .post(
        "searchCallLog.php",
        qs.stringify({
          action: "search_log",
          source: filterQuery
        })
      )
      .then(res => {
        if (res.data === null) {
          NotificationManager.warning("Data not available right now");
        } else {
          setTableState({ ...tableState, callerData: res.data.data });
          setFilterCount(res.data.count);
          if (res.data.data === null) {
            setFilterCheck(true);
          }
        }
      });
  };

  const filterRecentCalls = () => {
    //--------This function filters the data for "Recent Calls"---------//
    if (filterQuery.search === "") {
      // setQueryCheck(true);
      setSearchMode(false);
    } else {
      // setQueryCheck(false);
      setSearchMode(true);
      getSearchResults();
    }
  };

  //*************************************************************************************************//

  const getTableData = () => {
    setSearchMode(false);
    setFilterCheck(false);
    setFilterQuery({ ...filterQuery, search: "" });
    httpClient
      .post(
        "getCallLog.php",
        qs.stringify({
          action: "call_log",
          source: tableState.getCallQuery
        })
      )
      .then(res => {
        if (res.data === null) {
          NotificationManager.warning("Data not available right now");
        } else {
          if (res.data.data === null) {
            setFilterCheck(true);
            setSearchMode(true);
          }
          setFilterDate({ ...filterDate, start_date: null, end_date: null });
          setTableState({ ...tableState, callerData: res.data.data });
        }
      });
  };

  const handleFromDate = info => {
    setFilterDate({ ...filterDate, start_date: info });
  };

  const handleToDate = info => {
    setFilterDate({ ...filterDate, end_date: info });
  };

  const filterCallList = () => {
    //----This function fetches the filtered data and maps it into the "tableState" which gets mapped into the table---//
    setFilterQuery({ ...filterQuery, search: "" });
    setFilterCount(null);

    if (filterDate.start_date === null && filterDate.end_date === null) {
      setDateCheck(true);
    } else {
      setDateCheck(false);
      setTableState({ ...tableState, callerData: [] });
      httpClient
        .post(
          "getCallLog.php",
          qs.stringify({
            action: "call_log",
            source: filterDate
          })
        )
        .then(res => {
          if (res.data.data === null) {
            setFilterCheck(true);
            setSearchMode(true);
          } else {
            setFilterCheck(false);
            setSearchMode(true);
            setTableState({ ...tableState, callerData: res.data.data });
          }
        });
    }
  };

  const handleChangePage = (event, page) => {
    setTableState({ ...tableState, page });
  };

  const handleChangeRowsPerPage = event => {
    setTableState({ ...tableState, rowsPerPage: event.target.value });
  };

  return (
    <ResponsiveContainer>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap"
          }}
        >
          <div
            className="page-heading d-sm-flex flex-wrap align-items-sm-center"
            style={{
              marginTop: "2%",
              width: "90%"
            }}
          >
            <h2 className="title mb-3 mb-sm-0">List of calls</h2>

            <div
              className="from-to-date-wrapper"
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-around",
                marginLeft: "6%"
              }}
            >
              <div className="start-date">
                <h3 style={{ color: "#8f8f8f", fontWeight: "bold" }}>From</h3>
                <DatePicker
                  value={filterDate.start_date}
                  onChange={e => handleFromDate(e)}
                  animateYearScrolling={false}
                  leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                  rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                  errorText={dateCheck}
                />
                <div>
                  {dateCheck && (
                    <div
                      style={{
                        fontSize: "12px",
                        color: "rgb(244, 67, 54)"
                      }}
                    >
                      Please enter the Start Date !
                    </div>
                  )}
                </div>
              </div>

              <div className="end-date" style={{ marginLeft: "50px" }}>
                <h3 style={{ color: "#8f8f8f", fontWeight: "bold" }}>To</h3>
                <DatePicker
                  value={filterDate.end_date}
                  onChange={e => handleToDate(e)}
                  animateYearScrolling={false}
                  leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                  rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                  errorText={dateCheck}
                />
                <div>
                  {dateCheck && (
                    <div
                      style={{
                        fontSize: "12px",
                        color: "rgb(244, 67, 54)"
                      }}
                    >
                      Please enter the end Date !
                    </div>
                  )}
                </div>
              </div>

              <div className="search-date-icon">
                <IconButton
                  style={{ marginTop: "20px" }}
                  aria-label="search"
                  onClick={filterCallList}
                >
                  <SearchIcon />
                </IconButton>
              </div>

              <div
                className="call-search-box"
                style={{ marginTop: "1%", marginLeft: "10px" }}
              >
                <Paper className={classes.root}>
                  <span style={{ padding: 10 }}>
                    <MenuIcon />
                  </span>
                  <InputBase
                    className={classes.input}
                    placeholder="Search Call Log"
                    inputProps={{ "aria-label": "Search Call Log" }}
                    name="search"
                    value={filterQuery.search}
                    onChange={e => handleSearchQuery(e)}
                  />
                  <IconButton
                    className={classes.iconButton}
                    aria-label="search"
                    onClick={filterRecentCalls}
                  >
                    <SearchIcon />
                  </IconButton>
                  <Divider className={classes.divider} orientation="vertical" />
                </Paper>
                {/* <div style={{ marginTop: "10px" }}>
                  {queryCheck && (
                    <div
                      style={{ fontSize: "12px", color: "rgb(244, 67, 54)" }}
                    >
                      Search field cannot be empty !
                    </div>
                  )}
                </div> */}
              </div>
            </div>
          </div>
        </div>

        {searchMode ? (
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <h2
              style={{
                marginLeft: "3%",
                fontWeight: "600"
              }}
            >
              Latest Search Results (
              {filterCount === null ? "---" : filterCount})
            </h2>

            <Button
              variant="contained"
              color="primary"
              className="jr-btn text-white"
              style={{
                marginLeft: "5%"
              }}
              onClick={getTableData}
            >
              See all Calls
            </Button>
          </div>
        ) : null}

        <div className="flex-auto" style={{ marginTop: "2%" }}>
          <div
            className="table-responsive-material"
            style={{ marginLeft: "2%", marginRight: "2%" }}
          >
            <Table>
              <EnhancedTableHead
                order={tableState.order}
                orderBy={tableState.orderBy}
                rowCount={tableState.callerData && tableState.callerData.length}
              />

              <TableBody>
                {/*------Mapping the table Data involves slicing the array of data-------*/}
                {tableState.callerData && tableState.callerData.length > 0 ? (
                  tableState.callerData
                    .slice(
                      tableState.page * tableState.rowsPerPage,
                      tableState.page * tableState.rowsPerPage +
                        tableState.rowsPerPage
                    )
                    .map((each, index) => {
                      return (
                        <TableRow tabIndex={-1} key={index}>
                          {/* <TableCell padding="default">{counter++}</TableCell> */}
                          <TableCell padding="default">
                            {moment(
                              each.addedDate + " " + each.addedTime
                            ).format("Do MMM YYYY, h:mm a")}
                          </TableCell>
                          <TableCell padding="default">
                            {each.firstName} {each.lastName}
                          </TableCell>
                          <TableCell padding="default">{each.phone}</TableCell>
                          <TableCell padding="default">
                            {each.address}
                          </TableCell>
                          <TableCell padding="default">{each.suburb}</TableCell>
                          <TableCell
                            padding="default"
                            style={{ color: "#b5b5b5" }}
                          >
                            {each.addedBy === "" ? (
                              "---"
                            ) : (
                              <span>{each.addedBy}</span>
                            )}
                          </TableCell>
                          <TableCell padding="default">
                            {
                              //----Passing Data to the "FormDialog" component that shows the details of each call----//
                              <>
                                <FormDialog
                                  rowID={each.logID}
                                  rowDate={each.preferredDate}
                                  rowTime={each.preferredTime}
                                  rowPhone={each.phone}
                                  rowSuburb={each.suburb}
                                  rowFirstName={each.firstName}
                                  rowLastName={each.lastName}
                                  rowAddress={each.address}
                                  rowPetName={each.petName}
                                  rowPetBreed={each.petBreed}
                                  rowPetGender={each.gender}
                                  interestedService={each.interestedService}
                                  rowEmail={each.email}
                                  notes={each.notes}
                                  addedBy={each.addedBy}
                                  referredby={each.referredby}
                                />
                              </>
                            }
                          </TableCell>
                        </TableRow>
                      );
                    })
                ) : (
                  <>
                    {filterCheck ? (
                      <div>
                        <br />
                        <h3 style={{ marginLeft: "2%" }}>
                          No Records for that Time period...
                        </h3>
                      </div>
                    ) : (
                      <LinearBuffer />
                    )}
                  </>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    count={
                      tableState.callerData && tableState.callerData.length
                    }
                    rowsPerPage={tableState.rowsPerPage}
                    page={tableState.page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        </div>
        <NotificationContainer />
      </div>
    </ResponsiveContainer>
  );
};

export default EnhancedTable;
