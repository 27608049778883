import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ResponsiveContainer } from "recharts";
import useStyles from "./style";
import TopInfoBox from "./topInfoBox";

function ContinueFranchiseChange(props) {
  const classes = useStyles();
  const [messageSubmit, setMessageSubmit] = useState(false);
  const [stage, setStage] = useState(0);


  useEffect(() => {
    props.showResetButton && setMessageSubmit(true);
  }, [props.showResetButton]);

  const handleSubmit = () => {
    props.handleSubmitFranchiseChange();
  };

  const handleKeyEnter = (e, next) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <ResponsiveContainer>
      <div className="row">
        <div className="col-md-12">
          {/* {stage === 0 && !messageSubmit && (
              <TopInfoBox text="May I have your name please ?" />
            )}
            {stage === 1 && !messageSubmit && (
                <TopInfoBox text="And what is the best number to contact you on ? " />
              )}
  
            {stage === 2 && !messageSubmit && (
                <TopInfoBox text="May I take your address please ?" />
              )}
  
            {stage === 3 && !messageSubmit && (
                <TopInfoBox
                  text={
                    "Can you please provide me with details of the complaint?"
                  }
                />
              )} */}
            {(props.suspendedFranchisee.leadSuspend == "1") && (
              <TopInfoBox text={`Unfortunately ${props.suspendedFranchisee.name || props.suspendedFranchisee.memberName} is on leave from ${moment(props.suspendedFranchisee.leadSuspendStartDate).format("dddd, Do MMMM YYYY")} to ${moment(props.suspendedFranchisee.leadSuspendEndDate).format("dddd, Do MMMM YYYY")} and the reason is ${props.suspendedFranchisee.leadSuspendReasonID || props.suspendedFranchisee.leadSuspendReason}`} />
            )}

          <TopInfoBox
            text={"Can you please provide me with details of the message?"}
          />

          {messageSubmit && (
            <TopInfoBox
              text={
                "Thank you for calling, I will pass on your message straight away and have your call returned as soon as possible."
              }
            />
          )}
          {!props.showResetButton ? (
            <div className={classes.selectBox}>
              <form>
                <div className="row">
                  <div className="col-md-12 mt-4">
                    <InputLabel className="mb-2">Message</InputLabel>
                    <TextField
                      multiline
                      rows={4}
                      variant="outlined"
                      name="message"
                      value={props.enquiryDetails.message}
                      onChange={props.handleEnquiryChange}
                      className={classes.textField}
                      onFocus={props.handleEnquiryPress}
                      onKeyUp={handleKeyEnter}
                      //   helperText={"Press enter the Message"}
                    />
                  </div>

                  <div className="mt-3">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={props.handleBack}
                    >
                      Back
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      className="ml-3"
                    >
                      Submit
                    </Button>
                    {props.showResetButton && (
                      <Button
                        variant="contained"
                        className="ml-3"
                        color="default"
                        onClick={props.handleReset}
                      >
                        Reset
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <div className={classes.freshStart}>
              <Button
                color="secondary"
                variant="contained"
                onClick={props.handleReset}
              >
                Start from beginning
              </Button>
            </div>
          )}
        </div>
      </div>
    </ResponsiveContainer>
  );
}

export default ContinueFranchiseChange;
