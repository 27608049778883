import { makeStyles, Button } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { ResponsiveContainer } from 'recharts';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { httpClient } from "appUtility/Api";
import {
    NotificationManager,
    NotificationContainer
  } from "react-notifications";
import { history } from '../../../../store';


const qs = require("qs");
const loginData = JSON.parse(window.localStorage.getItem("login"));

const columnHead = [
  // {
  //   id: "_id",
  //   align: false,
  //   disablePadding: "default",
  //   label: "SN"
  // },
  {
    id: "fullName",
    align: "center",
    disablePadding: "default",
    label: "Full Name"
  },

  {
    id: "userName",
    align: false,
    disablePadding: "default",
    label: "UserName"
  },
  { id: "country", align: "center", disablePadding: "default", label: "Country" },
  {
    id: "state",
    align: "center",
    disablePadding: "default",
    label: "State"
  },
  {
    id: "suburb",
    align: "center",
    disablePadding: "default",
    label: "Suburb"
  },
  {
    id: "address",
    align: "center",
    disablePadding: "default",
    label: "Address"
  },
  {
    id: "phone",
    align: "center",
    disablePadding: "default",
    label: "Phone Number"
  },
  {
    id: "actions",
    align: "center",
    disablePadding: "default",
    label: "Actions"
  }
];


const EnhancedTableHead = () => {
    return (
      <>
        <TableHead>
          <TableRow>
            {columnHead.map(column => {
              return (
                <TableCell
                  key={column.id}
                  numeric={column.numeric}
                  padding={column.disablePadding}
                >
                  {column.label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
      </>
    );
  };


const MemberTable = () => {

    const [tableState, setTableState] = useState({
        order: "asc",
        orderBy: "_id",
        selected: [],
        getMemberQuery: {
          userID: loginData && loginData.uid,
          userCode: loginData && loginData.aCode
        },
        memberData: [],
        page: 0,
        rowsPerPage: 10
      });

    useEffect(() => {
        getTableData();
    },[])

    const getTableData = () => {
        const data = {
            userID: loginData && loginData.uid,
            userCode: loginData && loginData.aCode,
            appID: "1",
        }
        httpClient
        .post(
            "getMemberList.php",
            qs.stringify({
              action: "get_member_list",
              source: data
            })
          )
          .then(res => {
            if (res.data === null) {
              NotificationManager.warning("Data not available right now");
            } else {
              setTableState({ ...tableState, memberData: res.data });
            }
          });
    }

    const handleChangePage = (event, page) => {
        setTableState({ ...tableState, page });
      };
    
    const handleChangeRowsPerPage = event => {
        setTableState({ ...tableState, rowsPerPage: event.target.value });
    };

    const handleEdit = (data) => {
      history.push(`/app/addMembers/${data.memID}`);
    }

    const handleResetPassword = (data) => {
      console.log('data', data);
      history.push(`/app/resetPassword/${data.memID}`);
    }

    return (
        <ResponsiveContainer>
        <div className="flex-auto" style={{ margin: "2%" }}>
            <Table>
              <EnhancedTableHead
                order={tableState.order}
                orderBy={tableState.orderBy}
                rowCount={tableState.memberData && tableState.memberData.length}
              />
              <TableBody>
                {/* mapping the datas(members) got from the useEffect  */}
                {tableState.memberData && tableState.memberData.length > 0 ? (
                  tableState.memberData.slice(
                    tableState.page * tableState.rowsPerPage,
                      tableState.page * tableState.rowsPerPage +
                        tableState.rowsPerPage
                  ).map((each, index) => {
                    return(
                      <TableRow tabIndex={-1} key={index}>
                        <TableCell>{each.firstName + " " + each.lastName}</TableCell>
                        <TableCell>{each.username}</TableCell>
                        <TableCell>{each.memCountryID === "13" && "Australia"}</TableCell>
                        <TableCell>{each.memStateID}</TableCell>
                        <TableCell>{each.memSuburb}</TableCell>
                        <TableCell>{each.memAddress1}</TableCell>
                        <TableCell>{each.memPhone}</TableCell>
                        <TableCell style={{minWidth:"300px"}}>
                          <Button size="small" variant="outlined" style={{marginRight: "10px"}} color="secondary" onClick={() => handleResetPassword(each)}>
                            Reset Password
                          </Button>
                          <Button variant="contained" color="primary" onClick={() => handleEdit(each)} size="small">
                            Edit/View
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <div style={{textAlign: "center"}}>
                      No member found
                  </div>
                )
              }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    count={
                      tableState.memberData && tableState.memberData.length
                    }
                    rowsPerPage={tableState.rowsPerPage}
                    page={tableState.page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
        <NotificationContainer />
        </div>
        </ResponsiveContainer>
    )
}

export default MemberTable
