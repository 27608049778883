import React, { useState } from "react";
import { Button, Input, Card, Badge } from "reactstrap";
import { ResponsiveContainer } from "recharts";
import { httpClient } from "appUtility/Api";
import {
  NotificationManager,
  NotificationContainer
} from "react-notifications";
import { history } from "../../../store/index";

const qs = require("qs");
const loginData = JSON.parse(window.localStorage.getItem("login"));

const changePassword = () => {
  const [passwordData, setPassWordData] = useState({
    userID: loginData.uid,
    userCode: loginData.aCode,
    username: loginData.uname,
    appID: "1",
    currentpassword: "",
    newpassword: ""
  });

  const [repeatPassword, setRepeatPassword] = useState("");

  const [oldPasswordCheck, setOldPasswordCheck] = useState(false);
  const [newPasswordCheck, setNewPasswordCheck] = useState(false);
  const [repeatPasswordCheck, setRepeatPasswordCheck] = useState(false);

  const handleChange = e => {
    const { name, value } = e.target;
    setPassWordData({ ...passwordData, [name]: value });
  };

  const handleRepeatPassword = e => {
    setRepeatPassword(e.target.value);
  };

  const requestPasswordChange = () => {
    if (passwordData.currentpassword === "") {
      setOldPasswordCheck(true);
    }

    if (passwordData.newpassword === "") {
      setNewPasswordCheck(true);
    }

    if (repeatPassword === "") {
      setRepeatPasswordCheck(true);
    }

    if (repeatPassword !== passwordData.newpassword) {
      NotificationManager.error("New passwords don't match !");
    }

    if (
      passwordData.currentpassword !== "" &&
      passwordData.newpassword !== "" &&
      repeatPassword !== "" &&
      repeatPassword === passwordData.newpassword
    ) {
      httpClient
        .post(
          "changePassword.php",
          qs.stringify({
            action: "change_password",
            source: passwordData
          })
        )
        .then(res => {
          if (res.data.success !== "true") {
            NotificationManager.warning(res.data.msg);
          } else {
            NotificationManager.success(res.data.msg);
            setTimeout(() => {
              if (typeof window !== "undefined") {
                window.location.href = "/app/dashboard";
              }
            }, 400);
          }
        });
    }
  }; 

  const requestAdminPassworChange = () => {
    if (passwordData.currentpassword === "") {
      setOldPasswordCheck(true);
    }

    if (passwordData.newpassword === "") {
      setNewPasswordCheck(true);
    }

    if (repeatPassword === "") {
      setRepeatPasswordCheck(true);
    }

    if (repeatPassword !== passwordData.newpassword) {
      NotificationManager.error("New passwords don't match !");
    }

    if (
      passwordData.currentpassword !== "" &&
      passwordData.newpassword !== "" &&
      repeatPassword !== "" &&
      repeatPassword === passwordData.newpassword
    ) {
      httpClient
        .post(
          "changePassword_admin.php",
          qs.stringify({
            action: "change_password_admin",
            source: passwordData
          })
        )
        .then(res => {
          if (res.data.success !== "true") {
            NotificationManager.warning(res.data.msg);
          } else {
            NotificationManager.success(res.data.msg);
            setTimeout(() => {
              if (typeof window !== "undefined") {
                window.location.href = "/app/adminDashboard";
              }
            }, 400);
          }
        });
    }
  }

  const cancelChange = () => {
    // if (typeof window !== "undefined") {
    //   window.location.href = "/app/dashboard";
    // }
    loginData && loginData.userType === "admin" ? history.push("/app/adminDashboard") : history.push("/app/dashboard")
  };

  return (
    <ResponsiveContainer>
      <div className="changePassword-section">
        <Card className="password-card">
          <div style={{ margin: "2%" }}>
            <h1 className="font-weight-semibold">
              <Badge color="light" pill>
                Change Password
              </Badge>
            </h1>

            <Input
              type="text"
              name="username"
              value={passwordData.username}
              disabled
            />
            <br />

            <Input
              type="password"
              placeholder="Old password"
              name="currentpassword"
              value={passwordData.currentpassword}
              onChange={e => handleChange(e)}
              error={oldPasswordCheck}
            />
            <div>
              {oldPasswordCheck && (
                <div style={{ fontSize: "12px", color: "rgb(244, 67, 54)" }}>
                  Please enter your old password !
                </div>
              )}
            </div>
            <br />

            <Input
              type="password"
              placeholder="New password"
              name="newpassword"
              value={passwordData.newpassword}
              onChange={e => handleChange(e)}
              error={newPasswordCheck}
            />
            <div>
              {newPasswordCheck && (
                <div style={{ fontSize: "12px", color: "rgb(244, 67, 54)" }}>
                  Please enter your new password !
                </div>
              )}
            </div>
            <br />

            <Input
              type="password"
              placeholder="Repeat New password"
              name="newpassword"
              value={repeatPassword}
              onChange={e => handleRepeatPassword(e)}
              error={repeatPasswordCheck}
            />
            <div>
              {newPasswordCheck && (
                <div style={{ fontSize: "12px", color: "rgb(244, 67, 54)" }}>
                  Please Repeat your new password !
                </div>
              )}
            </div>
            <br />

            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <Button
                type="submit"
                color="primary"
                size="sm"
                onClick={(loginData && loginData.userType === "admin") ? requestAdminPassworChange : requestPasswordChange}
              >
                Change
              </Button>

              <Button
                type="submit"
                color="default"
                size="sm"
                onClick={cancelChange}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Card>
        <NotificationContainer />
      </div>
    </ResponsiveContainer>
  );
};
export default changePassword;
