import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  RootRef,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { ResponsiveContainer } from "recharts";
import useStyles from "./style";
import TopInfoBox from "./topInfoBox";

function CallScenerios(props) {
  const classes = useStyles();
  const [focus, setFocus] = useState()

  useEffect(() => {
    window.addEventListener("keydown", handleFocus);
    // window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", handleFocus);
      // window.removeEventListener("keyup", upHandler);
    };
  },[]);

  const handleFocus = (e) => {
    if((e.key === "ArrowUp" || e.key === "ArrowDown") && props.step == 2){
      document.getElementById(`${props.radios.replaceAll(" ","_")}`).focus()
    }
  }

  // const handleFocusBlur = () => {
  //   document.getElementById(`${props.radios.replaceAll(" ","_")}`).blur()
  // }

  const handleKeyPress = (e) => {
    if(e.key === "Enter"){
      props.handleNext();
    }
  }


  return (
    <ResponsiveContainer>
      <div className="row">
        <div className="col-md-12">
          <TopInfoBox
            text={
              props.radios === "Franchisee calling to change suburb"
                ? "Advisory – Please contact your Management Team for any suburb changes as we cannot action this verbally."
                : props.header
            }
          />
          <div className={classes.selectBox}>
            <FormControl style={{ width:"100%" }}>
              <FormLabel className="mb-3">
                <h2 style={{ fontWeight: "700" }}>Call Scenerios </h2>
              </FormLabel>

              <RadioGroup
                // aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="Service Required"
                name="radios"
                value={props.radios}
                onChange={props.handleRadioChange}
                onKeyUp={handleKeyPress}
              >
                <div className="row">
                  <div className="col-md-6">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <FormLabel className="">
                        <h3 style={{ fontWeight: "700" }}>
                          1. Booking Section{" "}
                        </h3>
                      </FormLabel>
                      <FormControlLabel
                        className={classes.label}
                        value="Service Required"
                        control={<Radio id="Service_Required" />}
                        label="Service Required"
                      />
                      <FormControlLabel
                        className={classes.label}
                        value="Cancel Booking"
                        control={<Radio id="Cancel_Booking" />}
                        label="Cancel Booking"
                      />
                      <FormControlLabel
                        className={classes.label}
                        value="Message For Operator"
                        control={<Radio id="Message_For_Operator" />}
                        label="Message For Operator"
                      />
                      <FormControlLabel
                        className={classes.label}
                        value="Franchisee calling to change suburb"
                        control={<Radio id="Franchisee_calling_to_change_suburb" />}
                        label="Franchisee calling to change suburb"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <FormLabel className="">
                        <h3 style={{ fontWeight: "700" }}>2. Admin Section </h3>
                      </FormLabel>

                      <FormControlLabel
                        className={classes.label}
                        value="Franchise Enquiry"
                        control={<Radio id="Franchise_Enquiry" />}
                        label="Franchise Enquiry"
                      />
                      <FormControlLabel
                        className={classes.label}
                        value="Advertising Enquiry"
                        control={<Radio id="Advertising_Enquiry" />}
                        label="Advertising Enquiry"
                      />
                      <FormControlLabel
                        className={classes.label}
                        value="Complaint"
                        control={<Radio id="Complaint" />}
                        label="Complaint"
                      />

                      <FormControlLabel
                        className={classes.label}
                        value="Declined to Proceed"
                        control={<Radio id="Declined_to_Proceed" />}
                        label="Declined to Proceed"
                      />
                    </div>
                  </div>
                </div>
              </RadioGroup>
            </FormControl>
            <div className="mt-3">
              <Button
                variant="contained"
                color="secondary"
                onClick={props.handleBack}
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={props.handleNext}
                className="ml-3"
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ResponsiveContainer>
  );
}

export default CallScenerios;
