import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { ResponsiveContainer } from "recharts";
import useStyles from "./style";
import TopInfoBox from "./topInfoBox";

function ServiceRequired(props) {
  const { radios, setPreviousForwardedScenario } = props;
  const classes = useStyles();

  useEffect(() => {
    window.addEventListener("keydown", handleFocus);
    // window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", handleFocus);
      // window.removeEventListener("keyup", upHandler);
    };
  }, []);

  const handleFocus = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      document
        .getElementById(`${props.serviceSelect.replaceAll(" ", "_")}`)
        .focus();
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      props.handleNext();
    }
  };

  useEffect(() => {
    setPreviousForwardedScenario(radios);
  }, []);

  return (
    <ResponsiveContainer>
      <div className="row">
        <div className="col-md-12">
          <TopInfoBox
            text="Do you require coat clipping as well as wash"
            subText={
              "Wash only = Dog wash + nail clip + blow dry <br/> Clip and wash = Dog wash + nail clip + coat clip + brush and blow dry"
            }
          />
          <div className={classes.selectBox}>
            <FormControl>
              <FormLabel className="mb-2">
                <h3 style={{ fontWeight: "700" }}>Select Service</h3>
              </FormLabel>
              <RadioGroup
                name="serviceSelect"
                defaultValue="Wash only"
                value={props.serviceSelect}
                onChange={props.handleServiceChange}
                onKeyUp={handleKeyPress}
              >
                <FormControlLabel
                  className={classes.label}
                  value="Wash only"
                  control={<Radio id="Wash_only" />}
                  label="Wash only"
                />
                <FormControlLabel
                  className={classes.label}
                  value="Clip and wash"
                  control={<Radio id="Clip_and_wash" />}
                  label="Clip and wash"
                />
              </RadioGroup>
            </FormControl>
            <div className="mt-3">
              <Button
                variant="contained"
                color="secondary"
                onClick={props.handleBack}
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={props.handleNext}
                className="ml-3"
                disabled={props.serviceSelect ? false : true}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ResponsiveContainer>
  );
}

export default ServiceRequired;
