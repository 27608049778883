import React from 'react'

const qs = require("qs");
const loginData = JSON.parse(window.localStorage.getItem("login"));



const DashboardAdmin = () => {
    return (
        <div>
            <h1>Admin Dashboard</h1>
        </div>
    )
}

export default DashboardAdmin
