import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  logoBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    height: "100%",
    margin: "30px",
    padding: "40px",
    cursor: "pointer",
    boxShadow:
      "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
    background: "#fff",
  },
  logoClass: {
    objectFit: "center",
    height: "200px",
    width: "200px",
  },
  boxFlex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  topInfo: {
    margin: "30px",
    padding: "20px",
    background: "#3f51b530",
    color: "#fff !important",
    borderRadius: "10px",
    boxShadow:
      "rgba(240, 46, 170, 0.4) -5px 5px, rgba(240, 46, 170, 0.3) -10px 10px, rgba(240, 46, 170, 0.2) -15px 15px, rgba(240, 46, 170, 0.1) -20px 20px, rgba(240, 46, 170, 0.05) -25px 25px",
  },
  infoText: {
    letterSpacing: "2px",
    margin: "0",
    fontSize:"30px"
  },
  infoSubText:{
    fontSize:"20px",
    letterSpacing:"1.5px",
    marginTop:"20px",
    color:"#df0033",
  },
  label:{
    "& .MuiTypography-body1":{
        fontSize:"1.3rem",
    }
  },
  selectBox: {
    margin: "50px 40px",
    padding: "40px",
    boxShadow:
      "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
    background: "#fff",
    borderRadius: "5px",
  },
  textField:{
    width:"100%"
  },
  freshStart:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    height:"100%"
  }
});

export default useStyles;
