import React from 'react'
import { ResponsiveContainer } from 'recharts'
import MemberTable from './MemberTable';

const ListMembers = () => {
    return (
        <ResponsiveContainer>
            <div className="callData">
                <MemberTable />
            </div>
        </ResponsiveContainer>
    )
}

export default ListMembers
