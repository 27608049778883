import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ResponsiveContainer } from "recharts";
import useStyles from "./style";
import TopInfoBox from "./topInfoBox";

function ReSchedule(props) {
  const classes = useStyles();
  const [cancelBookingSubmit, setCancelBookingSubmit] = useState(false);
  
  useEffect(() => {
    props.showResetButton && setCancelBookingSubmit(true);
  },[props.showResetButton])
  
  useEffect(() => {
    window.addEventListener("keydown", handleFocus);
    // window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", handleFocus);
      // window.removeEventListener("keyup", upHandler);
    };
  },[]);

  const handleFocus = (e) => {
    console.log(e.key)
    if((e.key === "ArrowUp" || e.key === "ArrowDown")){
      document.getElementById(`${props.callerDetails.arrangeCall ? props.callerDetails.arrangeCall : "1"}`).focus()
    }
  }

  const handleSubmit = () => {
    props.handleSubmitCancelBooking();
  };

  const handleKeyPress = (e) => {
    if(e.key === "Enter"){
      handleSubmit();
    }
  }

  console.log('props.callerDetails.arrangeCall',props.callerDetails.arrangeCall);
  return (
    <ResponsiveContainer>
      <div className="row">
        <div className="col-md-12">
          {!cancelBookingSubmit && (
            <TopInfoBox
              text={`May I arrange ${props.operatorName} for to call you to reschedule ?`}
            />
          )}
          {cancelBookingSubmit && (
            <TopInfoBox text="Thank you for your call, I will pass on your message straight away and have your call returned as soon as possible." />
          )}
          {!props.showResetButton ? 
          <div className={classes.selectBox}>
            <div className="col-md-12 mt-4">
              <FormControl>
                <FormLabel className="mb-2">
                  <h3 style={{ fontWeight: "700" }}>Re-Schedule</h3>
                </FormLabel>

                <RadioGroup
                  name="arrangeCall"
                  defaultValue={"1"}
                  // defaultOpen="true"
                  value={props.callerDetails.arrangeCall ? props.callerDetails.arrangeCall : "1"}
                  onChange={props.handleChange}
                  onKeyUp={handleKeyPress}
                >
                  <FormControlLabel
                    className={classes.label}
                    value="1"
                    control={<Radio id="1" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    className={classes.label}
                    value="0"
                    control={<Radio id="0" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </div>

            <div className="col-md-12 mt-3">
              <Button
                variant="contained"
                color="secondary"
                onClick={props.handleBack}
              >
                Back
              </Button>
              {props.callerDetails.arrangeCall && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  className="ml-3"
                >
                  Submit
                </Button>
              )}
              {props.showResetButton && (
                <Button
                  variant="contained"
                  className="ml-3"
                  color="default"
                  onClick={props.handleReset}
                >
                  Reset
                </Button>
              )}
            </div>
          </div>
          :
          <div className={classes.freshStart}>
            <Button color="secondary" variant="contained" onClick={props.handleReset}>
                  Start from beginning
            </Button>    
          </div>
          }
        </div>
      </div>
    </ResponsiveContainer>
  );
}

export default ReSchedule;
