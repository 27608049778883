import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DetailCard from "./DetailCard";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
// import DialogTitle from "@material-ui/core/DialogTitle";

class FormDialog extends React.Component {
  state = {
    open: false
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  render() {
    const {
      rowID,
      rowDate,
      rowTime,
      rowPhone,
      rowPhone2,
      rowSuburb,
      rowFirstName,
      rowLastName,
      rowAddress,
      rowPetName,
      rowPetBreed,
      interestedService,
      rowEmail,
      rowPetGender,
      notes,
      commentText,
      addedBy,
      referredby,
      messageType,
      reschedule,
      franchiseeName
    } = this.props;

    return (
      <div>
        <Button
          variant="contained"
          color="primary"
          className="jr-btn text-white"
          style={{ height: "30px", width: "50px" }}
          onClick={this.handleClickOpen}
        >
          View
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleRequestClose}
          maxWidth={"md"}
          fullWidth={true}
        >
          {/* <DialogContent>
            <DialogContentText>
              Details about the call is provided below :
            </DialogContentText>
          </DialogContent> */}
          <DetailCard
            rowID={rowID}
            rowDate={rowDate}
            rowTime={rowTime}
            rowPhone={rowPhone}
            rowPhone2={rowPhone2}
            rowSuburb={rowSuburb}
            rowFirstName={rowFirstName}
            rowLastName={rowLastName}
            rowAddress={rowAddress}
            rowPetName={rowPetName}
            rowPetBreed={rowPetBreed}
            rowPetGender={rowPetGender}
            interestedService={interestedService}
            rowEmail={rowEmail}
            reschedule={reschedule}
            notes={notes}
            commentText={commentText}
            addedBy={addedBy}
            referredby={referredby}
            messageType={messageType}
            franchiseeName={franchiseeName}
          />

          <DialogActions>
            <Button onClick={this.handleRequestClose} color="secondary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default FormDialog;
