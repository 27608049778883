import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { ResponsiveContainer } from "recharts";
import FormDialog from "./FormDialog";
import moment from "moment";
import LinearBuffer from "./LinearBuffer";
import {
  // NotificationManager,
  NotificationContainer
} from "react-notifications";
// import { httpClient } from "appUtility/Api";

// const qs = require("qs");
// const loginData = JSON.parse(window.localStorage.getItem("login"));

const SearchResults = props => {
  const { filteredData } = props;

  return (
    <>
      <ResponsiveContainer>
        <div className="table-responsive-material">
          <Table>
            <TableHead>
              <TableRow>
                {/* <TableCell>SN</TableCell> */}
                <TableCell align="left">Added Date/Time</TableCell>
                <TableCell align="left">Customer</TableCell>
                <TableCell align="left">Address</TableCell>
                <TableCell align="left">Suburb</TableCell>
                <TableCell align="left">Phone</TableCell>
                <TableCell align="left">Call Type</TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData && filteredData.length > 0 ? (
                filteredData.map((n, index) => {
                  return (
                    <TableRow key={index}>
                      {/* <TableCell>{counter++}</TableCell> */}
                      <TableCell align="left">
                        {moment(n.addedDate + " " + n.addedTime).format(
                          "Do MMM YYYY, h:mm a"
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {n.firstName} {n.lastName}
                      </TableCell>
                      <TableCell align="left">{n.address}</TableCell>
                      <TableCell align="left">{n.suburb}</TableCell>
                      <TableCell align="left">{n.phone}</TableCell>
                      <TableCell align="left" style={{ color: "#b5b5b5" }}>
                        {n.messageType}
                      </TableCell>
                      <TableCell align="left">
                        {
                          <>
                            {/*---Here Details of each Data is being passed on to the FormDialog component as it shows the details of each data---*/}
                            <FormDialog
                              rowID={n.logID}
                              rowDate={n.addedDate}
                              rowTime={n.addedTime}
                              rowPhone={n.phone}
                              rowPhone2={n.phone2}
                              rowSuburb={n.suburb}
                              rowFirstName={n.firstName}
                              rowLastName={n.lastName}
                              rowAddress={n.address}
                              rowPetName={n.petName}
                              rowPetBreed={n.petBreed}
                              rowPetGender={n.gender}
                              interestedService={n.interestedService}
                              messageType={n.messageType}
                              rowEmail={n.email}
                              notes={n.notes}
                              commentText={n.commentText || n.message}
                              reschedule={n.reschedule}
                              addedBy={n.addedBy}
                              referredby={n.referredby}
                              franchiseeName={n.franchiseeName}
                            />
                          </>
                        }
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <>
                  {filteredData === null ? (
                    <h2 style={{ marginTop: "2%", marginLeft: "2%" }}>
                      Sorry, no results for that search query !
                    </h2>
                  ) : (
                    <LinearBuffer />
                  )}
                </>
              )}
            </TableBody>
          </Table>
          <NotificationContainer />
        </div>
      </ResponsiveContainer>
    </>
  );
};

export default SearchResults;
