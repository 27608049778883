import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { ResponsiveContainer } from "recharts";
import { NotificationContainer } from "react-notifications";
import useStyles from "./style";
import TopInfoBox from "./topInfoBox";
import { handlePhoneNumberValidation } from "../../../appUtility/phoneNumberUtils";

function ContinueServiceCall(props) {
  const classes = useStyles();
  const [servSubmit, setServSubmit] = useState(false);
  const [stage, setStage] = useState(0);
  const inputRef = useRef([]);

  useEffect(() => {
    if (props.callerPressed.phoneNumber1 || props.callerPressed.phoneNumber2) {
      setStage(1);
    } else if (props.callerPressed.address) {
      setStage(2);
    } else if (props.callerPressed.firstName || props.callerPressed.lastName) {
      setStage(0);
    }
  }, [props]);

  const handleNext = () => {
    const isValid = handlePhoneNumberValidation(
      props.callerDetails.phoneNumber1,
      props.callerDetails.phoneNumber2
    );

    if (!isValid) {
      return;
    }

    setServSubmit(true);
    props.handleContinue();
  };

  const handleKeyEnter = (e, next) => {
    if (e.key === "Enter") {
      var nextfield;
      if (next === "enter") {
        handleNext();
      } else {
        nextfield = document.querySelector(`input[name=${next}]`);
        nextfield.focus();
      }
    }
  };

  return (
    <>
      <ResponsiveContainer>
        <div className="row">
          <div className="col-md-12">
            {stage === 0 && props.suspendedFranchisee.leadSuspend == "1" && (
              <TopInfoBox
                text={`Unfortunately ${props.suspendedFranchisee.name ||
                  props.suspendedFranchisee
                    .memberName} is on leave from ${moment(
                  props.suspendedFranchisee.leadSuspendStartDate
                ).format("dddd, Do MMMM YYYY")} to ${moment(
                  props.suspendedFranchisee.leadSuspendEndDate
                ).format("dddd, Do MMMM YYYY")} and the reason is ${props
                  .suspendedFranchisee.leadSuspendReasonID ||
                  props.suspendedFranchisee.leadSuspendReason}`}
              />
            )}

            {stage === 0 && <TopInfoBox text="May I have your name please ?" />}
            {stage === 1 && (
              <TopInfoBox text="And what is the best number to contact you on ? " />
            )}
            {stage === 2 && (
              <TopInfoBox text="May I take your address please ?" />
            )}

            <div className={classes.selectBox}>
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <InputLabel className="mb-2">FirstName</InputLabel>
                    <TextField
                      variant="outlined"
                      name="firstName"
                      value={props.callerDetails.firstName}
                      onChange={props.handleChange}
                      className={classes.textField}
                      // onKeyUp={props.handleCallerFocus}
                      onKeyUp={(e) => handleKeyEnter(e, "lastName")}
                      helperText={"Press enter the First Name"}
                      ref={inputRef}
                    />
                  </div>
                  {/* {props.callerDetails.firstName !== "" &&
                  props.callerPressed.firstName && ( */}
                  <div className="col-md-6">
                    <InputLabel className="mb-2">LastName</InputLabel>
                    <TextField
                      variant="outlined"
                      name="lastName"
                      value={props.callerDetails.lastName}
                      onChange={props.handleChange}
                      className={classes.textField}
                      // onKeyUp={props.handleCallerFocus}
                      onKeyUp={(e) => handleKeyEnter(e, "phoneNumber1")}
                      helperText={"Press enter the Last Name"}
                      ref={inputRef}
                    />
                  </div>
                  {/* )} */}

                  {(props.callerDetails.firstName ||
                    props.callerDetails.lastName) && (
                    <div className="col-md-6 mt-4">
                      <InputLabel className="mb-2">Phone Number 1</InputLabel>
                      <TextField
                        variant="outlined"
                        name="phoneNumber1"
                        type="number"
                        value={props.callerDetails.phoneNumber1}
                        onChange={props.handleChange}
                        className={classes.textField}
                        // onKeyUp={props.handleCallerFocus}
                        onKeyUp={(e) => handleKeyEnter(e, "phoneNumber2")}
                        helperText={"Press enter the Phone Number"}
                        // ref={`${inputRef}_3`}
                      />
                    </div>
                  )}

                  {(props.callerDetails.firstName ||
                    props.callerDetails.lastName) && (
                    <div className="col-md-6 mt-4">
                      <InputLabel className="mb-2">Phone Number 2</InputLabel>
                      <TextField
                        variant="outlined"
                        name="phoneNumber2"
                        type="number"
                        value={props.callerDetails.phoneNumber2}
                        onChange={props.handleChange}
                        className={classes.textField}
                        // onKeyUp={props.handleCallerFocus}
                        onKeyUp={(e) => handleKeyEnter(e, "address")}
                        helperText={"Press enter the Phone Number"}
                      />
                    </div>
                  )}

                  {props.callerDetails.firstName &&
                    // || props.callerDetails.lastName
                    (props.callerDetails.phoneNumber1 ||
                      props.callerDetails.phoneNumber2) && (
                      <>
                        <div className="col-md-12 mt-4">
                          <InputLabel className="mb-2">Address</InputLabel>
                          <TextField
                            variant="outlined"
                            name="address"
                            value={props.callerDetails.address}
                            onChange={props.handleChange}
                            className={classes.textField}
                            onKeyUp={props.handleCallerFocus}
                            helperText={"Press enter the Address"}
                            onKeyUp={(e) => handleKeyEnter(e, "suburb")}
                          />
                        </div>
                        <div className="col-md-4 mt-4">
                          <InputLabel className="mb-2">Suburb</InputLabel>
                          <TextField
                            variant="outlined"
                            name="suburb"
                            value={props.suburb}
                            onChange={props.handleChange}
                            className={classes.textField}
                            // disabled={true}
                            // onKeyUp={props.handleCallerFocus}
                            onKeyUp={(e) => handleKeyEnter(e, "state")}
                          />
                        </div>
                        <div className="col-md-4 mt-4">
                          <InputLabel className="mb-2">State</InputLabel>
                          <TextField
                            variant="outlined"
                            name="state"
                            value={props.state}
                            onChange={props.handleChange}
                            className={classes.textField}
                            // disabled={true}
                            // onKeyUp={props.handleCallerFocus}
                            onKeyUp={(e) => handleKeyEnter(e, "postCode")}
                          />
                        </div>
                        <div className="col-md-4 mt-4">
                          <InputLabel className="mb-2">Post Code</InputLabel>
                          <TextField
                            variant="outlined"
                            name="postCode"
                            value={props.postCode}
                            onChange={props.handleChange}
                            className={classes.textField}
                            // disabled={true}
                            onKeyUp={(e) => handleKeyEnter(e, "enter")}
                          />
                        </div>
                      </>
                    )}

                  <div className="mt-3">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={props.handleBack}
                    >
                      Back
                    </Button>
                    {props.callerDetails.firstName &&
                      props.callerDetails.phoneNumber1 && (
                        // props.callerDetails.hearingSource && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                          className="ml-3"
                        >
                          Next
                        </Button>
                      )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </ResponsiveContainer>
      <NotificationContainer />
    </>
  );
}

export default ContinueServiceCall;
