/**
 * @file Enquiry details page used for Franchise and Advertising Enquiry
 */

import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import moment from "moment";
import { ResponsiveContainer } from "recharts";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import useStyles from "./style";
import TopInfoBox from "./topInfoBox";
import { httpClient } from "appUtility/Api";
import { handlePhoneNumberValidation } from "../../../appUtility/phoneNumberUtils";

const qs = require("qs");

function ContinueEnquiry(props) {
  const classes = useStyles();
  const [messageSubmit, setMessageSubmit] = useState(false);
  const [stage, setStage] = useState(0);
  const [postCode, setPostCode] = useState("");
  const [postCodeError, setPostCodeError] = useState("");
  const [postCodeValid, setPostCodeValid] = useState(false);
  const [suburbList, setSuburbList] = useState([]);
  const [selectedSuburb, setSelectedSuburb] = useState("");
  const [postCodePressed, setPostCodePressed] = useState(false);

  let loginData = JSON.parse(localStorage.getItem("login"));

  useEffect(() => {
    if (props.enquiryPressed.firstName || props.enquiryPressed.lastName) {
      setStage(0);
    } else if (
      props.enquiryPressed.phoneNumber1 ||
      props.enquiryPressed.phoneNumber2
    ) {
      setStage(1);
    } else if (props.enquiryPressed.address) {
      setStage(2);
    } else if (props.enquiryPressed.message) {
      setStage(3);
    } else {
    }
  }, [props]);

  useEffect(() => {
    if (postCodePressed) {
      setStage(2);
    }
  }, [postCodePressed]);

  useEffect(() => {
    props.showResetButton && setMessageSubmit(true);
  }, [props.showResetButton]);

  const handlePostCodePress = (e) => {
    if (e.key === "Enter") {
      var postQuery = {
        userID: loginData && loginData.uid,
        userCode: loginData && loginData.aCode,
        appID: 1,
        postCode: postCode,
      };
      httpClient
        .post(
          "getSuburbs_Franchise.php",
          qs.stringify({
            action: "get_suburbs",
            source: postQuery,
          })
        )
        .then((res) => {
          if (res.data.error) {
            setPostCodeError(
              "Invalid Post Code. Please Re-Enter your Post Code"
            );
            setPostCodeValid(false);
            setSuburbList([]);
            setSelectedSuburb("");
          } else {
            setPostCodeError("");
            setPostCodeValid(true);

            if (res.data[0] === "No Valid Suburb found on system") {
              NotificationManager.warning("No Valid Suburb found on system");

              setSuburbList([]);
            } else {
              setPostCodeError("");

              setSuburbList(res.data);
              setPostCodeValid(true);
            }
            setSelectedSuburb("");
          }
        });
    }
  };

  const handleSuburbChange = (e) => {
    setSelectedSuburb(e.target.value);
  };

  const handleSubmit = () => {
    const isValid = handlePhoneNumberValidation(
      props.enquiryDetails.phoneNumber1,
      props.enquiryDetails.phoneNumber2
    );
    if (!isValid) {
      return;
    }

    props.handleSubmit(
      props.advertisingEnquiry ? "Advertising_Enquiry" : "Franchise_Enquiry",
      postCode,
      selectedSuburb
    );
  };

  const handleKeyEnter = (e, next) => {
    if (e.key === "Enter") {
      var nextfield;
      if (next === "message") {
        nextfield = document.querySelector(`textarea[name=${next}]`);
      } else {
        nextfield = document.querySelector(`input[name=${next}]`);
      }
      nextfield.focus();
    }
  };

  const showPostCodeField =
    (props.enquiryDetails.firstName || props.enquiryDetails.lastName) &&
    (props.enquiryDetails.phoneNumber1 || props.enquiryDetails.phoneNumber2) &&
    props.enquiryDetails.address;

  const showSuburbSelect = postCodeValid && suburbList.length > 0;
  const showMessageField =
    postCodeValid && (suburbList.length === 0 || selectedSuburb !== "");

  return (
    <>
      <ResponsiveContainer>
        <div className="row">
          <div className="col-md-12">
            {/* {!props.enquiryDetails.customerName && (
            <TopInfoBox text="May I have your name please ?" />
          )}
          {props.enquiryDetails.customerName &&
            (!props.enquiryDetails.phoneNumber1 && !props.enquiryDetails.phoneNumber2) && (
              <TopInfoBox text="And what is the best number to contact you on ? " />
            )}

          {props.enquiryDetails.customerName &&
            (props.enquiryDetails.phoneNumber1 || props.enquiryDetails.phoneNumber2) &&
            !props.enquiryDetails.address && (
              <TopInfoBox text="May I take your address please ?" />
            )}

          {props.enquiryDetails.customerName &&
            (props.enquiryDetails.phoneNumber1 || props.enquiryDetails.phoneNumber2) &&
            props.enquiryDetails.address &&
            // !props.enquiryDetails.message && 
            !messageSubmit &&
            (
            <TopInfoBox text={props.advertisingEnquiry ? "Record details of message – Including the company the caller is from." : "Record details of message."} />
          )} */}

            {/* {props.enquiryDetails.customerName &&
            (props.enquiryDetails.phoneNumber1 || props.enquiryDetails.phoneNumber2) &&
            props.enquiryDetails.address &&
            props.enquiryDetails.message &&
            !props.enquiryDetails.hearingSource &&
            !messageSubmit && <TopInfoBox text="Finally, may I ask how you heard about us?" />}

          {props.enquiryDetails.customerName &&
            (props.enquiryDetails.phoneNumber1 || props.enquiryDetails.phoneNumber2) &&
            props.enquiryDetails.address &&
            props.enquiryDetails.message &&
            messageSubmit && (
              <TopInfoBox text={props.advertisingEnquiry ? "Thank you for calling I will pass on your details." : "Thank you for calling our National Business Development Manager will contact you shortly" }/>
            )} */}
            {stage === 0 && props.suspendedFranchisee.leadSuspend == "1" && (
              <TopInfoBox
                text={`Unfortunately ${props.suspendedFranchisee.name ||
                  props.suspendedFranchisee
                    .memberName} is on leave from ${moment(
                  props.suspendedFranchisee.leadSuspendStartDate
                ).format("dddd, Do MMMM YYYY")} to ${moment(
                  props.suspendedFranchisee.leadSuspendEndDate
                ).format("dddd, Do MMMM YYYY")} and the reason is ${props
                  .suspendedFranchisee.leadSuspendReasonID ||
                  props.suspendedFranchisee.leadSuspendReason}`}
              />
            )}
            {stage === 0 && <TopInfoBox text="May I have your name please ?" />}
            {stage === 1 && (
              <TopInfoBox text="And what is the best number to contact you on ? " />
            )}

            {stage === 2 && (
              <TopInfoBox text="May I take your address please ?" />
            )}

            {stage === 3 && !messageSubmit && (
              <TopInfoBox
                text={
                  props.advertisingEnquiry
                    ? "Please enter details of message – Including the company the caller is from."
                    : "Please enter details of message."
                }
              />
            )}

            {messageSubmit && (
              <TopInfoBox
                text={
                  props.advertisingEnquiry
                    ? "Thank you for calling I will pass on your details."
                    : "Thank you for calling our National Business Development Manager will contact you shortly"
                }
              />
            )}

            {!props.showResetButton ? (
              <div className={classes.selectBox}>
                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <InputLabel className="mb-2">First Name</InputLabel>
                      <TextField
                        variant="outlined"
                        name="firstName"
                        value={props.enquiryDetails.firstName}
                        onChange={props.handleEnquiryChange}
                        className={classes.textField}
                        onFocus={props.handleEnquiryPress}
                        onKeyUp={(e) => handleKeyEnter(e, "lastName")}
                        helperText={"Press enter the First Name"}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputLabel className="mb-2">Last Name</InputLabel>
                      <TextField
                        variant="outlined"
                        name="lastName"
                        value={props.enquiryDetails.lastName}
                        onChange={props.handleEnquiryChange}
                        className={classes.textField}
                        onFocus={props.handleEnquiryPress}
                        onKeyUp={(e) => handleKeyEnter(e, "phoneNumber1")}
                        helperText={"Press enter the Last Name"}
                      />
                    </div>
                    {(props.enquiryDetails.firstName ||
                      props.enquiryDetails.lastName) && (
                      <div className="col-md-6 mt-4">
                        <InputLabel className="mb-2">Phone Number 1</InputLabel>
                        <TextField
                          variant="outlined"
                          name="phoneNumber1"
                          type="number"
                          value={props.enquiryDetails.phoneNumber1}
                          onChange={props.handleEnquiryChange}
                          className={classes.textField}
                          onFocus={props.handleEnquiryPress}
                          onKeyUp={(e) => handleKeyEnter(e, "phoneNumber2")}
                          helperText={"Press enter the Phone Number"}
                        />
                      </div>
                    )}
                    {(props.enquiryDetails.firstName ||
                      props.enquiryDetails.lastName) && (
                      <div className="col-md-6 mt-4">
                        <InputLabel className="mb-2">Phone Number 2</InputLabel>
                        <TextField
                          variant="outlined"
                          name="phoneNumber2"
                          type="number"
                          value={props.enquiryDetails.phoneNumber2}
                          onChange={props.handleEnquiryChange}
                          className={classes.textField}
                          onFocus={props.handleEnquiryPress}
                          onKeyUp={(e) => handleKeyEnter(e, "address")}
                          helperText={"Press enter the Phone Number"}
                        />
                      </div>
                    )}
                    {(props.enquiryDetails.firstName ||
                      props.enquiryDetails.lastName) &&
                      (props.enquiryDetails.phoneNumber1 ||
                        props.enquiryDetails.phoneNumber2) && (
                        <div className="col-md-12 mt-4">
                          <InputLabel className="mb-2">Address</InputLabel>
                          <TextField
                            multiline
                            minRows={2}
                            variant="outlined"
                            name="address"
                            value={props.enquiryDetails.address}
                            onChange={props.handleEnquiryChange}
                            className={classes.textField}
                            onFocus={props.handleEnquiryPress}
                            helperText="Enter the Address"
                            fullWidth
                          />
                        </div>
                      )}
                    {showPostCodeField && (
                      <>
                        <div className="col-md-6 mt-4">
                          <InputLabel>Post Code</InputLabel>
                          <TextField
                            variant="outlined"
                            name="postCode"
                            value={postCode}
                            onChange={(e) => setPostCode(e.target.value)}
                            onKeyPress={handlePostCodePress}
                            helperText={
                              postCode
                                ? "Please Press Enter after you have inserted your Post Code"
                                : ""
                            }
                            error={!!postCodeError}
                            margin="normal"
                            fullWidth
                          />
                          {postCodeError && (
                            <FormHelperText error>
                              {postCodeError}
                            </FormHelperText>
                          )}
                        </div>
                        {showSuburbSelect && (
                          <div className="col-md-6 mt-4">
                            <InputLabel id="suburb-label">Suburb</InputLabel>
                            <FormControl
                              variant="outlined"
                              style={{ width: "100%" }}
                              margin="normal"
                            >
                              <Select
                                name="suburb"
                                labelId="suburb-label"
                                id="suburb"
                                onChange={handleSuburbChange}
                                value={selectedSuburb}
                                margin="normal"
                              >
                                <MenuItem value="">
                                  <em>Select Suburb</em>
                                </MenuItem>
                                {suburbList.map((sub) => (
                                  <MenuItem
                                    key={sub.suburbID}
                                    value={sub.suburbName}
                                  >
                                    <span>
                                      {sub.suburbName
                                        ? `${sub.suburbName}, ${sub.stateShortName}`
                                        : sub}
                                    </span>
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                        )}
                      </>
                    )}
                    {showMessageField && (
                      <div className="col-md-12 mt-4">
                        <InputLabel className="mb-2">Message</InputLabel>
                        <TextField
                          multiline
                          rows={4}
                          variant="outlined"
                          name="message"
                          value={props.enquiryDetails.message}
                          onChange={props.handleEnquiryChange}
                          className={classes.textField}
                          onFocus={props.handleEnquiryPress}
                          //   helperText={"Press enter the Message"}
                        />
                      </div>
                    )}

                    <div className="col-md-12 mt-3">
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={props.handleBack}
                      >
                        Back
                      </Button>
                      {(props.enquiryDetails.firstName ||
                        props.enquiryDetails.lastName) &&
                        props.enquiryDetails.phoneNumber1 &&
                        postCode &&
                        props.enquiryDetails.address &&
                        props.enquiryDetails.message && (
                          // props.enquiryDetails.hearingSource && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            className="ml-3"
                          >
                            Submit
                          </Button>
                        )}
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              <div className={classes.freshStart}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={props.handleReset}
                >
                  Start from beginning
                </Button>
              </div>
            )}
          </div>
        </div>
      </ResponsiveContainer>
      <NotificationContainer />
    </>
  );
}

export default ContinueEnquiry;
