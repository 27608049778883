import React from "react";
import useStyles from "./style";

function TopInfoBox({ text, subText }) {
  const classes = useStyles();
  return (
    <div style={{ width:"100%"}}>
    <div className={classes.topInfo}>
      <h2 className={classes.infoText}>{text}</h2>
      {subText && <div className={classes.infoSubText} style={{ whiteSpace: "pre-line" }}>{subText.split("<br/>").join("\n")}</div>}
    </div>
    </div>
  );
}

export default TopInfoBox;
