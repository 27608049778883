import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { connect } from "react-redux";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IntlMessages from "util/IntlMessages";
// import { userSignOut } from "actions/Auth";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import LogOut from "../../containers/Logout";
import { history } from "../../store/index";
// import Button from "@material-ui/core/Button";
// import DialogActions from "@material-ui/core/DialogActions";
// import DialogContentText from "@material-ui/core/DialogContentText";

const loginData = JSON.parse(window.localStorage.getItem("login"));

class UserInfo extends React.Component {
  state = {
    anchorEl: null,
    open: false,
    dialog_open: false,
    userName: loginData && loginData.fname + " " + loginData.lname
  };

  handleClick = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  handlePassword = () => {
    history.push("/app/changePassword");
  };

  handleDialogOpen = () => {
    this.setState({ dialog_open: true });
  };

  render() {
    return (
      <>
        <div className="user-profile d-flex flex-row align-items-center">
          <Avatar
            src={require("assets/images/phone-logo.png")}
            alt="phone_logo"
            title="phone_logo"
            className="user-avatar"
          />
          <div className="user-detail">
            <h4 className="user-name" onClick={this.handleClick}>
              {this.state.userName}
              <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
            </h4>
          </div>
          <Menu
            className="user-info"
            id="simple-menu"
            anchorEl={this.state.anchorEl}
            open={this.state.open}
            onClose={this.handleRequestClose}
            PaperProps={{
              style: {
                minWidth: 120,
                paddingTop: 0,
                paddingBottom: 0
              }
            }}
          >
            {/* <MenuItem onClick={this.handleRequestClose}>
            <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />
            <IntlMessages id="popup.profile" />
          </MenuItem> */}
            <MenuItem onClick={this.handlePassword}>
              <i className="zmdi zmdi-settings zmdi-hc-fw mr-2" />
              <IntlMessages id="popup.changePassword" />
            </MenuItem>

            <MenuItem
              onClick={() => {
                this.handleRequestClose();
                this.handleDialogOpen();
                // this.props.userSignOut();
              }}
            >
              <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />
              <IntlMessages id="popup.logout" />
            </MenuItem>
          </Menu>
        </div>

        <Dialog open={this.state.dialog_open}>
          <DialogContent>
            <LogOut />
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { locale } = settings;
  return { locale };
};
export default connect(mapStateToProps)(UserInfo);
// export default connect(mapStateToProps, { userSignOut })(UserInfo);
