import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ResponsiveContainer } from "recharts";
import useStyles from "./style";
import TopInfoBox from "./topInfoBox";

function ReferallPage(props) {
  const classes = useStyles();
  const [servSubmit, setServSubmit] = useState(false);

  useEffect(() => {
    props.showResetButton && setServSubmit(true);
  }, [props.showResetButton]);

  useEffect(() => {
    window.addEventListener("keydown", handleFocus);
    // window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", handleFocus);
      // window.removeEventListener("keyup", upHandler);
    };
  }, []);

  const handleFocus = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      document
        .getElementById(
          `${
            props.callerDetails.hearingSource
              ? props.callerDetails.hearingSource.replaceAll(" ", "_")
              : "Existing_Customer"
          }`
        )
        .focus();
    }
  };

  const handleSubmit = () => {
    props.handleSubmitService();
  };

  const handleKeyEnter = (e, next) => {
    if (e.key === "Enter") {
      var nextfield;
      if (next === "submit") {
        // handleSubmit();
      } else {
        nextfield = document.querySelector(`textarea[name=${next}]`);
        nextfield.focus();
      }
    }
  };

  const handleKeySelect = (e, next) => {
    if (e.key === "Enter") {
      var nextfield;
      if (next === "Other") {
        nextfield = document.querySelector(`textarea[name=other]`);
        nextfield.focus();
      } else {
        nextfield = document.querySelector(`textarea[name=comment]`);
        nextfield.focus();
      }
    }
  };

  return (
    <ResponsiveContainer>
      <div className="row">
        <div className="col-md-12">
          {!servSubmit && (
            <TopInfoBox text="Finally, may I ask how you heard about us ?" />
          )}

          {servSubmit && (
            <TopInfoBox
              text={`Thank you for your call, please be aware ${props.operatorName} may be grooming a dog at the moment, so will contact you as soon as possible during their business hours.`}
              subText="If asked for a time that a return call can be expected, inform the caller that call should be returned within 24 hours. <br/> For calls late Friday and the Weekend, advise caller if they do not receive a call prior, they can expect a return call on Monday (Public Holidays – the next working day)"
            />
          )}
          {!props.showResetButton ? (
            <div className={classes.selectBox}>
              <div className="row">
                <div className="col-md-12 mt-4">
                  <FormControl>
                    <FormLabel className="mb-2">
                      <h3 style={{ fontWeight: "700" }}>Referall</h3>
                    </FormLabel>
                    <RadioGroup
                      name="hearingSource"
                      value={props.callerDetails.hearingSource}
                      onChange={props.handleChange}
                      onKeyUp={(e) =>
                        handleKeySelect(e, props.callerDetails.hearingSource)
                      }
                    >
                      <FormControlLabel
                        className={classes.label}
                        value="Existing Customer"
                        control={<Radio id="Existing_Customer" />}
                        label="Existing Customer"
                      />
                      <FormControlLabel
                        className={classes.label}
                        value="Internet"
                        control={<Radio id="Internet" />}
                        label="Internet"
                      />
                      <FormControlLabel
                        className={classes.label}
                        value="Local Event"
                        control={<Radio id="Local_Event" />}
                        label="Local Event"
                      />
                      <FormControlLabel
                        className={classes.label}
                        value="Local Press"
                        control={<Radio id="Local_Press" />}
                        label="Local Press"
                      />
                      <FormControlLabel
                        className={classes.label}
                        value="Local Shops"
                        control={<Radio id="Local_Shops" />}
                        label="Local Shops"
                      />
                      <FormControlLabel
                        className={classes.label}
                        value="Local Yellow Pages"
                        control={<Radio id="Local_Yellow_Pages" />}
                        label="Local Yellow Pages"
                      />

                      <FormControlLabel
                        className={classes.label}
                        value="Pamphlets"
                        control={<Radio id="Pamphlets" />}
                        label="Pamphlets"
                      />
                      <FormControlLabel
                        className={classes.label}
                        value="RSPCA"
                        control={<Radio id="RSPCA" />}
                        label="RSPCA"
                      />
                      <FormControlLabel
                        className={classes.label}
                        value="Saw Blue Dog"
                        control={<Radio id="Saw_Blue_Dog" />}
                        label="Saw Blue Dog"
                      />
                      <FormControlLabel
                        className={classes.label}
                        value="Vet/Pet Shop"
                        control={<Radio id="Vet/Pet_Shop" />}
                        label="Vet/Pet Shop"
                      />
                      <FormControlLabel
                        className={classes.label}
                        value="Yellow Pages Online"
                        control={<Radio id="Yellow_Pages_Online" />}
                        label="Yellow Pages Online"
                      />
                      <FormControlLabel
                        className={classes.label}
                        value="Yellow Pages In Print"
                        control={<Radio id="Yellow_Pages_In_Print" />}
                        label="Yellow Pages In Print"
                      />
                      <FormControlLabel
                        className={classes.label}
                        value="Other"
                        control={<Radio id="Other" />}
                        label="Other"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                {props.callerDetails.hearingSource === "Other" && (
                  <div className="col-md-12 mt-4">
                    <TextField
                      multiline
                      rows={2}
                      label="Other"
                      name="other"
                      variant="outlined"
                      value={props.callerDetails.other}
                      onChange={props.handleChange}
                      onFocus={props.handleCallerFocus}
                      className={classes.textField}
                      onKeyUp={(e) => handleKeyEnter(e, "comment")}
                    />
                  </div>
                )}

                <div className="col-md-12 mt-4">
                  <TextField
                    multiline
                    rows={4}
                    label="Any comment to an operator/head office ?"
                    name="comment"
                    variant="outlined"
                    value={props.callerDetails.comment}
                    onChange={props.handleChange}
                    onFocus={props.handleCallerFocus}
                    className={classes.textField}
                    onKeyUp={(e) => handleKeyEnter(e, "submit")}
                  />
                </div>
                <div className="col-md-12 mt-5">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={props.handleBack}
                  >
                    Back
                  </Button>
                  {props.callerDetails.firstName &&
                    props.callerDetails.hearingSource && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        className="ml-3"
                      >
                        Submit
                      </Button>
                    )}
                  {props.showResetButton && (
                    <Button
                      variant="contained"
                      className="ml-3"
                      color="default"
                      onClick={props.handleReset}
                    >
                      Reset
                    </Button>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className={classes.freshStart}>
              <Button
                color="secondary"
                variant="contained"
                onClick={props.handleReset}
              >
                Start from beginning
              </Button>
            </div>
          )}
        </div>
      </div>
    </ResponsiveContainer>
  );
}

export default ReferallPage;
