import { Button, InputLabel, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { ResponsiveContainer } from "recharts";
import useStyles from "./style";
import { httpClient } from "appUtility/Api";
import TopInfoBox from "./topInfoBox";
const qs = require("qs");

const SelectFranchiseChange = (props) => {
  const { radios, setPreviousForwardedScenario } = props;

  const classes = useStyles();
  const [currentFranchiseList, setCurrentFranchiseList] = useState([]);
  const [totalFranchiseData, setTotalFranchiseData] = useState([]);
  const [count, setCount] = useState("");

  let commpanyType;
  let loginData = JSON.parse(localStorage.getItem("login"));

  useEffect(() => {
    setPreviousForwardedScenario(radios);
  }, []);

  const handleFranchisePress = (e) => {
    if (e.key === "Enter") {
      let params = {
        userID: loginData && loginData.uid,
        userCode: loginData && loginData.aCode,
        appID: 1,
        franchiseeName: e.target.value,
      };

      httpClient
        .post(
          "getSuburbs_Postcode.php",
          qs.stringify({
            action: "get_suburbs",
            source: params,
          })
        )
        .then(({ data }) => {
          if (data) {
            setTotalFranchiseData(data);
            let currFralist = [];
            !data.error &&
              data.map((item) => {
                // item.memberNames.map(mem => {
                //   currFralist.push(mem)
                // })
                currFralist.push(item);
              });
            setCurrentFranchiseList(currFralist);
          }
        });
    }
  };

  const handleFranchiseChange = (e, item) => {
    if (item != null) {
      //   setSuburbList(item.suburbName);
      props.setSuspendedFranchisee(item);
      props.setFranchise(item.companyID);
      //   setCurrCompanyType(item.businessType);
      commpanyType = item.businessType;
      setCount(1);
      //   if (item.suburbName !== null) {
      //     props.setState(item.suburbName[0].stateShortName);
      //     props.setStateID(item.suburbName[0].stateID);
      //     props.setPostCode(item.suburbName[0].postCode);
      //   }
    }
  };

  return (
    <ResponsiveContainer>
      <div className="row">
        <div className="col-md-12">
          {count && count > 0 ? (
            <TopInfoBox text="We have an Operator in your area, may I take your details and have your call returned as soon as possible?" />
          ) : (
            <TopInfoBox text="May I have the franchise/operator name ?" />
          )}
        </div>

        <div className="col-md-8">
          <div className={classes.selectBox}>
            <InputLabel style={{ marginBottom: "10px" }}>
              Franchise Name
            </InputLabel>
            <Autocomplete
              options={currentFranchiseList}
              getOptionLabel={(option) =>
                option.memberName +
                " - " +
                option.businessType +
                (option.serviceLocation && " - " + option.serviceLocation)
              }
              onChange={handleFranchiseChange}
              onKeyPress={handleFranchisePress}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  helperText="Press Enter after you enter franchisee name to appear franchisee list."
                  // InputProps={{
                  //   ...params.InputProps,
                  //   type: "search",
                  // }}
                />
              )}
            />

            <div className="mt-3">
              <Button
                variant="contained"
                color="secondary"
                onClick={props.handleBack}
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={props.handleContinue}
                className="ml-3"
                disabled={count > 0 ? false : true}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ResponsiveContainer>
  );
};

export default SelectFranchiseChange;
