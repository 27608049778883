import React from 'react'
import { ResponsiveContainer } from 'recharts';
import useStyles from './style'
import TopInfoBox from './topInfoBox'

function SelectOrganization(props) {
    const classes = useStyles();
    
  return (
    <ResponsiveContainer>
      <div className="row">
        <div className="col-md-12">
          <TopInfoBox text="Select an Organization" />
          <div className={classes.boxFlex}>
            <div>
              <div className={classes.logoBox} onClick={() => props.handleInfo("Blue Wheelers")}>
                <img
                  src={require("assets/images/_logo.png")}
                  alt="blue_wheelers"
                  title="Blue Wheelers"
                  className={classes.logoClass}
                />
              </div>
            </div>
            <div>
              <div className={classes.logoBox} onClick={() => props.handleInfo(("Dash" || "Dash Dog"))}>
                <img
                  src={require("assets/images/dash_logo.png")}
                  alt="dash_dog"
                  title="Dash Dog"
                  className={classes.logoClass}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ResponsiveContainer>
  )
}

export default SelectOrganization