// import React, { Component } from "react";
// import ReactDOM from "react-dom";
import { httpClient } from "appUtility/Api";
import {
  // NotificationContainer,
  NotificationManager
} from "react-notifications";

import { SIGNOUT_ADMIN, SIGNOUT_ADMIN_SUCCESS } from "constants/ActionTypes";

const qs = require("qs");



export const GetAdminVerify = uname => {
  if (uname.email !== "" && uname.password !== "") {
    NotificationManager.info("Verifying Admin Detail.");
  }

  httpClient
    .post(
      "login_admin.php",
      qs.stringify({
        action: "verify_login_admin",
        source: uname
      })
    )
    .then(({ data }) => {
      if (data.verified !== "success") {
        NotificationManager.warning(data);
      } else {
        //Now clear all local storage elements because new values are going to be downloaded soon.
        localStorage.clear();
        localStorage.setItem("login", JSON.stringify(data));
        NotificationManager.success("Logged in Successfully.");
        setTimeout(function() {
          document.location.href = "/app/adminDashboard";
        }, 1000);
      }
    });
};

//-----Sign Out Actions-----
export const userSignOut = () => {
  return {
    type: SIGNOUT_ADMIN
  };
};

export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_ADMIN_SUCCESS
  };
};
//-----------------------------
