import React from "react";
import EnhancedTable from "./EnhancedTable";
import { ResponsiveContainer } from "recharts";

const listCall2 = () => {
  return (
    <>
      <ResponsiveContainer>
        <div className="callData">
          <EnhancedTable />
        </div>
      </ResponsiveContainer>
    </>
  );
};
export default listCall2;
