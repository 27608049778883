import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  // CardSubtitle,
  CardText,
  Row,
  Col,
} from "reactstrap";
import moment from "moment";

const DetailCard = (props) => {
  // let serviceData = props.interestedService;
  // const serviceList = serviceData.split(",");
  // console.log('props', props);
  return (
    <Card className={`shadow border-0`} style={{ margin: "2%" }}>
      <CardHeader className="bg-primary text-white">
        <h1 className="font-weight-semibold">Call Details</h1>
      </CardHeader>
      <CardBody>
        {/* <CardSubtitle>Caller Information</CardSubtitle> */}
        <CardText>
          <Row>
            <Col>
              <h3 className="font-weight-semibold">Name</h3>
              <h4 style={{ color: "#757575" }}>
                {props.rowFirstName} {props.rowLastName}
              </h4>
            </Col>
            <Col>
              <h2 className="font-weight-semibold">Phone</h2>
              <h4 style={{ color: "#757575" }}>{props.rowPhone}</h4>
            </Col>
          </Row>

          <hr
            className="rounded"
            style={{ borderTop: "8px solid #7bfd8e", borderRadius: "5px" }}
          ></hr>
          <Row>
            <Col>
              {props.rowPhone2 && (
                <>
                  <h2 className="font-weight-semibold">Phone 2</h2>
                  <h4 style={{ color: "#757575" }}>{props.rowPhone2}</h4>
                  <br />
                </>
              )}

              <h2 className="font-weight-semibold">Added Date/Time</h2>
              <h4 style={{ color: "#757575" }}>
                {props.rowTime} on{" "}
                {moment(props.rowDate).format("Do MMM YYYY, dddd")}
              </h4>
              <br />
              {/* <h2 className="font-weight-semibold">Email</h2>
              <h4 style={{ color: "#757575" }}>{props.rowEmail}</h4>
              <br /> */}

              {/* <h2 className="font-weight-semibold">Pet Name</h2>
              <h4 style={{ color: "#757575" }}>{props.rowPetName}</h4>
              <br /> */}
              {props.interestedService && (
                <>
              <h2 className="font-weight-semibold">Interested Service</h2>
              <h4 style={{ color: "#757575" }}>{props.interestedService}</h4>
              <br />
              </>
              )}

              {props.messageType && (
                <>
                  <h2 className="font-weight-semibold">Call Type</h2>
                  <h4 style={{ color: "#757575" }}>{props.messageType}</h4>
                  <br />
                </>
              )}

              {props.franchiseeName && (
                <>
                  <h2 className="font-weight-semibold">Franchisee Name</h2>
                  <h4 style={{ color: "#757575" }}>{props.franchiseeName}</h4>
                  <br />
                </>
              )}

              {props.messageType == "cancel_booking" && (
                <>
                  <h2 className="font-weight-semibold">Re-scheduled</h2>
                  <h4 style={{ color: "#757575" }}>
                    <span>{props.reschedule == "0" ? "No" : "Yes"}</span>
                  </h4>
                </>
              )}

              <h2 className="font-weight-semibold">Notes</h2>
              <h4 style={{ color: "#757575" }}>
                {props.notes === "" ? "---" : <span>{props.notes}</span>}
              </h4>

              <h2 className="font-weight-semibold">Comment</h2>
              <h4 style={{ color: "#757575" }}>
                {props.commentText === "" ? (
                  "---"
                ) : (
                  <span>{props.commentText}</span>
                )}
              </h4>
            </Col>
            <Col>
              <h2 className="font-weight-semibold">Address</h2>
              <h4 style={{ color: "#757575" }}>{props.rowAddress}</h4>
              <br />
              <h2 className="font-weight-semibold">Suburb</h2>
              <h4 style={{ color: "#757575" }}>{props.rowSuburb}</h4>
              <br />
              {/* <h2 className="font-weight-semibold">Pet Breed</h2>
              <h4 style={{ color: "#757575" }}>{props.rowPetBreed}</h4>
              <br />
              <h2 className="font-weight-semibold">Pet Gender</h2>
              <h4 style={{ color: "#757575" }}>{props.rowPetGender}</h4>
              <br /> */}
              <h2 className="font-weight-semibold">Referred By</h2>
              <h4 style={{ color: "#757575" }}>
                {props.referredby === "" ? (
                  "---"
                ) : (
                  <span>{props.referredby}</span>
                )}
              </h4>
              <br />
              <h2 className="font-weight-semibold">Added By</h2>
              <h4 style={{ color: "#757575" }}>
                {props.addedBy === "" ? "---" : <span>{props.addedBy}</span>}
              </h4>
            </Col>
          </Row>
        </CardText>
        <CardText></CardText>
      </CardBody>
      <CardFooter></CardFooter>
    </Card>
  );
};
export default DetailCard;
