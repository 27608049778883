import { Button, Grid, TextField, Card } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ResponsiveContainer } from "recharts";
import { httpClient } from "appUtility/Api";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import Select from "react-select";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";
import { history } from "../../../../store";

const qs = require("qs");
const loginData = JSON.parse(window.localStorage.getItem("login"));

const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const AddMembers = (props) => {
  const timeZoneList = [
    { value: "Select Timezone", label: "Select Timezone" },
    { value: "Australia/Adelaide", label: "Australia/Adelaide" },
    { value: "Australia/Brisbane", label: "Australia/Brisbane" },
    { value: "Australia/Darwin", label: "Australia/Darwin" },
    { value: "Australia/Hobart", label: "Australia/Hobart" },
    { value: "Australia/Melbourne", label: "Australia/Melbourne" },
    { value: "Australia/Perth", label: "Australia/Perth" },
    { value: "Australia/Sydney", label: "Australia/Sydney" },
  ];

  const countryList = [{ value: "13", label: "Australia" }];

  const activeList = [
    { value: "1", label: "Active" },
    { value: "0", label: "InActive" },
  ];
  const memberList = [{ value: "user", label: "User" }];

  const [memID, setMemID] = useState(props.location.pathname.substring(16));
  const [suburbList, setSuburbList] = useState([]);
  const [stateList, setStateList] = useState([]);

  const [memberDetail, setMemberDetail] = useState({
    userID: loginData && loginData.uid,
    userCode: loginData && loginData.aCode,
    appID: "1",
    firstName: "",
    lastName: "",
    userName: "",
    password: "",
    // timeZone:  "",
    phone: "",
    mobile: "",
    address1: "",
    address2: "",
    state: null,
    suburb: null,
    country: countryList[0],
    active: activeList[0],
    memType: memberList[0],
    editMemberID: memID ? memID : "0",
  });

  // validation
  const [firstNameCheck, setFirstNameCheck] = useState(false);
  const [lastNameCheck, setLastNameCheck] = useState(false);
  const [userNameCheck, setUserNameCheck] = useState(false);
  const [passwordCheck, setPasswordCheck] = useState(false);
  const [timeZoneCheck, setTimeZoneCheck] = useState(false);
  const [address1Check, setAddress1Check] = useState(false);
  const [address2Check, setAddress2Check] = useState(false);
  const [phoneCheck, setPhoneCheck] = useState(false);
  const [mobileCheck, setMobileCheck] = useState(false);
  const [countryCheck, setCountryCheck] = useState(false);
  const [stateCheck, setStateCheck] = useState(false);
  const [suburbCheck, setSuburbCheck] = useState(false);
  const [activeCheck, setActiveCheck] = useState(false);
  const [memTypeCheck, setMemTypeCheck] = useState(false);
  // validation
  const [circular, setCircular] = useState(false);

  useEffect(() => {
    getStateList();
  }, []);

  useEffect(() => {
    memberDetail.state && memberDetail.state.ID && getSuburbList();
  }, [memberDetail.state]);

  useEffect(() => {
    getMemberDetail();
  }, []);

  const getStateList = () => {
    var stateQuery = {
      userID: loginData && loginData.uid,
      userCode: loginData && loginData.aCode,
      appID: 1,
    };
    httpClient
      .post(
        "getStates.php",
        qs.stringify({
          action: "get_states",
          source: stateQuery,
        })
      )
      .then((res) => {
        if (res.data === null) {
          NotificationManager.warning("State not available right now");
        } else {
          const stateData = res.data.map((d) => ({
            value: d.stateName,
            label: d.stateName,
            ID: d.stateID,
          }));
          setStateList(stateData);
        }
      });
  };

  const getSuburbList = () => {
    var suburbQuery = {
      userID: loginData && loginData.uid,
      userCode: loginData && loginData.aCode,
      appID: 1,
      stateID: memberDetail.state && memberDetail.state.ID,
    };

    httpClient
      .post(
        "getSuburbs.php",
        qs.stringify({
          action: "get_suburbs",
          source: suburbQuery,
        })
      )
      .then((res) => {
        if (res.data === null) {
          NotificationManager.warning("Suburb not available right now");
        } else {
          const suburbData = res.data.map((d) => ({
            value: d.suburbName,
            label: d.suburbName,
          }));
          setSuburbList(suburbData);
        }
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMemberDetail({ ...memberDetail, [name]: value });
  };

  // const handleTimeZone = (data) => {
  //   setMemberDetail({ ...memberDetail, timeZone: data })
  // }

  const handleSuburb = (data) => {
    setMemberDetail({ ...memberDetail, suburb: data });
  };
  const handleState = (data) => {
    setMemberDetail({ ...memberDetail, state: data });
  };

  const handleCountry = (data) => {
    setMemberDetail({ ...memberDetail, country: data });
  };

  const handleMember = (data) => {
    setMemberDetail({ ...memberDetail, memType: data });
  };

  const handleActive = (data) => {
    setMemberDetail({ ...memberDetail, active: data });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    memberDetail.firstName === "" && setFirstNameCheck(true);
    memberDetail.lastName === "" && setLastNameCheck(true);
    memberDetail.userName === "" && setUserNameCheck(true);
    memberDetail.password === "" && setPasswordCheck(true);
    // memberDetail.timeZone === "" && setTimeZoneCheck(true);
    // memberDetail.address1 === "" && setAddress1Check(true);
    // memberDetail.address2 === "" && setAddress2Check(true);
    // memberDetail.phone === "" && setPhoneCheck(true);
    // memberDetail.mobile === "" && setMobileCheck(true);
    memberDetail.country === "" && setCountryCheck(true);
    memberDetail.state === "" && setStateCheck(true);
    memberDetail.suburb === "" && setSuburbCheck(true);
    memberDetail.active === "" && setActiveCheck(true);
    memberDetail.memType === "" && setMemTypeCheck(true);

    if (
      memberDetail.firstName !== "" &&
      memberDetail.lastName !== "" &&
      memberDetail.userName !== "" &&
      memberDetail.password !== "" &&
      // memberDetail.address1 !== "" &&
      // memberDetail.address2 !== "" &&
      // memberDetail.phone !== "" &&
      // memberDetail.mobile !== "" &&
      memberDetail.country !== "" &&
      memberDetail.state !== null &&
      memberDetail.suburb !== null &&
      memberDetail.active !== "" &&
      memberDetail.memType !== ""
    ) {
      setCircular(true);
      httpClient
        .post(
          "saveMember.php",
          qs.stringify({
            action: "save_member",
            source: memberDetail,
          })
        )
        .then((res) => {
          if (res.data.success !== "true") {
            NotificationManager.warning(res.data.msg);
            setCircular(false);
          } else {
            NotificationManager.success(res.data.msg);
            history.push("/app/listMembers");
            setTimeout(() => {
              setMemberDetail({
                ...memberDetail,
                firstName: "",
                lastName: "",
                userName: "",
                password: "",
                // timeZone: "",
                phone: "",
                mobile: "",
                address1: "",
                address2: "",
                state: null,
                suburb: null,
              });
              setCircular(false);
              setFirstNameCheck(false);
              setLastNameCheck(false);
              setUserNameCheck(false);
              setPasswordCheck(false);
              // setTimeZoneCheck(false);
              setCountryCheck(false);
              setActiveCheck(false);
              setMemTypeCheck(false);
              setAddress1Check(false);
              setAddress2Check(false);
              setPhoneCheck(false);
              setMobileCheck(false);
              setStateCheck(false);
              setSuburbCheck(false);
            }, 1000);
          }
        });
    } else {
      // console.log("validation");
    }
  };

  const getMemberDetail = () => {
    const memberDetail = {
      userID: loginData && loginData.uid,
      userCode: loginData && loginData.aCode,
      appID: "1",
      profileOfMemberID: memID ? memID : 0,
    };

    memID !== "" &&
      httpClient
        .post(
          "getMemberDetail.php",
          qs.stringify({
            action: "get_member_detail",
            source: memberDetail,
          })
        )
        .then((res) => {
          // console.log('member after response', res.data);
          setMemberDetail({
            ...memberDetail,
            firstName: res.data[0].firstName,
            lastName: res.data[0].lastName,
            userName: res.data[0].username,
            phone: res.data[0].memPhone,
            mobile: res.data[0].memMobile,
            address1: res.data[0].memAddress1,
            address2: res.data[0].memAddress2,
            state: {
              ID: res.data[0].memStateID,
              label: res.data[0].memStateName,
              value: res.data[0].memStateName,
            },
            suburb: {
              value: res.data[0].memSuburb,
              label: res.data[0].memSuburb,
            },
            // timeZone: ({value: res.data[0].timeZone, label: res.data[0].timeZone}),
            country: countryList[0],
            active: activeList[0],
            memType: memberList[0],
            editMemberID: memID ? memID : "0",
          });
        });
  };


  return (
    <div
      className="dashboard animated slideInUpTiny animation-duration-3"
      style={{ margin: "4%" }}
    >
      <h1>{memID === "" ? "Add members" : "Edit member"}</h1>
      <ResponsiveContainer>
        <Card style={{ padding: "40px" }}>
          <form style={{ width: "100%" }} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item sm={12} md={6}>
                <TextField
                  error={memberDetail.firstName === ""}
                  required
                  variant="outlined"
                  label="First Name"
                  name="firstName"
                  value={memberDetail.firstName}
                  onChange={(e) => handleChange(e)}
                  margin="normal"
                  helperText={
                    firstNameCheck === true ? "Enter your First Name" : null
                  }
                  fullWidth
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <TextField
                  error={memberDetail.lastName === ""}
                  required
                  variant="outlined"
                  label="Last Name"
                  name="lastName"
                  value={memberDetail.lastName}
                  onChange={(e) => handleChange(e)}
                  margin="normal"
                  helperText={
                    lastNameCheck === true ? "Enter your Last Name" : null
                  }
                  fullWidth
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <TextField
                  error={!emailPattern.test(memberDetail.userName)}
                  required
                  variant="outlined"
                  label="User Name"
                  name="userName"
                  value={memberDetail.userName}
                  onChange={(e) => handleChange(e)}
                  margin="normal"
                  helperText={
                    userNameCheck === true ? (
                      <span style={{ color: "red" }}>
                        Username must be Email Address
                      </span>
                    ) : null
                  }
                  fullWidth
                />
              </Grid>
              {memID === "" && (
                <Grid item sm={12} md={6}>
                  <TextField
                    error={memberDetail.password === ""}
                    required
                    variant="outlined"
                    label="Password"
                    name="password"
                    value={memberDetail.password}
                    onChange={(e) => handleChange(e)}
                    margin="normal"
                    type="password"
                    helperText={
                      passwordCheck === true ? "Enter your Password" : null
                    }
                    fullWidth
                  />
                </Grid>
              )}
              {/* <Grid item sm={12} md={6}>
            <div style={{ margin: "10px 0" }}>
                  <InputLabel htmlFor="gender-native">TimeZone</InputLabel>
                  <Select
                    name="timeZone"
                    // defaultValue={timeZoneList[0]}
                    options={timeZoneList}
                    onChange={(e) => handleTimeZone(e)}
                    value={memberDetail && memberDetail.timeZone}
                  />
                  <div style={{ fontSize: "12px", color: "rgb(244, 67, 54)" }}>
                    {timeZoneCheck && <div>Please choose your TimeZone !</div>}
                  </div>
                </div>
          </Grid> */}
              <Grid item sm={12} md={6}>
                <div style={{ marginTop: "10px" }}>
                  <InputLabel htmlFor="gender-native">State</InputLabel>
                  <Select
                    name="state"
                    defaultValue={memberDetail.state}
                    options={stateList}
                    onChange={(e) => handleState(e)}
                    value={memberDetail && memberDetail.state}
                  />
                  {stateCheck && (
                    <div
                      style={{ fontSize: "12px", color: "rgb(244, 67, 54)" }}
                    >
                      Please choose your state !
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item sm={12} md={6}>
                <div style={{ marginTop: "10px" }}>
                  <InputLabel htmlFor="gender-native">Suburb</InputLabel>
                  <Select
                    name="suburb"
                    options={suburbList}
                    onChange={(e) => handleSuburb(e)}
                    value={memberDetail && memberDetail.suburb}
                  />

                  {suburbCheck && (
                    <div
                      style={{ fontSize: "12px", color: "rgb(244, 67, 54)" }}
                    >
                      Please choose your suburb !
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item sm={12} md={6}>
                <TextField
                  style={{ zIndex: "0" }}
                  variant="outlined"
                  label="Phone Number"
                  name="phone"
                  value={memberDetail.phone}
                  onChange={(e) => handleChange(e)}
                  margin="normal"
                  type="number"
                  fullWidth
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <TextField
                  style={{ zIndex: "0" }}
                  variant="outlined"
                  label="Mobile Number"
                  name="mobile"
                  value={memberDetail.mobile}
                  onChange={(e) => handleChange(e)}
                  margin="normal"
                  type="number"
                  fullWidth
                />
              </Grid>

              <Grid item sm={12} md={6}>
                <TextField
                  style={{ zIndex: "0" }}
                  variant="outlined"
                  label="Address 1"
                  name="address1"
                  value={memberDetail.address1}
                  onChange={(e) => handleChange(e)}
                  margin="normal"
                  fullWidth
                />
              </Grid>

              <Grid item sm={12} md={6}>
                <TextField
                  style={{ zIndex: "0" }}
                  variant="outlined"
                  label="Address 2"
                  name="address2"
                  value={memberDetail.address2}
                  onChange={(e) => handleChange(e)}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <div style={{ marginTop: "10px" }}>
                  <InputLabel htmlFor="gender-native">Country</InputLabel>
                  <Select
                    name="state"
                    defaultValue={countryList[0]}
                    options={countryList}
                    onChange={(e) => handleCountry(e)}
                    value={memberDetail && memberDetail.country}
                  />
                  <div style={{ fontSize: "12px", color: "rgb(244, 67, 54)" }}>
                    {countryCheck && <div>Please choose your Country !</div>}
                  </div>
                </div>
              </Grid>

              <Grid item sm={12} md={6}>
                <div style={{ marginTop: "10px" }}>
                  <InputLabel htmlFor="gender-native">Active</InputLabel>
                  <Select
                    name="active"
                    defaultValue={activeList[0]}
                    options={activeList}
                    onChange={(e) => handleActive(e)}
                    value={memberDetail && memberDetail.active}
                  />

                  {activeCheck && (
                    <div
                      style={{ fontSize: "12px", color: "rgb(244, 67, 54)" }}
                    >
                      Please choose if you are active or not !
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item sm={12} md={6}>
                <div style={{ marginTop: "10px" }}>
                  <InputLabel htmlFor="gender-native">Member Type</InputLabel>
                  <Select
                    name="memType"
                    defaultValue={memberList[0]}
                    options={memberList}
                    onChange={(e) => handleMember(e)}
                    value={memberDetail && memberDetail.memType}
                  />
                  {memTypeCheck && (
                    <div
                      style={{ fontSize: "12px", color: "rgb(244, 67, 54)" }}
                    >
                      Please choose member type !
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item sm={12} align="right">
                <Button type="submit" variant="contained" color="primary">
                  {memID !== "" ? "Update Member" : "Add Member"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Card>
      </ResponsiveContainer>
      <NotificationContainer />
    </div>
  );
};

export default AddMembers;
